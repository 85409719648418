 import { configureStore } from '@reduxjs/toolkit';
 import  calendarReducer  from "./reducers/calendarReducer";
import scheduleReducer  from "./reducers/scheduleReducer";
import userReducer  from "./user.slice";
 import thunk from 'redux-thunk';
// import rootReducer from './reducers';
import rootReducer from './reducers';


const store = configureStore({
    reducer: {
    calendar: calendarReducer, 
    schedule : scheduleReducer,
    user : userReducer
  },
     middleware: [thunk]
});

export default store;
