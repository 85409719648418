import { getData, postData } from "../api";
import  ApiCore  from  '../common/api-core';

import {
  REQUEST_SCHEDULES_PENDING,
  REQUEST_SCHEDULES_SUCCESS,
  REQUEST_SCHEDULES_FAILED,
  RECEIVE_SCHEDULE,
  ADD_SCHEDULE,
  UPDATE_SCHEDULE,
  DELETE_SCHEDULE
} from "./actionTypes";
import appConfig from '../../envConfig/config';
const url = "CalendarApi";

const apiTasks = new ApiCore({
  post: true,
  url: url
});


export const requestSchedules = (dispatch) => {
  dispatch({ type: REQUEST_SCHEDULES_PENDING });
  postData(`${appConfig.api_url}/CalendarApi/Schedules`)
    .then(
      data => dispatch({ type: REQUEST_SCHEDULES_SUCCESS, payload: data }))
    .catch(error =>
      dispatch({ type: REQUEST_SCHEDULES_FAILED, payload: error })
    );
};

export function receiveSchedule( name = "",description="" ,recurence= "", category= "",location= "", calendarId = null, id = null, start = null, end = null, roleGroupId = null) {
  
  return {
    type: RECEIVE_SCHEDULE,
    payload: {
      schedule: { name,description, recurence, category, location, calendarId, id, start,end,roleGroupId}
    }
  };
};

export const addSchedule = (schedule) => ({
  type: ADD_SCHEDULE,
  payload: schedule
});

export const updateSchedule = (schedule) => ({
  type: UPDATE_SCHEDULE,
  payload: schedule
});

export const deleteSchedule = (schedule) => ({
  type: DELETE_SCHEDULE,
  payload: schedule
});