import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Improving your time by faciliting you events management',
    text: 'The application will manage all personal and professional events and attach them to differents calendars.',
  },
  {
    title: 'Share events with you family, friends and team mates.',
    text: 'You can share your events with other people, send requests, cancel events.',
  },
  {
    title: 'Secure application which you can log in using your email and phone number.',
    text: 'You can configure your profile to log in using either your email or phone number.',
  },
  {
    title: 'Find your connections using their email address or number.',
    text: 'You can search people using their email address or phone number',
  },
  {
    title: 'You can book all your events using diarytree, cancel or reschedule them.',
    text: 'You can search for a professional and manage your appointment on the same application.',
  },
];

const Features = () => (
  <div className="dtree_features section__padding" id="features">
    <div className="dtree_features-heading">
      <h1 className="gradient__text">Imagine an application whre you can have all your events? You are not dreaming, its happening..</h1>
      <p>Request Early Access to Get Started</p>
    </div>
    <div className="dtree_features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
