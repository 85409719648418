import React from "react";
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";
// import { Navbar ,Nav} from 'react-bootstrap';
import Container from "react-bootstrap/Container";
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import 'antd/dist/antd.less';
import { FaAlignJustify } from "react-icons/fa";
import Navbar from "./Navbar";
import { useLocation } from "react-router-dom";

import Logo from "../../assets/images/Logo.png";

const Header = (props) => {
  const { pathname } = useLocation();
  // ...
  if (
    pathname.toLowerCase() === "/login" ||
    pathname.toLowerCase() === "/register"||
    (pathname.toLowerCase().includes("/availability")  && pathname.toLowerCase().includes('embed'))
  ) {
    return <></>;
  } else if (pathname.toLowerCase() === "/") {
    return <Navbar borderBottom="" marginBottom="" backgroundColor="#f0f6fc" />;
  }
  // render actual content
  //border-bottom: 1px solid;
  // , backgroundColor: '#f0f6fc'
  // position: 'absolute';
  // bottom: 0;
  // left: '50%';
  // width: '80%';
  // height: '2px';
  // backgroundColor: '#e0e0e0';
  // transform: 'translateX(-50%)';
  // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)';
  return (
    <Navbar borderBottom="1px solid" marginBottom="4px" backgroundColor="" />
  );

  // <Navbar  className="navbar navbar-expand-lg dt_navbar ftco-navbar-light" expand="lg">
  //       <Navbar.Brand>Logo</Navbar.Brand>
  //       {/* <Navbar.Toggle aria-controls="dt-nav" ><FaAlignJustify /></Navbar.Toggle> */}
  //       <Navbar.Collapse id="dt-nav">
  //           <Nav className="ml-auto navbar-nav ">
  //               <Link to="/" className="nav-item nav-link">Home</Link>
  //               {!props.currentUser && <Link to="/login" className="nav-item nav-link" >Login</Link>}
  //               {!props.currentUser && <Link to="/register" className="nav-item nav-link">Register</Link>}
  //               {props.currentUser && <Link to="/schedule" className="nav-item nav-link" >schedule</Link>}
  //               {props.currentUser && isAdmin && <Link to="/admin" className="nav-item nav-link">Admin</Link>}
  //               {props.currentUser && <a onClick={this.logout} className="nav-item nav-link">Logout</a>}
  //           </Nav>
  //       </Navbar.Collapse>
  // </Navbar>
};

export default Header;