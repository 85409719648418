// import React from 'react';

// const FriendsShares = ({ friendsShares }) => {
//   return (
//     <ul>
//       {friendsShares.map((friendsShare) => (
//         <li key={friendsShare.id}>
//           <strong>{friendsShare.friendName}</strong>: {friendsShare.content}
//           <br />
//           <a href={friendsShare.eventLink} target="_blank" rel="noopener noreferrer">
//             {friendsShare.eventName}
//           </a>
//           <button onClick={() => handleJoinEvent(friendsShare.eventId)}>Join</button>
//         </li>
//       ))}
//     </ul>
//   );
// };

// export default FriendsShares;
import React from 'react';
import { Avatar, Button } from '@mui/material';
import './dashboarddesign.css';
import { ThumbUpAltOutlined, ChatOutlined } from '@mui/icons-material';

const FriendsShares = ({ user, content, likes, comments }) => {
  return (
    <div className="post-container">
      <div className="user-info">
        <Avatar src={user.profilePicture} alt={user.name} />
        <div className="user-details">
          <h3>{user.name}</h3>
          <p>{user.jobTitle}</p>
        </div>
      </div>
      <div className="post-content">
        <p>{content}</p>
      </div>
      <div className="post-actions">
        <Button variant="outlined" startIcon={<ThumbUpAltOutlined />}>
          {likes} Likes
        </Button>
        <Button variant="outlined" startIcon={<ChatOutlined />}>
          {comments} Comments
        </Button>
      </div>
    </div>
  );
};
export default FriendsShares;