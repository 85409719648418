import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom"
import './index.css';
import App from './App';
import "bootstrap/dist/css/bootstrap.css";
import { Provider } from 'react-redux';
import store from "./app/services/store";
// import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { connect } from "react-redux";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
// As of React 18
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  // <I18nextProvider i18n={i18n}>
        <BrowserRouter>
              <Provider store={store}>
                <App />
            </Provider>
        </BrowserRouter>
  //  </I18nextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
