import { BehaviorSubject } from 'rxjs';
import ApiCore from './common/api-core';

const currentUserSubject = new BehaviorSubject(localStorage.getItem('token'));
const url = "AccountApi";

const apiTasks = new ApiCore({
    getAll: true,
    getSingle: true,
    post: true,
    put: false,
    patch: true,
    delete: false,
    url: url,
});

export const authenticationService = {
    login,
    register,
    logout,
    getUserByToken,
    getUserProfile,
    getUserSubscriptions,
    searchUserByEmailAndNumber,
    resetPassword,
    confirmEmail,
    contactUs,
    currentUser: currentUserSubject.asObservable(),
    get currentUserToken() {
        return currentUserSubject.value;
    }
};

function login(usernameText, emailText, passwordText, returnurlText) {
    return apiTasks.post('Login', {
        username: usernameText,
        email: emailText,
        password: passwordText,
        returnurl: returnurlText,
        grant_type: 'password',
    })
    .then(response => {
        if (!response.isAxiosError) {
            localStorage.setItem('token', response.token);
            currentUserSubject.next(response.token);
            return response.user;
        } else {
            throw new Error(response.message);
        }
    })
    .catch(error => {
        throw new Error('Login failed: ' + error.message);
    });
}

function register(usernameText, emailText, passwordText, confirmpasswordText) {
    return apiTasks.post('Register', {
        username: usernameText,
        email: emailText,
        password: passwordText,
        confirmpassword: confirmpasswordText,
        grant_type: 'password',
    })
    .then(response => {
        if (!response.isAxiosError) {
            localStorage.setItem('token', response.token);
            currentUserSubject.next(response.token);
            return response.user;
        } else {
            throw new Error(response.message);
        }
    })
    .catch(error => {
        throw new Error('Registration failed: ' + error.message);
    });
}

function getUserProfile(user) {
    return apiTasks.post('GetUserProfile', user)
    .then(response => {
        if (!response.isAxiosError) {
            return response;
        } else {
            throw new Error(response);
        }
    })
    .catch(error => {
        throw new Error('Get user profile failed: ' + error);
    });
}

function getUserSubscriptions(user) {
    return apiTasks.post('GetUserSubscriptions', user)
    .then(response => {
        if (!response.isAxiosError) {
            return response;
        } else {
            throw new Error(response);
        }
    })
    .catch(error => {
        throw new Error('Get user subscriptions failed: ' + error);
    });
}

async function resetPassword(email) {
    return apiTasks.post('ResetPassword', { Email: email })
    .then(response => {
        if (!response.isAxiosError) {
            return response;
        } else {
            throw new Error(response);
        }
    })
    .catch(error => {
        throw new Error('Reset password failed: ' + error);
    });
}

async function confirmEmail(userId, token) {
    return apiTasks.post('confirmEmail', { UserId: userId, Token: token })
    .then(response => {
        if (!response.isAxiosError) {
            return response;
        } else {
            throw new Error(response);
        }
    })
    .catch(error => {
        throw new Error('Confirm email failed: ' + error);
    });
}

function contactUs(formData) {
    return apiTasks.post('ContactUs', formData)
    .then(response => {
        if (!response.isAxiosError) {
            return response;
        } else {
            throw new Error(response.message);
        }
    })
    .catch(error => {
        throw new Error('Submit failed: ' + error.message);
    });
}

async function searchUserByEmailAndNumber(searchText) {
    return apiTasks.post('SearchUserByEmailAndNumber', { searchText })
    .then(response => {
        if (!response.isAxiosError) {
            return response;
        } else {
            throw new Error(response);
        }
    })
    .catch(error => {
        throw new Error('Search user failed: ' + error);
    });
}

function getUserByToken() {
    return apiTasks.post('GetUserByToken')
    .then(response => {
        return response;
    });
}

function logout() {
    localStorage.removeItem('token');
    currentUserSubject.next(null);
    window.location.reload(true);
}
