import React, { useState, useEffect, useRef } from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

import Button from '@mui/material/Button';
      
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { scheduleService, calendarService } from "../../app/services";

import "./sharedLayout.css";

const NotificationSubMenu = ({ notifications , deleteNotification}) => {
  const [isOpen, setOpen] = useState(false);
  const [openNotif, setOpenNotif] = React.useState(false);
  const [selectedNotification, setSelectedNotification] = React.useState();

  
  const notificationRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleClose = () => {
    deleteNotification(selectedNotification);
    
    setOpenNotif(false);
    setOpen(false);
  };

  const handleRefuse = () => {
    deleteNotification(selectedNotification);
    scheduleService.refuseScheduleProposition(selectedNotification);
    setOpenNotif(false);
    setOpen(false);
  };
  
  const handleAccept = () => {


    scheduleService.acceptScheduleProposition(selectedNotification);
    setOpenNotif(false);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleNotificationClick = (notification) => {
    if(notification.notificationType == "ScheduleModificationRequest")
    {
        // Handle the navigation to another page when clicking on a notification
        // console.log("Navigate to:", notification.link);
        setOpenNotif(true);
        setSelectedNotification(notification);
        console.log(selectedNotification);
    }
  };

  function formatDate(dateString) {
    if (!dateString) return ''; // Handle case where date string is empty or undefined

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add padding if necessary
    const day = date.getDate().toString().padStart(2, '0'); // Add padding if necessary
    const hours = date.getHours().toString().padStart(2, '0'); // Add padding if necessary
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Add padding if necessary

    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

  return (
    <div ref={notificationRef} className="notification-dropdown">
      <div className="notification-icon" onClick={handleToggle}>
        <span>
          <h6 style={{ color: "white" }}>{notifications.length}</h6>
        </span>
        <NotificationsNoneIcon />
        {/* <i className="fa fa-bell"></i> */}
      </div>
      {isOpen && (
        <div className="dropdown-content">
          {notifications.map((notification) => (
            <div
              key={notification.notificationId}
              className="notification-item"
              onClick={() => handleNotificationClick(notification)}
            >
              <span>{notification.notificationTitle}</span>
              <p>{notification.NotificationDescription}</p>
            </div>
          ))}
        </div>
      )}

      {openNotif && (
                      <Dialog
                        open={isOpen}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Are you sure you want to modify the schedule?"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                             {selectedNotification.notificationValue && selectedNotification.notificationValue.oldSchedule && (
                                  <div key={selectedNotification.notificationId} className="notification-item" >
                                    <span> {selectedNotification.notificationValue.oldSchedule.description}</span>
                                    <span>{formatDate(selectedNotification.notificationValue.oldSchedule.startDate)} to {formatDate(selectedNotification.notificationValue.newSchedule.startDate)}</span>
                                  </div>
                             )}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleRefuse}>Disagree</Button>
                        <Button onClick={handleAccept} autoFocus>Agree</Button>
                        </DialogActions>
                      </Dialog>
           )}
    </div>
  );
};

export default NotificationSubMenu;

