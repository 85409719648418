import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Subscriptions.css';
import { useTranslation } from 'react-i18next';

const Subscriptions = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleFreeClick = () => {
    navigate('/register');
  };

  const handleProClick = () => {
    navigate('/professionalcreation');
  };

  return (
    <div className="subscription-packages">
      <div className="subscription-package">
        <span className='header'>{t('HomePage.Subscription_allUsers')}</span>
        <span className='headerSub'>{t('HomePage.Subscription_forLife')}</span>
        <span className='price'>{t('HomePage.Subscription_free')}</span>
        <span className='priceSub'></span>
        <button className='pricingbutton' onClick={handleFreeClick}>{t('HomePage.Subscription_getStarted')}</button>
        <span className='pricingperiod'>{t('HomePage.Subscription_noSubscription')}</span>
        <ul className='pricingoptions'>
          <li className='table-feature-inner'>
            <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>{t('HomePage.Subscription_syncPlatforms')}</span>
          </li>
          <li className='table-feature-inner'>
            <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>{t('HomePage.Subscription_searchProfessionals')}</span>
          </li>
          <li className='table-feature-inner'>
            <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>{t('HomePage.Subscription_cancelEvents')}</span>
          </li>
          <li className='table-feature-inner'>
            <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>{t('HomePage.Subscription_receiveNotifications')}</span>
          </li>
        </ul>
      </div>
      <div className="subscription-package">
        <span className='header'>{t('HomePage.Subscription_pro')}</span>
        <span className='headerSub'>{t('HomePage.Subscription_getStarted')}</span>
        <span className='price'></span>
        <span className='priceSub'>/For 3 months</span>
        <button className='pricingbutton' onClick={handleProClick}>{t('HomePage.Subscription_subscribe')}</button>
        <span className='pricingperiod'>{t('HomePage.Subscription_manageEvents')}</span>
        <ul className='pricingoptions'>
          <li className='table-feature-inner'>
            <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>{t('HomePage.Subscription_manageClientsTimes')}</span>
          </li>
          <li className='table-feature-inner'>
            <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>{t('HomePage.Subscription_automationRescheduling')}</span>
          </li>
          <li className='table-feature-inner'>
            <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>{t('HomePage.Subscription_manageTeamsSchedules')}</span>
          </li>
          <li className='table-feature-inner'>
            <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>{t('HomePage.Subscription_manageClientsTimes')}</span>
          </li>
        </ul>
      </div>
      <div className="subscription-package">
        <span className='header'>{t('HomePage.Subscription_pro')}</span>
        <span className='headerSub'>{t('HomePage.Subscription_monthlyYearly')}</span>
        <span className='price'>50$ </span>
        <span className='priceSub'>/{t('HomePage.Subscription_monthYear')}</span>
        <button className='pricingbutton' onClick={handleProClick}>{t('HomePage.Subscription_subscribe')}</button>
        <span className='pricingperiod'>{t('HomePage.Subscription_manageEvents')}</span>
        <ul className='pricingoptions'>
          <li className='table-feature-inner'>
            <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>{t('HomePage.Subscription_manageClientsTimes')} </span>
          </li>
          <li className='table-feature-inner'>
            <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>{t('HomePage.Subscription_automationRescheduling')}</span>
          </li>
          <li className='table-feature-inner'>
            <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>{t('HomePage.Subscription_manageTeamsSchedules')}</span>
          </li>
          <li className='table-feature-inner'>
            <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <span>{t('HomePage.Subscription_manageClientsTimes')}</span>
          </li>
        </ul>
      </div>
      <div className="subscription-package">
       <span className='header'>{t('HomePage.Subscription_business')}</span>
        <span className='headerSub'>{t('HomePage.Subscription_monthlyYearly')}</span>
        <button style={{marginTop:'110px'}} className='pricingbutton'>{t('HomePage.Subscription_contactSales')}</button>
        <span className='price'></span>
        <span className='priceSub'></span>
        <span className='pricingperiod'>{t('HomePage.Subscription_exclusivesFunctionalities')}</span>
        
        <ul  className='pricingoptions'>
          <li className='table-feature-inner'>  <svg
            className="svg_tick"
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
          </svg>
            <span>{t('HomePage.Subscription_integrationSystems')}</span></li>
          <li className='table-feature-inner'><svg
            className="svg_tick"
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
          </svg>
            <span>{t('HomePage.Subscription_manageExternalContacts')}</span></li>
          <li className='table-feature-inner'><svg
            className="svg_tick"
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
          </svg>
            <span>{t('HomePage.Subscription_automationProcesses')}</span></li>
        </ul>
      </div>
    </div>
  );
};

export default Subscriptions;



// import React from 'react';
// import {useNavigate } from 'react-router-dom';
// import './Subscriptions.css';
// // import { t } from "i18next";
// import { useTranslation } from 'react-i18next';
// import { Link, useMatch, useResolvedPath } from "react-router-dom";

// const Subscriptions = () => {
//   const navigate = useNavigate();
//   const { t } = useTranslation();
//   const handleFreeClick = () => {
//     navigate('/register');
//   };
//   const handleProClick = () => {
//     navigate('/professionalcreation');
//   };

//   return (
//     <div className="subscription-packages">
//       <div className="subscription-package">
//         <span className='header'>{t('Subscription_allUsers')}</span>
//         <span className='headerSub'>{t('Subscription_forLife')}</span>
//         <span className='price'>{t('Subscription_free')}</span>
//         <span className='priceSub'></span>
//         <button  className='pricingbutton'  onClick={handleFreeClick}>{t('Subscription_getStarted')}</button>          
//         {/* <Link
//             to="/login"
//             className={`pricingbutton`}
//           >
//             Login
//           </Link> */}
//         <span className='pricingperiod'>{t('Subscription_noSubscription')}</span>
        
//         <ul  className='pricingoptions'>
//           <li className='table-feature-inner'>  <svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('Subscription_syncPlatforms')}</span></li>
//           <li className='table-feature-inner'><svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('Subscription_searchProfessionals')}</span></li>
//           <li className='table-feature-inner'><svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('Subscription_cancelEvents')}</span></li>
//             <li className='table-feature-inner'><svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('Subscription_receiveNotifications')}</span></li>
//         </ul>
//       </div>
//       <div className="subscription-package">
//         <span className='header'>{t('Subscription_pro')}</span>
//         <span className='headerSub'>{t('Subscription_getStarted')}</span>
//         <span className='price'></span>
//         <span className='priceSub'>/For 3 months</span>
//         <button  className='pricingbutton'  onClick={handleProClick}>{t('Subscription_subscribe')}</button>
//         <span className='pricingperiod'>{t('Subscription_manageEvents')}</span>
        
//         <ul  className='pricingoptions'>
//           <li className='table-feature-inner'>  <svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('Subscription_manageClientsTimes')}</span></li>
//           <li className='table-feature-inner'><svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('Subscription_automationRescheduling')}</span></li>
//           <li className='table-feature-inner'><svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('Subscription_manageTeamsSchedules')}</span></li>
//             <li className='table-feature-inner'>  <svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('Subscription_manageClientsTimes')}</span></li>
//         </ul>
//       </div>
//       <div className="subscription-package">
//         <span className='header'>{t('Subscription_pro')}</span>
//         <span className='headerSub'>{t('Subscription_monthlyYearly')}</span>
//         <span className='price'>50$ </span> 
//         <span className='priceSub'>/{t('Subscription_monthYear')}</span>
//         <button  className='pricingbutton'  onClick={handleProClick}>{t('Subscription_Subscribe')}</button>
//         <span className='pricingperiod'>{t('Subscription_manageEvents')}</span>
        
//         <ul  className='pricingoptions'>
//           <li className='table-feature-inner'>  <svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('Subscription_manageClientsTimes')}</span></li>
//           <li className='table-feature-inner'><svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('Subscription_automationRescheduling')}</span></li>
//           <li className='table-feature-inner'><svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('Subscription_manageTeamsSchedules')}</span></li>
//             <li className='table-feature-inner'>  <svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('Subscription_manageClientsTimes')}</span></li>
//         </ul>
//       </div>
//       <div className="subscription-package">
//         <span className='header'>{t('Subscription_business')}</span>
//         <span className='headerSub'>{t('Subscription_monthlyYearly')}</span>
//         <button style={{marginTop:'110px'}} className='pricingbutton'>{t('Subscription_contactSales')}</button>
//         <span className='price'></span>
//         <span className='priceSub'></span>
//         <span className='pricingperiod'>{t('Subscription_exclusivesFunctionalities')}</span>
        
//         <ul  className='pricingoptions'>
//           <li className='table-feature-inner'>  <svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('Subscription_integrationSystems')}</span></li>
//           <li className='table-feature-inner'><svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('Subscription_manageExternalContacts')}</span></li>
//           <li className='table-feature-inner'><svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('Subscription_automationProcesses')}</span></li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Subscriptions;
