import {
  REQUEST_CALENDARS_PENDING,
  REQUEST_CALENDARS_SUCCESS,
  REQUEST_CALENDARS_FAILED,
  RECEIVE_CALENDAR,
  SET_CALENDAR,
  ADD_CALENDAR,
  UPDATE_CALENDAR,
  DELETE_CALENDAR
} from '../actions/actionTypes';

const initialState = {
  calendars: [],
  currentCalendar: null
};

const calendarReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_CALENDARS_PENDING:
      return {
        ...state,
        isPending: true
      };
    case REQUEST_CALENDARS_SUCCESS:
      return {
        ...state,
        calendars: action.payload,
        isPending: false
      };
    case REQUEST_CALENDARS_FAILED:
      return {
        ...state,
        error: action.payload
      };
    case RECEIVE_CALENDAR:
      return {
        ...state,
        calendars: [...state.calendars, action.payload.calendar]
      };
    case SET_CALENDAR:
      return {
        ...state,
        currentCalendar: action.payload
      };
    case ADD_CALENDAR:
      return {
        ...state,
        calendars: [...state.calendars, action.payload]
      };
    case UPDATE_CALENDAR:
      return {
        ...state,
        calendars: state.calendars.map(calendar =>
          calendar.id === action.payload.id ? action.payload : calendar
        )
      };
    case DELETE_CALENDAR:
      return {
        ...state,
        calendars: state.calendars.filter(calendar =>
          calendar.id !== action.payload.id
        ),
        currentCalendar: null
      };
    default:
      return state;
  }
};

export default calendarReducer;