import React from "react";
import {useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import people from "../../assets/images/people.png";
import gifImage from "../../assets/images/diarytree-gif-reduit.gif"; // Add your GIF image path here
import "../../assets/styles/sectionstyle.css";

const Header = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/register');
  };


  return (



  <div className="dtree_header section__padding_side" id="home">
    <div className="dtree_header-main">
      <div className="dtree_header-content main-width">
        <div className="dtree_header-text">
          <span className="dtree_main_header">
            {t("HomePage.dashboardHeaderPresentation")}
            <br />
            <span style={{ color: "#664de4" }}>
              {t("HomePage.dashboardHeaderPresentationColored")}
            </span>
          </span>

          <h2>
            {t("HomePage.dashboardHeaderDetailsFirstPhrase")}
            <br />
            {t("HomePage.dashboardHeaderDetailsSecondPhraseFirstElement")}
            <span style={{ color: "#664de4" }}>
              {t("HomePage.dashboardHeaderDetailsSecondPhraseSecondElement")}
            </span>
            {t("HomePage.dashboardHeaderDetailsSecondPhraseThirdElement")}
          </h2>
        </div>
      </div>
      
      <button className="headerbutton" onClick={handleClick}>
        {t('HomePage.dashboardHeaderGetStartedButton')}
      </button>

      {/* <div className="dtree_header-content__people">
        <img src={people} alt="People" />
        <p>{t("HomePage.dashboardHeaderPeopleTextPhrase")}</p>
      </div> */}
    </div>

    <div className="dtree_header-gif">
      <img src={gifImage} alt="GIF" />
    </div>
  </div>
)
};


export default Header;

{/* <div style={{ height: "100vh" }} className="clearance">
  <video width="100%" height="100%" controls>
    <source loop="false"
      src={course?.courseAttachments?.attachmentUrl}
      type="video/mp4"
    />
  </video>
</div> */}

// import React from "react";
// import { t } from "i18next";
// import people from "../../assets/images/people.png";
// import "../../assets/styles/sectionstyle.css";
// const Header = () => (
//   <div className="dtree_header section__padding_side" id="home">
//     <div className="dtree_header-content main-width">
//       <span className="dtree_main_header">
//         {" "}
//         {t("HomePage.dashboardHeaderPresentation")} <br />
//         <span style={{ color: "#664de4" }}>
//           {t("HomePage.dashboardHeaderPresentationColored")}
//         </span>{" "}
//       </span>

//       <h2>
//         {t("HomePage.dashboardHeaderDetailsFirstPhrase")}
//         <br />
//         {t("HomePage.dashboardHeaderDetailsSecondPhraseFirstElement")}{" "}
//         <span style={{ color: "#664de4" }}>
//           {t("HomePage.dashboardHeaderDetailsSecondPhraseSecondElement")}
//         </span>{" "}
//         {t("HomePage.dashboardHeaderDetailsSecondPhraseThirdElement")}
//       </h2>

//       {/* <div className="dtree_header-content__input">
//         <input type="email" placeholder="Your Email Address" />
//         <button type="button">Get Started</button>
//       </div>  */}

//       <div className="dtree_header-content__people">
//         <img src={people} />
//         <p>{t("HomePage.dashboardHeaderPeopleTextPhrase")}</p>
//       </div>
//     </div>
//     {/* 
//     <div className="dtree_header-image">
//       <img src={ai} />
//     </div> */}
//   </div>
// );

// export default Header;
 