import { BehaviorSubject } from "rxjs";
import ApiCore from "./common/api-core";
import { handleResponse } from "../helpers";

// Get current Token value
const url = "CalendarApi";

const apiTasks = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: false,
  patch: true,
  delete: false,
  url: url,
});

export const scheduleService = {
  send,
  update,
  remove,
  acceptScheduleProposition,
  refuseScheduleProposition,
};

// send a schedule
function send(schedule) {
  return apiTasks.post("AddSchedule", schedule).then(function (response) {
    if (!response.isAxiosError) {
      return response;
    }
  });
}

// update a schedule
function update(schedule) {
  return apiTasks.post("UpdateSchedule", schedule).then(function (response) {
    if (!response.isAxiosError) {
      return response;
    }
  });
}

// delete
function remove(schedule) {
 // debugger;
  return apiTasks.post("DeleteSchedule", schedule).then(function (response) {
    if (!response.isAxiosError) {
      return response;
    }
  });
}


// update a schedule
function acceptScheduleProposition(schedule) {
  return apiTasks.post("SchedulePropositionAcceptedByUser", schedule).then(function (response) {
    if (!response.isAxiosError) {
      return response;
    }
  });
}


// update a schedule
function refuseScheduleProposition(schedule) {
  return apiTasks.post("SchedulePropositionRefusedByUser", schedule).then(function (response) {
    if (!response.isAxiosError) {
      return response;
    }
  });
}

