import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Card, CardActionArea, CardContent, Typography } from '@mui/material';

const ServiceSelector = ({ services, selectedServices, onSelect }) => {
  const { t } = useTranslation();

  const handleServiceSelect = (service) => {
    onSelect(service);
  };

  return (
    <Grid container spacing={2} marginTop='10px'>
      {services && services.length > 0 ? (
        services.map((service) => {
          const isSelected = selectedServices.some(s => s.id === service.id);

          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={service.id}>
              <Card
                onClick={() => handleServiceSelect(service)}
                style={{
                  marginLeft: '10px',
                  cursor: 'pointer',
                  minWidth: '70px',
                  flex: '0 0 auto',
                  border: isSelected ? '2px solid #4caf50' : 'none',
                  backgroundColor: isSelected ? '#e8f5e9' : 'white',
                }}
              >
                <CardActionArea
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: '5px', height: '100%' }}
                >
                  <div dangerouslySetInnerHTML={{ __html: service.icon }} />
                  <CardContent>
                    <Typography component="div">{t(service.name)}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })
      ) : (
        <Typography>No services available</Typography>
      )}
    </Grid>
  );
};

export default ServiceSelector;