import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { Badge, Avatar, Container, Select, Typography, Grid, MenuItem, TextField, ListItemAvatar, Button, ListItem, ListItemText, CircularProgress, Box } from '@mui/material';
import ServiceSelector from '../../Components/CalendarPageNew/Component/ServiceSelector';
import TimeSlotList from '../../Components/CalendarPageNew/Component/TimeSlotList';
import { LocalizationProvider, PickersDay, DateCalendar } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from 'react-i18next';

import { scheduleService, companyService } from "../../app/services";
import { useDispatch, useSelector } from "react-redux";

const ProfessionalAvailability = () => {
  const user = useSelector(state => state.user);
  const { params } = useParams();
  const [source, professionalId] = params.includes('&') ? params.split('&') : ['defaultSource', params];
  const initialValue = new Date();
  const [availability, setAvailability] = useState([]);
  const [professional, setProfessional] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedDate, setSelectedDate] = useState(initialValue);
  const [services, setServices] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [totalDuration, setTotalDuration] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [highlightedDays, setHighlightedDays] = useState([1, 2, 15]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      id: "",
      companyid: "",
      companyemployeeid: "",
      title: "",
      startDate: null,
      endDate: null,
      calendarid: "1",
      description: "",
    },
  });
  const { t } = useTranslation();
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    phoneNumber: ''
  });

  const [isUserConnected, setIsUserConnected] = useState(false);

  useEffect(() => {
    if (user.authenticated) {
      setIsUserConnected(true);
      setUserDetails({
        name: user.user.sub,
        email: user.user.email,
        phoneNumber: ''
      });
    }
  }, [user]);

  useEffect(() => {
    companyService.getCompaniesByCategoryAndLocationAndCompanyidNotConnected(null, null, professionalId)
      .then((company) => {
        setProfessional(company[0]);
      })
      .catch((error) => {
        console.error("Failed to fetch professionals:", error);
        setError(error);
      });
  }, [professionalId]);

  useEffect(() => {
    if (professional) {
      companyService.getCompanyEmployeesNotConnected(professional.username)
        .then(response => {
          const anyEmployee = { id: '0', name: 'Any Employee', role: '', image: '' };
          setEmployees([anyEmployee, ...response.data]);
        })
        .catch(error => {
          console.error('Failed to fetch employees:', error);
          setError(error);
        });
    }
  }, [professional]);

  useEffect(() => {
    if (selectedEmployee) {
      companyService.getCompanyServicesByEmployeeNotConnected(selectedEmployee.username)
        .then(response => {
          setServices(response);
          setValue('companyemployeeid', selectedEmployee.username);
        })
        .catch(error => {
          console.error('Failed to fetch services:', error);
          setError(error);
        });
    }
  }, [selectedEmployee, setValue]);

  useEffect(() => {
    if (selectedEmployee && selectedServices.length > 0) {
      const totalDuration = calculateTotalDuration();
      const totalPrice = calculateTotalPrice();
      setTotalDuration(totalDuration);
      setTotalPrice(totalPrice);

      companyService.getCompanyDisponibilitiesNotConnected(professional, selectedDate, 1, totalDuration)
        .then((availabilities) => {
          setTimeSlots(availabilities);
        })
        .catch((error) => {
          console.error('Failed to fetch time slots:', error);
          setError(error);
        });
    }
  }, [selectedDate, selectedServices, selectedEmployee, professional]);

  const calculateTotalDuration = () => {
    return selectedServices.reduce((total, service) => total + parseInt(service.duration, 10), 0);
  };

  const calculateTotalPrice = () => {
    return selectedServices.reduce((total, service) => total + parseInt(service.price, 10), 0);
  };

  const ServerDay = ({ day, outsideCurrentMonth, highlightedDays, ...other }) => {
    const isSelected = !outsideCurrentMonth;
  debugger;
    return (
      <Badge
        key={day.toString()}
        overlap="circular"
        badgeContent={isSelected ? '🌚' : undefined}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <PickersDay
          {...other} // Ensure this does not include invalid DOM props
          day={day}
          outsideCurrentMonth={outsideCurrentMonth}
        />
      </Badge>
    );
  };
  // const ServerDay = (props) => {
  //   const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
  //   const isSelected = !outsideCurrentMonth;
  //   return (
  //     <Badge
  //       key={day.toString()}
  //       overlap="circular"
  //       badgeContent={isSelected ? '🌚' : undefined}
  //       anchorOrigin={{
  //         vertical: 'top',
  //         horizontal: 'right',
  //       }}
  //     >
  //       <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
  //     </Badge>
  //   );
  // };

  const handleEmployeeSelect = (event) => {
    const employeeId = event.target.value;
    const employee = employees.find(emp => emp.id === employeeId);
    setSelectedEmployee(employee);
  };

  const handleServiceSelect = (service) => {
    setSelectedServices((prevSelectedServices) => {
      if (prevSelectedServices.includes(service)) {
        return prevSelectedServices.filter((s) => s.id !== service.id);
      } else {
        return [...prevSelectedServices, service];
      }
    });
  };

  const handleSlotSelect = (slot) => {
    setSelectedSlot(slot);

    
    setValue('companyemployeeid', slot.companyemployeeid);
    setValue('companyid', slot.companyid);
    setValue('title', slot.title);
    setValue('description', slot.description);
  };

  const handleMonthChange = (date) => {
    setHighlightedDays([]);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const shouldDisableDate = (date) => {
    return date < new Date();
  };

  const handleFormSubmit = (data) => {
    if (!isUserConnected) {
      alert("Please provide your details");
    } else {
      const submissionData = {
        ...data,
        userDetails: {
          name: userDetails.name,
          email: userDetails.email,
          phoneNumber: userDetails.phoneNumber,
        },
        startDate: selectedSlot ? selectedSlot.startDate : null,
        endDate: selectedSlot ? selectedSlot.endDate : null,
      };

      console.log("Booking data:", submissionData);
      scheduleService.send(submissionData);
      // Proceed with the booking submission (e.g., send to API)
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error loading data</Typography>;

  return (
    <Container style={{ marginTop: '100px' }}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box>
              <Avatar src={professional.logo} alt={professional.name} style={{ width: 80, height: 80, marginBottom: 16 }} />
              <Typography variant="h4" className="professional-name">{professional.name}</Typography>
              <Typography variant="h5" className="professional-address">{professional.address}</Typography>
              <Typography variant="h5" className="professional-hours">Open today: 08:00-20:00</Typography>
            </Box>
            <Box marginTop={2}>
              <Typography variant="h6">Select Employee</Typography>
              <Controller
                name="companyemployeeid"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    value={selectedEmployee ? selectedEmployee.id : ''}
                    fullWidth
                    onChange={handleEmployeeSelect}
                    label="Employee"
                    renderValue={(selected) => {
                      const employee = employees.find(emp => emp.id === selected);
                      if (!employee) return null;
                      return (
                        <Box display="flex" alignItems="center">
                          <Avatar src={employee.image || ''} alt={employee.name} style={{ marginRight: 8 }}>
                            {employee.image ? '' : employee.name.charAt(0)}
                          </Avatar>
                          <Typography>{employee.name}</Typography>
                        </Box>
                      );
                    }}
                  >
                    {employees.length > 0 ? (
                      employees.map((employee) => (
                        <MenuItem key={employee.id} value={employee.id}>
                          <ListItemAvatar style={{ minWidth: '40px' }}>
                            <Avatar src={employee.image} alt={employee.name} />
                          </ListItemAvatar>
                          {employee.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No employees available</MenuItem>
                    )}
                  </Select>
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={5}>
            <Box>
              <Typography variant="h6">Select Services</Typography>
              <ServiceSelector
                services={services}
                selectedServices={selectedServices}
                onSelect={handleServiceSelect}
              />
            </Box>
            <Box marginTop={2}>
              <Typography variant="h6">Select Date and Time</Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateCalendar
                  value={selectedDate}
                  onChange={handleDateChange}
                  shouldDisableDate={shouldDisableDate}
                  onMonthChange={handleMonthChange}

                />

              </LocalizationProvider>

              
{/* renderDay={(day, _value, DayComponentProps) => (
                    <ServerDay
                      day={day}
                      {...DayComponentProps}
                      highlightedDays={highlightedDays}
                    />
                  )} */}
              <Box marginTop={2}>
                <TimeSlotList
                  timeSlots={timeSlots}
                  selectedSlot={selectedSlot}
                  onSlotSelect={handleSlotSelect}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box>
              <Typography variant="h6">Booking Summary</Typography>
              <ListItem>
                <ListItemText primary="Company" secondary={professional.name} />
                <ListItemText primary="Address" secondary={professional.address} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Selected Employee" secondary={selectedEmployee ? selectedEmployee.name : 'None'} />
              </ListItem>
                <ListItem>
                <ListItemText primary="Selected Services" />
                <Box component="div" marginLeft={2}>
                  {selectedServices.length > 0 ? (
                    <ul>
                      {selectedServices.map(currentservice => {
                        const service = services.find(s => s.id === currentservice.id);
                        return <li key={service.id}>{t(service.name)}</li>;
                      })}
                    </ul>
                  ) : (
                    <Typography>No services selected</Typography>
                  )}
                </Box>
              </ListItem>
              <ListItem>
                <ListItemText primary="Selected Date" secondary={selectedDate ? selectedDate.toLocaleDateString() : 'None'} />
                <ListItemText primary="Selected Slot" secondary={selectedSlot ? (
                  <Typography>{new Date(selectedSlot.startDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Typography>
                ) : (
                  <Typography>No slot selected</Typography>
                )} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Total Duration" secondary={`${totalDuration} minutes`} />
                <ListItemText primary="Total Price" secondary={`$${totalPrice.toFixed(2)}`} />
              </ListItem>
            </Box>

            <Box marginTop={2}>
              {!isUserConnected && (
                <>
                  <Typography variant="h6">Your Details</Typography>
                  <Controller
                    name="userName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="Name"
                        fullWidth
                        {...field}
                        value={userDetails.name}
                        onChange={(e) => setUserDetails({ ...userDetails, name: e.target.value })}
                        margin="normal"
                      />
                    )}
                  />
                  <Controller
                    name="userEmail"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="Email"
                        fullWidth
                        {...field}
                        value={userDetails.email}
                        onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
                        margin="normal"
                      />
                    )}
                  />
                  <Controller
                    name="userPhoneNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="Phone Number"
                        fullWidth
                        {...field}
                        value={userDetails.phoneNumber}
                        onChange={(e) => setUserDetails({ ...userDetails, phoneNumber: e.target.value })}
                        margin="normal"
                      />
                    )}
                  />
                </>
              )}

              <Box marginTop={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading || !selectedSlot}
                >
                  {loading ? <CircularProgress size={24} /> : 'Book Now'}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default ProfessionalAvailability;

// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import { useForm, Controller } from "react-hook-form";
// import { Badge,Avatar ,Container,Select, Typography,Grid,MenuItem,TextField , ListItemAvatar,Button , ListItem, ListItemText, CircularProgress, Box, Divider } from '@mui/material';
// import ServiceSelector from '../../Components/CalendarPageNew/Component/ServiceSelector' ;
// import TimeSlotList from '../../Components/CalendarPageNew/Component/TimeSlotList';
// import { LocalizationProvider, PickersDay, DateCalendar } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { useTranslation } from 'react-i18next';

// import { companyService } from "../../app/services"; // Ensure this service is correctly set up
// import {useDispatch, useSelector} from "react-redux";



// const ProfessionalAvailability = () => {
//   const user = useSelector(state=>state.user);

//   const { params } = useParams();
//   const [source, professionalId] = params.includes('&') ? params.split('&') : ['defaultSource', params];
//   // const { source,professionalId } = useParams(); // Get the professional ID or username from the URL

  
//   const initialValue = new Date();
//   const [availability, setAvailability] = useState([]);
//   const [professional, setProfessional] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);  
//   const [employees, setEmployees] = useState([]);
//   const [selectedDate, setSelectedDate] = useState(initialValue);
//   const [services, setServices] = useState([]);
//   const [selectedEmployee, setSelectedEmployee] = useState("");
//   const [selectedServices, setSelectedServices] = useState([]);
//   const [totalDuration, setTotalDuration] = useState(0);
//   const [totalPrice, setTotalPrice] = useState(0.0);
//   const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15]);
//   const [selectedSlot, setSelectedSlot] = useState(null); // Add state for selected slot
//   const [timeSlots, setTimeSlots] = useState([]);
//   const {
//     register,
//     handleSubmit,
//     control,
//     reset,
//     formState: { errors },
//     setValue,
//   } = useForm();
//   const { t } = useTranslation();

//   const [userDetails, setUserDetails] = useState({
//     name: '',
//     email: '',
//     phoneNumber: ''
//   });

//   if (loading) return <CircularProgress />;
//   if (error) return <Typography color="error">Error loading data</Typography>;

//   const [isUserConnected, setIsUserConnected] = useState(false); // Assume false initially

//   useEffect(() => {
//         console.log(user);
//         if(user.authenticated)
//         {
//           setIsUserConnected(true);
//           setUserDetails({
//             name: user.user.sub,
//             email: user.user.email,
//             phoneNumber: ''
//           });
//         }
//   }, []);


//   useEffect(() => {
//     // Fetch professional data
//     companyService
//       .getCompaniesByCategoryAndLocationAndCompanyidNotConnected(null, null, professionalId)
//       .then((company) => {
//         setProfessional(company[0]);
//       })
//       .catch((error) => {
//         console.error("Failed to fetch professionals:", error);
//         setError(error);
//       });
//   }, [professionalId]);

//   useEffect(() => {
//     if (professional) {
//       // Fetch employees for the selected professional
//       companyService.getCompanyEmployeesNotConnected(professional.username)
//         .then(response => {
//           const anyEmployee = { id: '0', name: 'Any Employee', role: '', image: '' };
//           setEmployees([anyEmployee, ...response.data]);
//         })
//         .catch(error => {
//           console.error('Failed to fetch employees:', error);
//           setError(error);
//         });
//     }
//   }, [professional]);

//   useEffect(() => {
//     if (selectedEmployee) {
//       // Fetch services for the selected employee
//       companyService.getCompanyServicesByEmployeeNotConnected(selectedEmployee.username)
//         .then(response => {
//           setServices(response);
//           setValue('companyemployeeid', selectedEmployee.username);
//         })
//         .catch(error => {
//           console.error('Failed to fetch services:', error);
//           setError(error);
//         });
//     }
//   }, [selectedEmployee, setValue]);

//   useEffect(() => {
//     if (selectedEmployee && selectedServices.length > 0) {
//       const totalDuration = calculateTotalDuration();

//       const totalPrice = calculateTotalPrice();

//       setTotalDuration(totalDuration);
//       setTotalPrice(totalPrice);

//       companyService
//         .getCompanyDisponibilitiesNotConnected(professional, selectedDate, 1, totalDuration)
//         .then((availabilities) => {
//           setTimeSlots(availabilities);
//         })
//         .catch((error) => {
//           console.error('Failed to fetch time slots:', error);
//           setError(error);
//         });
//     }
//   }, [selectedDate, selectedServices, selectedEmployee, professional]);

//   const calculateTotalDuration = () => {
//     return selectedServices.reduce((total, service) => total + parseInt(service.duration, 10), 0);
//   };

//   const calculateTotalPrice = () => {
//     return selectedServices.reduce((total, service) => total + parseInt(service.price, 10), 0);
//   };


//   const ServerDay = (props) => {
//     const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
//     const isSelected = !outsideCurrentMonth;
//     return (
//       <Badge
//         key={day.toString()}
//         overlap="circular"
//         badgeContent={isSelected ? '🌚' : undefined}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'right',
//         }}
//       >
//         <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
//       </Badge>
//     );
//   };

//   const handleEmployeeSelect = (event) => {
//     const employeeId = event.target.value;
//     const employee = employees.find(emp => emp.id === employeeId);
//     setSelectedEmployee(employee);
//   };

//   const handleServiceSelect = (service) => {
//     setSelectedServices((prevSelectedServices) => {
//       if (prevSelectedServices.includes(service)) {
//         return prevSelectedServices.filter((s) => s.id !== service.id);
//       } else {
//         return [...prevSelectedServices, service];
//       }
//     });
//   };

//   const handleSlotSelect = (slot) => {
//     setSelectedSlot(slot);
//   };

//   const handleMonthChange = (date) => {
//     setHighlightedDays([]);
//     // fetchHighlightedDays(date);
//   };

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   const shouldDisableDate = (date) => {
//     return date < new Date(); // Disable dates before today
//   };

//   const handleFormSubmit = (data) => {
//     if (!isUserConnected) {
//       // Show user details form if not connected
//       alert("Please provide your details");
//     } else {
//       // Process booking
//       console.log("Booking data:", {
//         ...data,
//         userDetails
//       });
//     }
//   };

//   return (
//     <Container style={{ marginTop: '100px' }}>
//       <form onSubmit={handleSubmit(handleFormSubmit)}>
//         <Grid container spacing={2}>
//           {/* First Column: Company Description */}
//           <Grid item xs={12} md={3}>
//             <Box>
//               <Avatar src={professional.logo} alt={professional.name} style={{ width: 80, height: 80, marginBottom: 16 }} />
//               <Typography variant="h4" className="professional-name">{professional.name}</Typography>
//               <Typography variant="h5" className="professional-address">{professional.address}</Typography>
//               <Typography variant="h5" className="professional-hours">Open today: 08:00-20:00</Typography>
//             </Box>
//             <Box marginTop={2}>
//               <Typography variant="h6">Select Employee</Typography>
//               <Select
//                 value={selectedEmployee ? selectedEmployee.id : ''}
//                 fullWidth
//                 onChange={handleEmployeeSelect}
//                 label="Employee"
//                 renderValue={(selected) => {
//                   const employee = employees.find(emp => emp.id === selected);
//                   if (!employee) return null;
//                   return (
//                     <Box display="flex" alignItems="center">
//                       <Avatar src={employee.image || ''} alt={employee.name} style={{ marginRight: 8 }}>
//                         {employee.image ? '' : employee.name.charAt(0)}
//                       </Avatar>
//                       <Typography>{employee.name}</Typography>
//                     </Box>
//                   );
//                 }}
//               >
//                 {employees.length > 0 ? (
//                   employees.map((employee) => (
//                     <MenuItem key={employee.id} value={employee.id}>
//                       <ListItemAvatar style={{ minWidth: '40px' }}>
//                         <Avatar src={employee.image || ''} alt={employee.name} style={{ marginRight: 8 }}>
//                           {employee.image ? '' : employee.name.charAt(0)}
//                         </Avatar>
//                       </ListItemAvatar>
//                       <ListItemText primary={employee.name} secondary={employee.role} />
//                     </MenuItem>
//                   ))
//                 ) : (
//                   <MenuItem value="None">
//                     <ListItemText primary="No employees available" />
//                   </MenuItem>
//                 )}
//               </Select>
//             </Box>
//           </Grid>

//           {/* Second Column: Selection of Company and Services */}
//           <Grid item xs={12} md={5}>
//             <Box>
//               <Typography variant="h6">Select Services</Typography>
//               <ServiceSelector
//                 services={services}
//                 selectedServices={selectedServices}
//                 onSelect={handleServiceSelect}
//               />
//             </Box>
//             <Box marginTop={2}>
//               <Typography variant="h6">Select Date and Time</Typography>
//               <LocalizationProvider dateAdapter={AdapterDateFns}>
//                 <DateCalendar
//                   value={selectedDate}
//                   onChange={handleDateChange}
//                   shouldDisableDate={shouldDisableDate}
//                   onMonthChange={handleMonthChange}
//                   renderDay={(day, _value, DayComponentProps) => (
//                     <ServerDay
//                       day={day}
//                       {...DayComponentProps}
//                       highlightedDays={highlightedDays}
//                     />
//                   )}
//                 />
//               </LocalizationProvider>
//               <Box marginTop={2}>
//                 <TimeSlotList
//                   timeSlots={timeSlots}
//                   selectedSlot={selectedSlot}
//                   onSlotSelect={handleSlotSelect}
//                 />
//               </Box>
//             </Box>
//           </Grid>

//           {/* Third Column: Summary and Confirmation */}
//           <Grid item xs={12} md={4}>
//             <Box>
//             <Typography variant="h6">Booking Summary</Typography>
//                   <ListItem>
//                       <ListItemText primary="Company" secondary= {professional.name} />
//                       <ListItemText primary="Address" secondary= {professional.address} />
//                     </ListItem>
//                   <ListItem>
//                       <ListItemText primary="Selected Employee" secondary=  {selectedEmployee ? selectedEmployee.name : 'None'} />
//                     </ListItem>
//                   <ListItem>
//                       <ListItemText primary="Selected Services" secondary= {<ul>
//                             {selectedServices.length > 0 ? (
//                               selectedServices.map(currentservice => {
//                                 const service = services.find(s => s.id === currentservice.id);
//                                 return <li key={service.id}>{t(service.name)}</li>;
//                               })
//                             ) : (
//                               <li>No services selected</li>
//                             )}
//                           </ul>} />
//                     </ListItem>
//                   <ListItem>
//                       <ListItemText primary="Selected Date" secondary= {selectedDate ? selectedDate.toLocaleDateString() : 'None'} />                
//                       <ListItemText primary="Selected Slot" secondary= {selectedSlot ? (
//                           <Typography>{new Date(selectedSlot.startDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Typography>
//                         ) : (
//                           <Typography>No slot selected</Typography>
//                         )}/>
//                     </ListItem>
//                     <ListItem>
//                       <ListItemText primary="Total Duration" secondary={`${totalDuration} minutes`} />
//                       <ListItemText primary="Total Price" secondary={`$${totalPrice.toFixed(2)}`} />
//                     </ListItem>
//             </Box>

//             <Box marginTop={2}>
//               {!isUserConnected && (
//                 <>
//                   <Typography variant="h6">Your Details</Typography>
//                   <TextField
//                     label="Name"
//                     fullWidth
//                     value={userDetails.name}
//                     onChange={(e) => setUserDetails({ ...userDetails, name: e.target.value })}
//                     margin="normal"
//                   />
//                   <TextField
//                     label="Email"
//                     fullWidth
//                     value={userDetails.email}
//                     onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
//                     margin="normal"
//                   />
//                   <TextField
//                     label="Phone Number"
//                     fullWidth
//                     value={userDetails.phoneNumber}
//                     onChange={(e) => setUserDetails({ ...userDetails, phoneNumber: e.target.value })}
//                     margin="normal"
//                   />
//                 </>
//               )}

//               <Box marginTop={2}>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   type="submit"
//                   disabled={loading || !selectedSlot}
//                 >
//                   {loading ? <CircularProgress size={24} /> : 'Book Now'}
//                 </Button>
//               </Box>
//             </Box>
//           </Grid>
//         </Grid>
//       </form>
//     </Container>
//   );
// };

// export default ProfessionalAvailability;

//   useEffect(() => {
//     // Fetch professionals from your backend
//     companyService
//     .getCompaniesByCategoryAndLocationAndCompanyidNotConnected(null,null,professionalId)
//       .then((company) => {
//         setProfessional(company[0]);
//       })
//       .catch((error) => {
//         console.error("Failed to fetch professionals:", error);
//       });
//   }, []);

//   useEffect(() => {
//     if (professional) {
//       // Fetch employees for the selected professional
//       companyService.getCompanyEmployeesNotConnected(professional.username)
//         .then(response => {
//           const anyEmployee = { id: '0', name: 'Any Employee', role: '', image: '' };
//           setEmployees([anyEmployee, ...response.data]);
//           return true;
//         });
//     }
//   }, [professional]);

//   useEffect(() => {
//     if (selectedEmployee) {
//       // Fetch employees for the selected professional
  
//       companyService.getCompanyServicesByEmployeeNotConnected(selectedEmployee.username)
//         .then(response => {
//           setServices(response);
//           setValue('companyemployeeid', selectedEmployee.username);
//           return true;
//         });
//     }
//   }, [selectedEmployee]);

//   useEffect(() => {

//         if(selectedEmployee && selectedServices && selectedServices.length>0)
//         {
          
//           const totalDuration = calculateTotalDuration();

//           companyService
//             .getCompanyDisponibilitiesNotConnected(professional, selectedDate, 1 ,totalDuration)
//             .then((availabilities) => {
//               setTimeSlots(availabilities);
//             })
//             .catch((error) => {
//               console.error('Failed to fetch time slots:', error);
//             });

            
//         }
//   }, [selectedDate, selectedServices]);

//   const calculateTotalDuration = () => {
//     return selectedServices.reduce((total, service) => total + parseInt(service.duration, 10), 0);
//     //selectedServices.reduce((total, service) => total + service.duration, 0);
//   };

//   function ServerDay(props) {
//     const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
//     // && highlightedDays.indexOf(props.day.date()) >= 0
//     const isSelected = !props.outsideCurrentMonth;

//     return (
//       <Badge
//       key={props.day.toString()}
//       overlap="circular"
//       badgeContent={isSelected ? '🌚' : undefined}
//       anchorOrigin={{
//         vertical: 'top',
//         horizontal: 'right',
//       }}

//     >
//         <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
//       </Badge>
//                 // fontSize: '5px',
//                 // height: '10px',
//                 // width: '5px',
//                 // borderRadius: '50%',
//     );
//   }  

//   const handleEmployeeSelect = (event) => {
//     const employeeId = event.target.value;
//     const employee = employees.find(emp => emp.id === employeeId);
//     setSelectedEmployee(employee);
//     };
  
//     const handleServiceSelect = (service) => { 
//       setSelectedServices((prevSelectedServices) => {
  
//         if (prevSelectedServices.includes(service)) {
//           // Remove service if already selected
//           return prevSelectedServices.filter((s) => s.id !== service.id);
//         } else {
//           // Add service if not already selected
//           return [...prevSelectedServices, service];
//         }
//       });
//     };
  
//     const handleSlotSelect = (slot) => {
  
//       setSelectedSlot(slot);
//     };


//     const handleMonthChange = (date) => {
//       if (requestAbortController.current) {
//         // make sure that you are aborting useless requests
//         // because it is possible to switch between months pretty quickly
//         requestAbortController.current.abort();
//       }
  
//       setIsLoading(true);
//       setHighlightedDays([]);
//       fetchHighlightedDays(date);
//     };

//     const handleDateChange = (date) => {
//       setSelectedDate(date);
//     };

//     const shouldDisableDate = (date) => {
//       return date < new Date(); // Disable dates before today
//     };

//     const handleFormSubmit = (data) => {
//       // Prevent the default form submission
//       // Process the form data
//       handleSubmitForm(data);
//       onFinish();
//       // Your form submission logic goes here
//     };
  
//   const Marker = () => <div style={{ color: 'red' }}>📍</div>;

//   return (
//     <Container style={{marginTop:'100px'}}>
//     <form>
//       <Grid container spacing={2}>
//         {/* First Column: Company Description */}
//         <Grid item xs={12} md={3}>
//           <Box>
//             <Avatar src={professional.logo} alt={professional.name} style={{ width: 80, height: 80, marginBottom: 16 }} />
//             <Typography variant="h4" className="professional-name">{professional.name}</Typography>
//             <Typography variant="h5" className="professional-address">{professional.address}</Typography>
//             <Typography variant="h5" className="professional-hours">Open today: 08:00-20:00</Typography>
//           </Box>
//           <Box marginTop={2}>
//             <Typography variant="h6">Select Employee</Typography>
//             <Select
//               value={selectedEmployee ? selectedEmployee.id : ''}
//               fullWidth
//               onChange={handleEmployeeSelect}
//               label="Employee"
//               renderValue={(selected) => {
//                 const employee = employees.find(emp => emp.id === selected);
//                 if (!employee) return null;
//                 return (
//                   <Box display="flex" alignItems="center">
//                     <Avatar src={employee.image || ''} alt={employee.name} style={{ marginRight: 8 }}>
//                       {employee.image ? '' : employee.name.charAt(0)}
//                     </Avatar>
//                     <Typography>{employee.name}</Typography>
//                   </Box>
//                 );
//               }}
//             >
//               {employees.length > 0 ? (
//                 employees.map((employee) => (
//                   <MenuItem key={employee.id} value={employee.id}>
//                     <ListItemAvatar style={{ minWidth: '40px' }}>
//                       <Avatar src={employee.image || ''} alt={employee.name} style={{ marginRight: 8 }}>
//                         {employee.image ? '' : employee.name.charAt(0)}
//                       </Avatar>
//                     </ListItemAvatar>
//                     <ListItemText primary={employee.name} secondary={employee.role} />
//                   </MenuItem>
//                 ))
//               ) : (
//                 <MenuItem value="None">
//                   <ListItemText primary="No employees available" />
//                 </MenuItem>
//               )}
//             </Select>
//           </Box>
//         </Grid>

//         {/* Second Column: Selection of Company and Services */}
//         <Grid item xs={12} md={5}>
//           <Box>
//             <Typography variant="h6">Select Services</Typography>
//             <ServiceSelector
//               services={services}
//               selectedServices={selectedServices}
//               onSelect={handleServiceSelect}
//             />
//           </Box>
//           <Box marginTop={2}>
//             <LocalizationProvider dateAdapter={AdapterDateFns}>
//               <DateCalendar
//                 defaultValue={new Date()}
//                 loading={loading}
//                 value={selectedDate ?? ""}
//                 onMonthChange={handleMonthChange}
//                 onChange={handleDateChange}
//                 renderLoading={() => <DayCalendarSkeleton />}
//                 shouldDisableDate={shouldDisableDate}
//                 slots={{
//                   day: ServerDay,
//                 }}
//                 slotProps={{
//                   day: {
//                     highlightedDays,
//                   },
//                 }}
//               />
//             </LocalizationProvider>
//             <TimeSlotList 
//               professional={professional} 
//               employee={selectedEmployee} 
//               selectedServices={selectedServices} 
//               timeSlots={timeSlots} 
//               onSlotSelect={handleSlotSelect} 
//               selectedDate={selectedDate} 
//               interval={30} 
//             />
//           </Box>
//         </Grid>

//         {/* Third Column: Recapitulation */}
//         <Grid item xs={12} md={4}>
//           <Box>
//             <Typography variant="h6">Recap</Typography>
//             <Typography variant="body1"><strong>Company:</strong> {professional.name}</Typography>
//             <Typography variant="body1"><strong>Address:</strong> {professional.address}</Typography>
//             <Typography variant="body1"><strong>Selected Employee:</strong> {selectedEmployee ? selectedEmployee.name : 'None'}</Typography>
//             <Typography variant="body1"><strong>Selected Services:</strong></Typography>
//             <ul>
//               {selectedServices.length > 0 ? (
//                 selectedServices.map(currentservice => {
//                   const service = services.find(s => s.id === currentservice.id);
//                   return <li key={service.id}>{service.name}</li>;
//                 })
//               ) : (
//                 <li>No services selected</li>
//               )}
//             </ul>
//             <Typography variant="body1"><strong>Selected Date:</strong> {selectedDate ? selectedDate.toLocaleDateString() : 'None'}</Typography>
//             <Typography variant="h5">Selected Slot:</Typography>
//             {selectedSlot ? (
//               <Typography>{new Date(selectedSlot.startDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Typography>
//             ) : (
//               <Typography>No slot selected</Typography>
//             )}
//             <Typography variant="body1"><strong>Total Duration:</strong> {totalDuration} minutes</Typography>
//             <Typography variant="body1"><strong>Total Price:</strong> ${totalPrice.toFixed(2)}</Typography>
//           </Box>
//           <Box marginTop={2}>
//             <Button
//               type="submit"
//               variant="contained"
//               className="submit-btn"
//               onClick={handleSubmit(handleFormSubmit)} 
//             >
//               {t("AppointmentForm.book")}
//             </Button>
//           </Box>
//         </Grid>

//         {/* Divider */}
//         <Grid item xs={12}>
//           <Divider />
//         </Grid>
//       </Grid>
//     </form>

//     <Box mt={4} textAlign="center">
//       <Typography variant="body2" color="textSecondary">
//         Powered by DiaryTree
//       </Typography>
//     </Box>
//   </Container>
//   );
// };

// export default ProfessionalAvailability;

