import React, { useState, useEffect,useRef  } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, TextField, Select, MenuItem, Grid } from "@mui/material";
import { Typography } from "antd";
import { companyService } from '../../app/services';



const schema = yup.object().shape({
  companyName: yup.string().required("Company Name is required"),
  professionalType: yup.string().required("Company Type is required"),
  location: yup.string().required("Location is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  contactSurname: yup.string().required("Contact Surname is required"),
  contactFamilyName: yup.string().required("Contact Family Name is required"),
  contactEmail: yup.string().email("Invalid email").required("Contact Email is required"),
  contactPhoneNumber: yup.string().required("Contact Phone Number is required"),
});

const ProfessionalCreationPage = () => {
  const { handleSubmit, register, formState: { errors },setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const [libraries] = useState(["places"]);
  const [professionalTypes, setProfessionalTypes] = useState(); 
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const navigate = useNavigate();


  useEffect(() => {
    companyService.getAllCategories()
      .then((categories) => {
        setProfessionalTypes(categories);
      })
      .catch((error) => {
        console.error('Failed to fetch professional types:', error);
      });
  }, []);

  const onSubmit = (data) => {
    console.log(data);
    const formData = {
      ...data,
      gps: coordinates,
    };
    console.log('Form Data:', formData);
    companyService.requestCompany(formData)      
    .then((response) => {
      alert("The request have been sent successfully");
    })
    .catch((error) => {
      console.error('Failed to fetch professional types:', error);
      alert('Failed processing your request, please contact our support team.');
    });

    navigate("/");
    // Call the companyService method to send the data to the server
  };

////Manage autocomplete
  const { isLoaded, loadError } = useLoadScript(
    {
    googleMapsApiKey: 'AIzaSyB_j8NP_ZfkMFGHNxqsCL_pPhEIBlBp9As', // Replace with your API key
     libraries : libraries,
  }
);

  const [autocomplete, setAutocomplete] = useState(null);
  const inputRef = useRef(null);

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const location = place.formatted_address;
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      setValue('location', location);
      setCoordinates({ lat, lng });
      console.log('Location:', location);
      console.log('Latitude:', lat);
      console.log('Longitude:', lng);
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  if (loadError) return 'Error loading Google Maps API';
  if (!isLoaded) return 'Loading...';

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          margin: "50px",
          padding: "20px",
          backgroundColor: "white",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}> <Typography variant="h6">Company details</Typography></Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={6}>
              {/* <Typography variant="h6">Choose a professional:</Typography> */}
              <Select
                        label = "Choose a professional"
                    {...register("professionalType")}
                    defaultValue="" // Set the initial value to an empty string or a valid default value
                    required
                    fullWidth
                  >
                    {professionalTypes &&
                      professionalTypes.map((proftype) => (
                        <MenuItem key={proftype.id} value={proftype.id}>
                          {proftype.name}
                        </MenuItem>
                      ))}
                  </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Company Name"
                {...register("companyName")}
                error={!!errors.companyName}
                helpertext={errors.companyName?.message}
                required
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={3}>
            <Grid item xs={6}>
              <TextField style={{width:'100%'}}
                label="Email" 
                type="email"
                {...register("email")}
                error={!!errors.email}
                helpertext={errors.email?.message}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField  style={{width:'100%'}}
                label="Phone Number"
                {...register("phoneNumber")}
                error={!!errors.phoneNumber}
                helpertext={errors.phoneNumber?.message}
                required
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
              <TextField
                label="Location"
                inputRef={inputRef}
                {...register('location')}
                error={!!errors.location}
                helperText={errors.location?.message}
                required
                fullWidth
              />
            </Autocomplete>

            {/* <TextField
              label="Location"
              {...register("location")}
              error={!!errors.location}
              helpertext={errors.location?.message}
              required
              fullWidth
            /> */}
          </Grid>

          <Grid item xs={12}><Typography variant="h6">Contact details</Typography></Grid>

          <Grid container xs={12} item spacing={3}>
            <Grid item xs={6}>
              <TextField
                label="Contact Surname"
                {...register("contactSurname")}
                error={!!errors.contactSurname}
                helpertext={errors.contactSurname?.message}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Contact Family Name"
                {...register("contactFamilyName")}
                error={!!errors.contactFamilyName}
                helpertext={errors.contactFamilyName?.message}
                required
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container xs={12} item spacing={3}>
            <Grid item xs={6}>
              <TextField
                label="Contact Email"
                type="email"
                {...register("contactEmail")}
                error={!!errors.contactEmail}
                helpertext={errors.contactEmail?.message}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Contact Phone Number"
                {...register("contactPhoneNumber")}
                error={!!errors.contactPhoneNumber}
                helpertext={errors.contactPhoneNumber?.message}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export { ProfessionalCreationPage };
