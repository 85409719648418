import React, { useState } from "react";
import { useParams, useHistory,useNavigate,useLocation } from 'react-router-dom';
import {
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  Link,
  InputAdornment,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Image from "react-bootstrap/Image";
import { authenticationService } from "../../app/services";
import image from "../../assets/images/logo_transparent.png"; // Tell webpack this JS file uses this image
import imageGoogle from "../../assets/images/google_logo.png"; // Tell webpack this JS file uses this image
import imageApple from "../../assets/images/mac_logo.png"; // Tell webpack this JS file uses this image
import {
  EmailOutlined,
  LockOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import { useTranslation } from 'react-i18next';

const CustomTextField = (props) => (
  <TextField variant="outlined" {...props} fullWidth required />
);

const RegisterPage = () => {
  const [status, setStatus] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const returnUrl = query.get('returnUrl') || '/';

  const handleSubmit = async (values) => {
    setStatus();
    authenticationService
      .register(
        values.username,
        values.email,
        values.password,
        values.confirmpassword
      )
      .then(
        (user) => {
          const returnUrl = location.state?.from?.pathname || '/schedule';
          navigate(returnUrl);
        },
        (error) => {
          setStatus(error);
        }
      );
  };

  return (
    <div>
      <Formik
        initialValues={{
          username: "",
          email: "",
          password: "",
          confirmpassword: "",
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().required(t('LoginRegisterPage.Common_name') + " " + t('LoginRegisterPage.is_required')),
          email: Yup.string()
            .email(t('LoginRegisterPage.Please_enter_valid_email'))
            .required(t('LoginRegisterPage.Common_email') + " " + t('LoginRegisterPage.is_required')),
          password: Yup.string().required(t('LoginRegisterPage.Common_password') + " " + t('LoginRegisterPage.is_required')),
          confirmpassword: Yup.string()
            .oneOf([Yup.ref("password"), null], t('LoginRegisterPage.Passwords_must_match'))
            .required(t('LoginRegisterPage.Common_confirmpassword') + " " + t('LoginRegisterPage.is_required')),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <div
            align="center"
            style={{ height: "100vh", width: "100%" }}
            className="register-page"
          >
            <Grid
              container
              spacing={12}
              style={{ height: "100%", padding: "0px!important" }}
            >
              <Grid
                item
                xs={12}
                style={{
                  marginTop: "5%",
                  background: "#f5f7fd",
                  height: "110vh",
                }}
              >
                <Grid style={{ paddingTop: "10px", paddingBottom: "50px" }}>
                  <Link
                    href="/"
                    style={{ width: "200px", height: "100px", offset: "999" }}
                  >
                    {/* 
                  
                  margin: "30px 0px 30px 0",*/}
                    <img src={image} style={{ width: "10em" }} />
                  </Link>
                </Grid>
                <Grid className="auth-wrapper">
                  <Typography variant="h5">Welcome</Typography>
                  <Typography variant="h6">Sign up</Typography>
                  <Form>
                    <Field
                      align="center"
                      as={CustomTextField}
                      name="username"
                      placeholder={t('LoginRegisterPage.Enter_username')}
                      helperText={<ErrorMessage name="username" />}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutlineOutlined />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Field
                      align="center"
                      as={CustomTextField}
                      name="email"
                      placeholder={t('LoginRegisterPage.Enter_email')}
                      helperText={<ErrorMessage name="email" />}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailOutlined />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Field
                      align="center"
                      as={CustomTextField}
                      name="password"
                      placeholder={t('LoginRegisterPage.Enter_password')}
                      helperText={<ErrorMessage name="password" />}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlined />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Field
                      align="center"
                      as={CustomTextField}
                      name="confirmpassword"
                      placeholder={t('LoginRegisterPage.Confirm_password')}
                      helperText={<ErrorMessage name="confirmpassword" />}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlined />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button
                      type="submit"
                      className="submit-btn register"
                      variant="contained"
                      disabled={isSubmitting}
                      fullWidth
                    >
                      {isSubmitting  ? t('LoginRegisterPage.Loading') : t('LoginRegisterPage.Sign_up')}
                    </Button>
                  </Form>
                  {status && (
                    <Typography variant="body1" color="error">
                      {status}
                    </Typography>
                  )}
                  <Typography className="signup-link">
                  {t('LoginRegisterPage.Already_have_account')} <Link href="/login">{t('LoginRegisterPage.Sign_in')}</Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </Formik>
    </div>
  );
};

export { RegisterPage };

// import React, {useState} from "react";
// import {
//     Grid,
//     Paper,
//     Avatar,
//     TextField,
//     Button,
//     Typography,
//     Link,
// } from "@mui/material";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import Image from "react-bootstrap/Image";
// import Switch from "@mui/material/Switch";
// // import { makeStyles } from '@mui/styles';
// import image from "../../assets/images/logo_transparent.png"; // Tell webpack this JS file uses this image
// import imageGoogle from "../../assets/images/google_logo.png"; // Tell webpack this JS file uses this image
// import imageApple from "../../assets/images/mac_logo.png"; // Tell webpack this JS file uses this image
// import { GoogleLogin } from "@react-oauth/google";
// import AppleLogin from "react-apple-login";

// import { authenticationService } from "../../app/services";
// import { Navigate } from 'react-router-dom';

// const RegisterPage = () => {
//     const [isLoggedIn, setIsLoggedIn] = useState(authenticationService.currentUserToken != null);
//     const [status, setStatus] = useState("");

//     const classes = {
//         root: {
//             flexGrow: 1,
//         },
//         paper: {
//             //padding: theme.spacing(2),
//             textAlign: 'center',
//             //color: theme.palette.text.secondary,
//             width: '50%'
//         },
//     };

//     const btnstyle = { margin: '8px 0' }

//     const handleSubmit = async (values) => {
//         setStatus();
//         authenticationService
//             .register(values.username, values.email, values.password, values.confirmpassword)
//             .then(
//                 (user) => {

//                     window.location.reload();
//                 },
//                 (error) => {
//                     setSubmitting(false);
//                     setStatus(error);
//                 }
//             );
//     };

//     return (
//         <div>
//                 <Formik
//                     initialValues={{
//                         username: "",
//                         email: "",
//                         password: "",
//                         confirmpassword: "",
//                         remember: false,
//                     }}
//                     // validationSchema={Yup.object().shape({
//                     //     email: Yup.string()
//                     //         .email("please enter valid email")
//                     //         .required("Required"),
//                     //     password: Yup.string().required("Required"),
//                     //     // ,
//                     //     // confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
//                     // })}
//                     onSubmit={handleSubmit}
//                 >
//                     {({ isSubmitting }) => (
//                         <div
//                             className={classes.root}
//                             align="center"
//                             style={{
//                                 height: "86vh",
//                                 width: "100%",
//                             }}
//                         >
//                             <Grid
//                                 container
//                                 spacing={12}
//                                 style={{
//                                     height: "100%",
//                                     width: "60%",
//                                     Padding: "0px!important",
//                                 }}
//                             >
//                                 <Grid
//                                     item
//                                     xs={12}
//                                     style={{
//                                         marginTop: "5%",
//                                     }}
//                                 >

//                                     {/* <Paper className={classes.paper} style={{width:'80%'}}> */}
//                                     <Grid
//                                         style={{
//                                             width: "50%",
//                                             PaddingTop: "10px",
//                                         }}
//                                     >
//                                         <Link
//                                             to="/"
//                                             style={{
//                                                 margin: "30px 0px 30px 0",
//                                             }}
//                                         >
//                                             <Image
//                                                 src={image}
//                                                 style={{
//                                                     width: "15em",
//                                                 }}
//                                                 rounded
//                                             >

//                                             </Image>
//                                             {/* <h2>Diarytree</h2> */}
//                                         </Link>
//                                         <h5> Sign up to diarytree </h5>
//                                     </Grid>
//                                     <Grid>

//                                         {/* <GoogleLogin    /> */}
//                                         {/* <AppleLogin
//                                             clientId="YOUR_CLIENT_ID"
//                                             redirectURI="YOUR_REDIRECT_URL"
//                                             usePopup={true}
//                                            // callback={this.appleResponse} // Catch the response
//                                             scope="email name"
//                                             style={{
//                                                 marginTop: "16px",
//                                             }}
//                                             responseMode="query"
//                                             backgroundColor="Red"
//                                             render={(
//                                                 renderProps //Custom Apple Sign in Button
//                                             ) => (
//                                                 <>
//                                                     <button
//                                                         onClick={
//                                                             renderProps.onClick
//                                                         }
//                                                         style={{
//                                                             backgroundColor:
//                                                                 "white",
//                                                             padding: 10,
//                                                             border:
//                                                                 "1px solid black",
//                                                             fontFamily: "none",
//                                                             lineHeight: "25px",
//                                                             fontSize: "25px",
//                                                         }}
//                                                     >
//                                                          <Image   src={imageApple}  ></Image>
//                                                         <svg
//                                                             style={{
//                                                                 height: "18px",
//                                                                 marginright:
//                                                                     "12px",
//                                                                 width: "18px",
//                                                             }}
//                                                             xmlns="http://www.w3.org/2000/svg"
//                                                             viewBox="0 0 20 20"
//                                                         >

//                                                             <path
//                                                                 d="M10.16 -2.38419e-07C7.8 0.56 6.9 2.1 6.86 4.14C7.76 4.06 8.27 3.96 8.99 3.23C9.84 2.37 10.22 1.26 10.22 0.43C10.22 0.219999 10.2 0.129999 10.16 -2.38419e-07ZM10.33 3.84C9.86 3.84 9.28 3.95 8.59 4.19C7.94 4.42 7.46 4.54 7.16 4.54C6.92 4.54 6.46 4.43 5.77 4.23C5.07 4.03 4.47 3.92 3.99 3.92C1.69 3.92 1.19209e-07 6.11 1.19209e-07 9.22C1.19209e-07 10.86 0.49 12.58 1.48 14.35C2.47 16.12 3.49 17 4.5 17C4.84 17 5.29 16.89 5.83 16.65C6.36 16.41 6.84 16.32 7.26 16.32C7.69 16.32 8.2 16.43 8.77 16.64C9.39 16.87 9.85 17 10.18 17C11.9 17 13.48 14.12 14 12.41C12.8 12.05 11.72 10.53 11.72 8.85C11.72 7.3 12.46 6.44 13.52 5.53C12.82 4.66 11.93 3.84 10.33 3.84Z"
//                                                                 fill="#1D1C1D"
//                                                             >

//                                                             </path>
//                                                         </svg>
//                                                         <i className="fa-brands fa-apple px-2 ">

//                                                         </i>
//                                                         Continue with Apple
//                                                     </button>
//                                                 </>
//                                             )}
//                                         /> */}
//                                         {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage}  render={renderProps => (  //Custom Apple Sign in Button
//                                              <>
//                                                  <button
//                                                      onClick={renderProps.onClick}
//                                                      style={{
//                                                          backgroundColor: "white",
//                                                          padding: 10,
//                                                          border: "1px solid black",
//                                                          fontFamily: "none",
//                                                          lineHeight: "25px",
//                                                          fontSize: "25px"
//                                                      }}
//                                                  >
//                                                  <Image   src={imageGoogle}  ></Image>
//                                                      <i className="fa-brands fa-apple px-2 "></i>
//                                                      Continue with Google
//                                                  </button>
//                                                  </>
//                                              )} /> */}
//                                     </Grid>
//                                     <hr />
//                                     <Grid
//                                         item
//                                         style={{
//                                             width: "50%",
//                                         }}
//                                     >
//                                         <Form>
//                                             <Field
//                                                 as={TextField}
//                                                 label="Username"
//                                                 name="username"
//                                                 placeholder="Enter username"
//                                                 fullWidth
//                                                 required
//                                                 helperText={
//                                                     <ErrorMessage name="username" />
//                                                 }
//                                             />
//                                             <Field
//                                                 align="center"
//                                                 as={TextField}
//                                                 label="Email"
//                                                 name="email"
//                                                 placeholder="Enter your email address .."
//                                                 fullWidth
//                                                 required
//                                                 helperText={
//                                                     <ErrorMessage name="email" />
//                                                 }
//                                             />
//                                             <Field
//                                                 as={TextField}
//                                                 label="Password"
//                                                 name="password"
//                                                 placeholder="Enter password"
//                                                 type="password"
//                                                 fullWidth
//                                                 required
//                                                 helperText={
//                                                     <ErrorMessage name="password" />
//                                                 }
//                                             />
//                                             <Field
//                                                 as={TextField}
//                                                 label="Confirm password"
//                                                 name="confirmpassword"
//                                                 placeholder="Confirm password"
//                                                 type="password"
//                                                 fullWidth
//                                                 required
//                                                 helperText={
//                                                     <ErrorMessage name="confirmpassword" />
//                                                 }
//                                             />
//                                             <Field
//                                                 as={FormControlLabel}
//                                                 name="remember"
//                                                 control={
//                                                     <Switch defaultChecked />
//                                                 }
//                                                 label="Remember me"
//                                             />
//                                             <Button
//                                                 type="submit"
//                                                 color="primary"
//                                                 variant="contained"
//                                                 disabled={isSubmitting}
//                                                 style={btnstyle}
//                                                 fullWidth
//                                             >

//                                                 {isSubmitting
//                                                     ? "Loading"
//                                                     : "Sign in"}
//                                             </Button>
//                                         </Form>
//                                         <Typography>
//                                             <Link href="/login">

//                                                 Already have an account ?
//                                             </Link>
//                                         </Typography>
//                                     </Grid>
//                                 </Grid>
//                             </Grid>
//                         </div>
//                     )}
//                 </Formik>
//         </div>
//     );
// };

// export { RegisterPage };

// // const classes = {
// //     root: {
// //         flexGrow: 1,
// //     },
// //     paper: {
// //         //padding: theme.spacing(2),
// //         textAlign: 'center',
// //         //color: theme.palette.text.secondary,
// //         width: '50%'
// //     },
// // };
// // // const paperStyle = { padding: 20, height: '73vh', width: 300, margin: "0 auto" }
// // const avatarStyle = { backgroundColor: '#1bbd7e' }

// // // const paper= {padding: 2,textAlign: 'center',color: palette.text.secondary,}
// // // const  root= {flexGrow: 1,}
// // const btnstyle = { margin: '8px 0' }
// // const initialValues = {
// //     username: '',
// //     email: '',
// //     password: '',
// //     confirmpassword: '',
// //     remember: false
// // }
// // return (
// //     <Formik
// //         initialValues={{
// //             username: '',
// //             email:'',
// //             password: '',
// //             confirmpassword: '',
// //             remember: false
// //         }}
// //         validationSchema={Yup.object().shape({
// //             email: Yup.string().email('please enter valid email').required("Required"),
// //             password: Yup.string().required("Required")
// //             // ,
// //             // confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
// //         })}
// //         onSubmit={({ username,email, password, confirmpassword }, { setStatus, setSubmitting }) => {
// //             setStatus();
// //             authenticationService.register(username,email, password, confirmpassword)
// //                 .then(
// //                     user => {
// //
// //                        // this.props.navigation.
// //                         //navigate(from, { replace: true });
// //                       //  const { from } = this.props.location.state || { from: { pathname: "/" } };
// //                         setSubmitting(false);
// //                        // this.props.history.push(from);
// //                     },
// //                     error => {
// //                         setSubmitting(false);
// //                         setStatus(error);
// //                     }
// //                 );
// //         }}
// //         render={({ errors, status, touched, isSubmitting }) => (
// //            <div className={classes.root} align='center' style={{ height: '86vh', width: '100%' }}>
// //                 <Grid container spacing={12} style={{ height: '100%', width: '60%', Padding: '0px!important' }}>

// //                     <Grid item xs={12} style={{ marginTop: '5%' }}>
// //                         {/* <Paper className={classes.paper} style={{width:'80%'}}> */}
// //                         <Grid style={{ width: '50%', PaddingTop: '10px' }} >
// //                             <Link to="/"  style={{  margin:'30px 0px 30px 0' }}>
// //                                         <Image src={image}  style={{ width: '15em' }} rounded></Image>
// //                                 {/* <h2>Diarytree</h2> */}
// //                             </Link>
// //                             <h5>Sign up to diarytree</h5>

// //                         </Grid>

// //                         <Grid>
// //                             {/* <GoogleLogin    /> */}

// //                             <AppleLogin
// //                                 clientId="YOUR_CLIENT_ID"
// //                                 redirectURI="YOUR_REDIRECT_URL"
// //                                 usePopup={true}
// //                                 callback={this.appleResponse} // Catch the response
// //                                 scope="email name" style={{marginTop: '16px'}}
// //                                 responseMode="query" backgroundColor="Red"
// //                                 render={renderProps => (  //Custom Apple Sign in Button
// //                                 <>
// //                                     <button
// //                                         onClick={renderProps.onClick}
// //                                         style={{
// //                                             backgroundColor: "white",
// //                                             padding: 10,
// //                                             border: "1px solid black",
// //                                             fontFamily: "none",
// //                                             lineHeight: "25px",
// //                                             fontSize: "25px"
// //                                         }}
// //                                     >
// //                                     {/* <Image   src={imageApple}  ></Image> */}
// //                                     <svg style={{height: '18px',marginright: '12px', width: '18px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10.16 -2.38419e-07C7.8 0.56 6.9 2.1 6.86 4.14C7.76 4.06 8.27 3.96 8.99 3.23C9.84 2.37 10.22 1.26 10.22 0.43C10.22 0.219999 10.2 0.129999 10.16 -2.38419e-07ZM10.33 3.84C9.86 3.84 9.28 3.95 8.59 4.19C7.94 4.42 7.46 4.54 7.16 4.54C6.92 4.54 6.46 4.43 5.77 4.23C5.07 4.03 4.47 3.92 3.99 3.92C1.69 3.92 1.19209e-07 6.11 1.19209e-07 9.22C1.19209e-07 10.86 0.49 12.58 1.48 14.35C2.47 16.12 3.49 17 4.5 17C4.84 17 5.29 16.89 5.83 16.65C6.36 16.41 6.84 16.32 7.26 16.32C7.69 16.32 8.2 16.43 8.77 16.64C9.39 16.87 9.85 17 10.18 17C11.9 17 13.48 14.12 14 12.41C12.8 12.05 11.72 10.53 11.72 8.85C11.72 7.3 12.46 6.44 13.52 5.53C12.82 4.66 11.93 3.84 10.33 3.84Z" fill="#1D1C1D"></path></svg>
// //                                         <i className="fa-brands fa-apple px-2 "></i>
// //                                         Continue with Apple
// //                                     </button>
// //                                     </>
// //                                 )}
// //                             />

// //                             {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage}  render={renderProps => (  //Custom Apple Sign in Button
// //                                 <>
// //                                     <button
// //                                         onClick={renderProps.onClick}
// //                                         style={{
// //                                             backgroundColor: "white",
// //                                             padding: 10,
// //                                             border: "1px solid black",
// //                                             fontFamily: "none",
// //                                             lineHeight: "25px",
// //                                             fontSize: "25px"
// //                                         }}
// //                                     >
// //                                     <Image   src={imageGoogle}  ></Image>
// //                                         <i className="fa-brands fa-apple px-2 "></i>
// //                                         Continue with Google
// //                                     </button>
// //                                     </>
// //                                 )} /> */}

// //                         </Grid>

// //                         <hr />

// //                 <Grid item style={{ width: '50%' }}>
// //                     <Form>
// //                         <Field as={TextField} label='Username' name="username"
// //                             placeholder='Enter username' fullWidth required
// //                             helperText={<ErrorMessage name="username" />}
// //                         />
// //                                 <Field align='center' as={TextField} label='Email' name="email"
// //                                     placeholder='Enter your email address ..' fullWidth required
// //                                     helperText={<ErrorMessage name="email" />}
// //                                 />

// //                         <Field as={TextField} label='Password' name="password"
// //                             placeholder='Enter password' type='password' fullWidth required
// //                             helperText={<ErrorMessage name="password" />} />
// //                         <Field as={TextField} label='Confirm password' name="confirmpassword"
// //                             placeholder='Confirm password' type='password' fullWidth required
// //                             helperText={<ErrorMessage name="confirmpassword" />} />
// //                         <Field as={FormControlLabel}
// //                             name='remember'
// //                             control={
// //                                 <Switch defaultChecked />
// //                             }
// //                             label="Remember me"
// //                         />
// //                         <Button type='submit' color='primary' variant="contained" disabled={isSubmitting}
// //                             style={btnstyle} fullWidth>{isSubmitting ? "Loading" : "Sign in"}</Button>

// //                     </Form>
// //                     <Typography >
// //                         <Link href="/login" > Already have an account ? </Link>
// //                     </Typography>
// //                     </Grid>
// //                 </Grid>
// //                 </Grid>
// //             </div>
// //         )}
// //     />
// // )
