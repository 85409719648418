import React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import ImageIcon from "@mui/icons-material/Image";
import { CheckSquareOutlined } from "@ant-design/icons";
import { AiOutlineCheck } from "react-icons/ai";

const CalendarSmallBlock = (props) => {
  return (
    <ListItem
      style={{ cursor: "pointer", padding: "8px" }}
      onClick={() => {
        props.handleClick(props.data.id);
      }}
    >
      <ListItemAvatar
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar
          style={{
            backgroundColor: props.data.color,
            filter: props.data.selected ? "grayscale(0%)" : "grayscale(100%)",
            textAlign: "center",
            border: "3px solid #ece8e8",
          }}
        >
          {/* <ImageIcon  src={image} style={{ backgroundColor: props.data.color }} />                */}

          <div
            style={{ alignItems: "end" }}
            className={`gx-d-flex gx-align-items-center gx-justify-content-center  gx-rounded-sm ${
              props.data.selected ? "is_select" : "is_select_none"
            }`}
          >
            <AiOutlineCheck style={{ fontSize: 15, color: "#fff" }} />
          </div>
        </Avatar>
        <ListItemText
          primary={props.data.name}
          secondary={props.data.description}
          className="content-small-block"
        />
      </ListItemAvatar>
      {/* <ListItemText primary={props.data.name} secondary={props.data.description} /> */}
    </ListItem>

    // <div className={'gx-w-100 calendar_small_block'}>
    //     {/* <div className={'block_logo'} onClick={() => { props.handleClick(props.data.id) }}>
    //         <div className={'gx-w-100 gx-h-100 gx-rounded-sm text'} style={{ backgroundColor: props.data.color }} >
    //             <span>{props.data.name}</span>
    //         </div>
    //         <div className={`gx-w-100 gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center is_select gx-rounded-sm ${props.data.selected ? '' : 'gx-d-none'}`}>
    //             <Check style={{ fontSize: 30, color: '#fff' }} />
    //         </div>
    //     </div> */}
    //     <div className={'block_logo'} onClick={() => { props.handleClick(props.data.id) }}>
    //             <div className={'gx-w-100 gx-rounded-sm text'} style={{ backgroundColor: props.data.color,height:'100%' }} >
    //                     <span>{props.data.name}</span>
    //                 </div>
    //                 <div className={`gx-d-flex gx-align-items-center gx-justify-content-center  gx-rounded-sm ${props.data.selected ? 'is_select_sm' : 'is_select_none'}`}>
    //                     <AiOutlineCheck style={{ fontSize: 30, color: '#fff' }} />
    //                 </div>
    //      </div>
    // </div>
  );
};

export default CalendarSmallBlock;
