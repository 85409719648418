import { getData, postData } from '../api';
// import { authenticationService } from '../authentication.service';
import {
  REQUEST_CALENDARS_PENDING,
  REQUEST_CALENDARS_SUCCESS,
  REQUEST_CALENDARS_FAILED,
  RECEIVE_CALENDAR,
  SET_CALENDAR,
  ADD_CALENDAR,
  UPDATE_CALENDAR,
  DELETE_CALENDAR
} from './actionTypes';
import appConfig from '../../envConfig/config';

export const setCalendar = (calendar) => ({ type: SET_CALENDAR, payload: calendar })

export const requestCalendars = (dispatch) => {
  dispatch({ type: REQUEST_CALENDARS_PENDING })
  postData(`${appConfig.api_url}/CalendarApi/Calendars`)
  
  .then(data => dispatch({ type: REQUEST_CALENDARS_SUCCESS, payload: data }))
  .catch(error => dispatch({ type: REQUEST_CALENDARS_FAILED, payload: error }))
}

export function receiveCalendar(        
  color = "",
  bgcolor = "",
  name = "",
  id = null,
  selected = false
) {
  return {
    type: RECEIVE_CALENDAR,
    payload: {
      Calendar: {
        id,
        name,
        color,
        bgcolor,
        selected
      }
    }
  }
}


export const addCalendar = (calendar) => ({
  type: ADD_CALENDAR,
  payload: calendar
});

export const updateCalendar = (calendar) => ({
  type: UPDATE_CALENDAR,
  payload: calendar
});

export const deleteCalendar = (calendarId) => ({
  type: DELETE_CALENDAR,
  payload: calendarId
});

// export const updateCalendar = (calendar) => {
//   return apiTasks.post('UpdateCalendar',calendar)
//     .then(function (response) {
//       
//                 if(!response.isAxiosError)
//                 {
//                     return response;
//                 }
//           })
// } 

// export const deleteCalendar = (calendarid) => {
//   return apiTasks.post('DeleteSchedule',calendarid)
//     .then(function (response) {
//                 if(!response.isAxiosError)
//                 {
//                     return response;
//                 }
//           })
// } 
