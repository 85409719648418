import { useState, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useMatch,useNavigate, useResolvedPath,useLocation  } from "react-router-dom";
import image from "../../assets/images/logo_transparent.png"; // Tell webpack this JS file uses this image
import { MessageOutlined, BellOutlined } from "@ant-design/icons";
import { authenticationService } from "../../app/services";
import ProfileSubMenu from "./ProfileSubMenu";
import Image from "react-bootstrap/Image";
import NotificationSubMenu from "./NotificationSubMenu";
import SignalRService from "../../app/services/signalRService";
import "./navbar.css";

 const Navbar = (props) => {
   const [notifications, setNotifications] = useState([]);
   const location = useLocation();
//     {
//       id: 1,
//       title: "New Message",
//       message: "You have a new message from John.",
//     },
//     {
//       id: 2,
//       title: "Friend Request",
//       message: "You received a friend request from Alice.",
//     },
//     // Add more notifications as needed
//   ]);

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if(authenticationService.currentUserToken!= null)
    {
    const signalRService = new SignalRService();
    console.log("navbar use effect signalr");

    signalRService
      .startConnection()
      .then(() => {
        console.log("SignalR connection established.");
        // Subscribe to events
        signalRService.onNotificationReceived(handleNotificationReceived);
      })
      .catch((error) => {
        console.error("Error establishing SignalR connection:", error);
      });

    return () => {
      // Unsubscribe from events and stop the connection
      signalRService.off("NotificationReceived", handleNotificationReceived);
      signalRService.stopConnection();
    };
      
  }
  }, []);

  const handleNotificationReceived = (notification) => {
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      notification,
    ]);
    setUnreadCount((prevUnreadCount) => prevUnreadCount + 1);
  };

  // Function to delete a notification
  const deleteNotification = (notificationToDelete) => {
    const updatedNotifications = notifications.filter(notification => notification.id !== notificationToDelete.id);
    setNotifications(updatedNotifications);
  };


  return (
    <nav
      className="nav"
      style={{
        alignItems: "center",
        height: "80px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        backgroundColor: props.backgroundColor,
        display: "flex",
        flexDirection: "row", // Horizontal layout
        justifyContent: "space-between", // Align logo and menu button on opposite sides
        padding: "0 1em", // Add padding for spacing
      }}
    >
      <Link to="/" className="site-title">
        <Image src={image} style={{ width: "2em" }} alt="Logo" />
        <span style={{ marginLeft: '0.5em', fontSize: '1em', color: '#335c6e',  fontWeight: 'bold',fontStyle:'unset' }}>diarytree</span>
      </Link>

      <ul className={`menu-nav ${menuOpen ? "open" : "full-menu"}`}>
        {authenticationService.currentUserToken == null && (
          <CustomLink
            to={{
              pathname: "/login",
              state: { from: location.pathname },
            }}
            className={`nav-item nav-link ${!menuOpen ? "rounded-button" : ""}`}
          >
            Login
          </CustomLink>
        )}
        {authenticationService.currentUserToken == null && (
          <CustomLink
            to="/professionalcreation"
            className={`nav-item nav-link ${
              !menuOpen ? "rounded-button filled-button" : ""
            }`}
          >
            Pro
          </CustomLink>
        )}
        {authenticationService.currentUserToken != null && (
          <CustomLink
            to="/"
            className="nav-item nav-link top-nav-link"
          >
            Home
          </CustomLink>
        )}
        {authenticationService.currentUserToken != null && (
          <CustomLink to="/schedule" className="nav-item nav-link top-nav-link">
            Schedule
          </CustomLink>
        )}
        {authenticationService.currentUserToken != null && (
          <CustomLink to="/admin" className="nav-item nav-link top-nav-link">
            Admin
          </CustomLink>
        )}{" "}
        {/* //&& isAdmin */}
        {authenticationService.currentUserToken != null && (
          <NotificationSubMenu notifications={notifications}  deleteNotification={deleteNotification} />
        )}
        {authenticationService.currentUserToken != null && (
          <ProfileSubMenu appendClass="desktop" />
        )}
      </ul>

      {/* Add more menu items for the full menu here */}
      <div className="nav-right-side-mobile" style={{backgroundColor:'red'}}>
        {authenticationService.currentUserToken != null && (
          <ProfileSubMenu appendClass="mobile" />
        )}
        <button
          className={`menu-button ${menuOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          {/* Use an icon like the hamburger menu icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#664de4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>
    </nav>
  );
};


function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(state => state.user); // Adjust based on your state shape

  const handleClick = (e) => {
    debugger;
    if (!user.authenticated) {
      e.preventDefault();
      navigate(to.pathname, { state: { from: location.pathname } });
    }
  };

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props} onClick={handleClick}>
        {children}
      </Link>
    </li>
  );
}


// function CustomLink({ to, children, ...props }) {
//   const resolvedPath = useResolvedPath(to);
//   const isActive = useMatch({ path: resolvedPath.pathname, end: true });
//   debugger;

//   return (
//     <li className={isActive ? "active" : ""}>
//       <Link to={to} {...props}>
//         {children}
//       </Link>
//     </li>
//   );
// }

export default Navbar;
