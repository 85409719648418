import React from "react";
import { Typography } from "antd";
import { Button } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;

const AddCalendarButton = (props) => {
  return (
    <div
      className={
        "gx-w-100 calendar_block gx-d-flex add_button_sector left-side-action-btn "
      }
      onClick={props?.openAddCalendarForm}
    >
      {/* <div className={'gx-w-25 gx-mw-90'}>
                <div className={'add_button'}>
                    <Button style={{ fontSize: 40, color: 'grey' }} />

                </div>,display:'inline-flex'style={{alignItems='center'}}
            </div>
            <div span={18} className={'gx-d-flex gx-align-items-center'}> */}

      <PlusOutlined />
      {/* <Title className={'gx-w-100 gx-m-0'} level={4}>Add Calendar</Title> */}
      {/* </div> */}
    </div>
  );
};

export default AddCalendarButton;
