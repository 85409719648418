import React, { useEffect, useState } from 'react';
import { useParams, useHistory,useNavigate } from 'react-router-dom';
import { authenticationService } from "../../app/services";

const EmailConfirmationPage = () => {
   const { userId, token } = useParams();
   const navigate = useNavigate();
   const [confirmationStatus, setConfirmationStatus] = useState('');
  // const history = useHistory();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        await authenticationService
        .confirmEmail(userId,token) // assuming username holds the email value
        .then(
          (user) => {
            setConfirmationStatus("Your email address is successfully confirmed.");
            navigate("/schedule");

          },
          (error) => {
            setErrorMessage(error.message);
          }
        );
      } catch (error) {
        alert('There was an error sending the password reset email.');
      }

      //   const response = await axios.post(`/confirm-email/${userId}/${token}`);
      //   setConfirmationStatus(response.data.message);
      //   // Redirect to signin page after successful confirmation
      //   if (response.data.success) {
      //     setTimeout(() => {
      //       history.push('/signin');
      //     }, 3000); // Redirect after 3 seconds
      //   }
      // } catch (error) {
      //   console.error('Error confirming email:', error);
      //   setConfirmationStatus('An error occurred while confirming your email.');
      // }
    };

    confirmEmail();
  }, [userId, token]);

  return (
    <div>
      <h2>Email Confirmation</h2>
      <p>{confirmationStatus}</p>
    </div>
  );
};

export { EmailConfirmationPage };
