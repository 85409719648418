import {
  REQUEST_SCHEDULES_PENDING,
  REQUEST_SCHEDULES_SUCCESS,
  REQUEST_SCHEDULES_FAILED,
  RECEIVE_SCHEDULE,
  ADD_SCHEDULE,
  UPDATE_SCHEDULE,
  DELETE_SCHEDULE
} from "../actions/actionTypes";

const initialState = {
  schedules: [],
  currentCalendarId: null
};

const scheduleReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_SCHEDULES_PENDING:
      return {
        ...state,
        isPending: true
      };
    case REQUEST_SCHEDULES_SUCCESS:
      return {
        ...state,
        schedules: action.payload,
        isPending: false
      };
    case REQUEST_SCHEDULES_FAILED:
      return {
        ...state,
        error: action.payload
      };
    case RECEIVE_SCHEDULE:
      if (action.payload.currentCalendarId === action.payload.schedule.calendarId) {
        return {
          ...state,
          schedules: [...state.schedules, action.payload.schedule]
        };
      } else {
        return state;
      }
    case ADD_SCHEDULE:
      return {
        ...state,
        schedules: [...state.schedules, action.payload]
      };
    case UPDATE_SCHEDULE:
      
      var newSchedule = action.payload;
      var oldSchedule = state.schedules.map((schedule) =>
      schedule.id === action.payload.id ? action.payload : schedule
    );


      return {
        ...state,
        schedules: oldSchedule
      };
    case DELETE_SCHEDULE:
      return {
        ...state,
        schedules: state.schedules.filter((schedule) => schedule.id !== action.payload)
      };
    default:
      return state;
  }
};

export default scheduleReducer;
