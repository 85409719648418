import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    token: localStorage.getItem("token"),
    role:"toto",
    bearerToken: null,
    isTokenValid: false,
    authenticated: false,
  },
  reducers: {
    setToken: (state, action) => {
      try {
        //
        const decodedToken = jwt_decode(action.payload);
        const expiration = decodedToken.exp * 1000;
        const isTokenValid = expiration > Date.now();
        if (isTokenValid) {
          localStorage.setItem("token", action.payload);
          return {
            ...state,
            user: decodedToken,
            role:"toto",
            authenticated: true,
            token: action.payload,
            bearerToken: `Bearer ${action.payload}`,
            isTokenValid,
          };
        } else {
          localStorage.removeItem("token");
          return {
            ...state,
            token: null,
            bearerToken: null,
            isTokenValid: false,
          };
        }
      } catch (err) {
        console.log(err);
        localStorage.removeItem("token");
        return {
          ...state,
          token: null,
          bearerToken: null,
          isTokenValid: false,
        };
      }
    },
    checkToken: (state) => {
      if (state.token && state.token != "undefined") {
        try {
          const decodedToken = jwt_decode(state.token);
          const expiration = decodedToken.exp * 1000;
          const isTokenValid = expiration > Date.now();
          if (isTokenValid) {
            return {
              ...state,
              user: decodedToken,
              bearerToken: `Bearer ${state.token}`,
              authenticated: true,
              isTokenValid,
            };
          } else {
            localStorage.removeItem("token");
            return {
              ...state,
              token: null,
              bearerToken: null,
              isTokenValid: false,
              authenticated: false,
            };
          }
        } catch (err) {
          console.log(err);
          localStorage.removeItem("token");
          return {
            ...state,
            token: null,
            bearerToken: null,
            isTokenValid: false,
            authenticated: false,
          };
        }
      } else {
        return {
          ...state,
          token: null,
          bearerToken: null,
          isTokenValid: false,
        };
      }
    },
    fetchToken: (state, action) => {
      action.payload.method
        .then((response) => {
          if (response.status === 200 && response.data) {
            return { ...state, token: response.data };
          } else {
            throw new Error("Error on fetching token");
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return state;
    },
  },
});

export const selectUser = (state) => state.user.user;
export const { setToken, checkToken, fetchToken } = userSlice.actions;
export default userSlice.reducer;