import React from "react";
// import Navbar from 'react-bootstrap/Navbar';
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import {
  Router,
  Route,
  Link,
  BrowserRouter,
  Routes,
  Switch,
  Outlet,
} from "react-router-dom";
import { authenticationService } from "../../app/services/authentication.service";
import Footer from "./Footer";
import Header from "./Header";
import { useNavigate } from "react-router-dom";

class SharedLayout extends React.Component {
  constructor(props) {
    super(props);

    //   const navigate = useNavigate();
    this.state = {
      currentUser: null,
      isAdmin: false,
    };
  }

  componentDidMount = () => {
    // this.connection
    //     .start({ withCredentials: false })
    //     .catch(err => console.error(err));
    // authenticationService.currentUser.subscribe(x => this.setState({
    //      currentUser: x//,
    //     // isAdmin: x && x.role === Role.Admin
    // }));
  };

  logout() {
    authenticationService.logout();

    //history.push('/login');
  }
  render() {
    const { currentUser, isAdmin } = this.state;

    return (
      <>
        <Header currentUser={currentUser} />
        <Outlet />
      </>
    );
  }
}

export { SharedLayout };
