import React, { useState } from 'react';
 import { Box } from '@mui/material';
//  import Footer from '../SharedLayout/Footer';
 import { Footer, Blog, Possibility, Features, WhatDTree, Header, ZigZag, Subscriptions ,ContactForm } from './containers';

// import Exercises from '../components/Exercises';
// import SearchExercises from '../components/SearchExercises';
// import HeroBanner from '../components/HeroBanner';
const data={apple:1,mango:2,banana:3};

const Home = () => {

  return (

    <div className="App">
    {/* <div className="gradient__bg">
      <Navbar /> 
      <Header />
    </div> */}
    {/* <Brand /> */}
    <Header />

    <br/>
    <Subscriptions  />
    <br/>
    {/* <WhatDTree /> */}
    <ZigZag />
    <br/>
  
    <br/>
    <ContactForm/>
    {/* <Features />
    <Possibility /> */}
    {/* <CTA /> */}
    {/* <Blog /> */}
    <Footer />
  </div>

    // <>
    // <Box  className="container">
    //   This is the home page
    // </Box>
    
    // <Footer /> 
    // </>
  );
};

export default Home;



    
      {/* <HeroBanner /> */}
      {/* <SearchExercises setExercises={setExercises} bodyPart={bodyPart} setBodyPart={setBodyPart} />
      <Exercises setExercises={setExercises} exercises={exercises} bodyPart={bodyPart} /> */}
