import { useState } from "react";
import AddCalendarButton from "./AddCalendarButton";
import CalendarSmallBlock from "./CalendarSmallBlock";
import { ExpandMoreOutlined } from "@mui/icons-material";

function MobileSidebar({ openAddCalendarForm, calendars, clickCalendar }) {
  const [visible, setVisible] = useState(false);
  return (
    <div className="mobile-sidebar-wrapper">
      <span
        onClick={() => setVisible(!visible)}
        style={{ fontWeight: 600, cursor: "pointer" }}
      >
        Calendars
        <ExpandMoreOutlined
          style={{
            fontSize: "20px",
            transition: "all 300ms ease-in-out",
            transform: visible ? "rotate(180deg)" : "rotate(0)",
          }}
        />
      </span>
      {visible && (
        <div className={"gx-w-100 gx-h-100 gx-d-flex calendar-cats-mobile"}>
          <div className={"gx-w-100"}>
            <AddCalendarButton
              openAddCalendarForm={() => {
                openAddCalendarForm("new");
              }}
            />
            {calendars &&
              calendars.map((calendar) => (
                <CalendarSmallBlock
                  data={calendar}
                  key={calendar.id}
                  handleClick={clickCalendar}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default MobileSidebar;
