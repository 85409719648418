import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, TimePicker,Typography,Divider, Space, Table, Popconfirm, message, Row,Col } from 'antd';
import { companyService, authenticationService } from "../../app/services";
import moment from 'moment';
import { Hidden } from '@mui/material';

const { Title } = Typography;
const { RangePicker } = TimePicker;


const PreferencesPage = (props) => {
  const [form] = Form.useForm();
  const [employees, setEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [userAvailability, setUserAvailability] = useState(null);
  const [editingRecord, setEditingRecord] = useState(null);

  const onFinish = async (values) => {
    try {
      // Convert the selected time range to a format suitable for your backend
      const workingHours = values.workingHours.map((time) => time.format('HH:mm'));

      // Make an API call to save the working hours to the database
      await companyService.saveWorkingHours(props.company,workingHours.join('-'));

      message.success('Working hours saved successfully!');
      form.resetFields();
    } catch (error) {
      console.error('Error saving working hours:', error);
      message.error('Failed to save working hours. Please try again.');
    }
  };

  return (
    <>
      <Title level={3}>Company Preferences</Title>

      {/* Working Hours Form */}
      <Divider>Working Hours</Divider>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="workingHours"
          label="Select Working Hours"
          rules={[{ required: true, message: 'Please select working hours!' }]}
        >
          <RangePicker format="HH:mm" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save Working Hours
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default PreferencesPage;