import { authenticationService } from './authentication.service';

// Get Calls 
export const getData = (url) => 
  fetch(url,
    {
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        "Content-Type": "application/json; charset=utf-8", 'Authorization': 'Bearer ' + authenticationService.currentUserToken      }
    })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      }
      return  response.json();
    })
    .catch(error => Promise.reject(error));

//Post calls
export const postData = (url = '', data = {}) => {
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      "Content-Type": "application/json; charset=utf-8", 'Authorization': 'Bearer ' + authenticationService.currentUserToken    },
    body: JSON.stringify(data)
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      }
      return  response.json();
    })
    .catch(error => Promise.reject(error));
};