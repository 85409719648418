import React, { useState, useEffect } from "react";
import {
  Layout,
  Typography,
  Tooltip,
  Menu,
  Button,
  List,
  Form,
  Input,
  Space,
  Divider,
  Avatar,
} from "antd";
import {
  UserOutlined,
  PushpinOutlined,
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  SettingOutlined
} from "@ant-design/icons";
import { authenticationService, companyService } from "../../app/services";
import {
  EmailRounded,
  HomeWorkRounded,
  LocalPhoneRounded,
  PersonRounded,
  SortRounded,
} from "@mui/icons-material";
import useWindowResizeListener from "../../helpers/useWindowResizeListener";

// Images
import avatar from "../../assets/images/avatar.png";
import CompanySettings from './CompanySettings'; 
import PreferencesPage from "./PreferencesPage";


const { Content, Sider } = Layout;
const { Title } = Typography;

const UserProfilePage = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [profile, setProfile] = useState({});
  const [updated, setUpdated] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const [companyRequests, setCompanyRequests] = useState([]);
  const [activeMenu, setActiveMenu] = useState("profile");
  const [form] = Form.useForm();
  const { width } = useWindowResizeListener();
  const handleUpdate = () => {
    // Update data in backend
    setUpdated(true);
  };
  useEffect(() => {
    width < 768 ? setCollapsed(true) : setCollapsed(false);
  }, [width]);

  useEffect(() => {
    // Fetch user profile
    //authenticationService.getUserProfile()
    authenticationService
      .getUserByToken()

      .then((profileData) => {
        setProfile(profileData);
        form.setFieldsValue(profileData);
        // After profile is fetched set updated to false
        setUpdated(false);
      })
      .catch((error) => {
        console.error("Failed to fetch user profile:", error);
      });

    // // Fetch subscribers
    // authenticationService
    //   .getUserSubscriptions()
    //   .then((subscriberData) => {
    //     setSubscribers(subscriberData);
    //   })
    //   .catch((error) => {
    //     console.error("Failed to fetch subscribers:", error);
    //   });

    // 
    // Fetch company requests for users with COMPANYREQUESTVALIDATOR role
    if (
      profile?.roles?.some((role) => role.name === "COMPANYREQUESTVALIDATOR")
    ) {
      // companyService
      //   .getPendingRequests()
      //   .then((requestsData) => {
      //     setCompanyRequests(requestsData);
      //   })
      //   .catch((error) => {
      //     console.error("Failed to fetch company requests:", error);
      //   });
    }
  }, [updated]); // <=== issue here. Re-rendering infinitely if "profile" dependency.

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const handleProfileFormSubmit = (values) => {
    // Update user profile
    authenticationService
      .updateUserProfile(values)
      .then((updatedProfile) => {
        setProfile(updatedProfile);
        form.setFieldsValue(updatedProfile);
      })
      .catch((error) => {
        console.error("Failed to update user profile:", error);
      });
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsed={collapsed}
        onCollapse={toggleSidebar}
        width={200}
        collapsedWidth={80}
        className="user-profile-sider"
      >
        <Menu
          mode="inline"
          selectedKeys={[activeMenu]}
          onClick={({ key }) => handleMenuClick(key)}
          theme="dark"
        >
          <Menu.Item key="profile" icon={<UserOutlined />}>
            Profile
          </Menu.Item>
          {profile?.roles?.some(
            (role) => role.name === "COMPANYREQUESTVALIDATOR"
          ) && (
            <Menu.Item key="company-requests" icon={<PushpinOutlined />}>
              Company Requests
            </Menu.Item>
          )}
          {profile?.type === "Pro" && (
          <>
            <Menu.Item key="company-preferences" icon={<EnvironmentOutlined />}>
              Preferences
            </Menu.Item>
            <Menu.Item key="company-settings" icon={<SettingOutlined />}>
              Settings
            </Menu.Item>
            </>
          )}
        </Menu>
      </Sider>
      <Layout>
        <Content
          style={{ padding: "24px", background: "#f5f7fd" }}
          className="user-details-profile"
        >
          {activeMenu === "profile" && (
            <>
              <Title level={3}>Profile Parameters</Title>
              <hr style={{ color: "#9e9e9e" }} />
              <div className="update-image-wrapper">
                <img
                  className="update-avatar-img"
                  src={avatar}
                  alt="diarytree profile image"
                />
                <Button
                  className="change-image-btn submit-btn"
                  htmlType="submit"
                  variant="contained"
                >
                  Change Image
                </Button>
              </div>

              <Form
                form={form}
                layout="vertical"
                onFinish={handleProfileFormSubmit}
                initialValues={profile}
              >
                <Form.Item
                  name="username"
                  label="username"
                  rules={[
                    { required: true, message: "Please enter your username" },
                  ]}
                >
                  <Input
                    className="profile-input-text"
                    prefix={<PersonRounded />}
                  />
                </Form.Item>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, message: "Please enter your name" },
                  ]}
                >
                  <Input
                    className="profile-input-text"
                    prefix={<SortRounded />}
                  />
                </Form.Item>
                <Form.Item
                  name="address"
                  label="Address"
                  rules={[
                    { required: true, message: "Please enter your address" },
                  ]}
                >
                  <Input
                    className="profile-input-text"
                    prefix={<HomeWorkRounded />}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email Address"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email address",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input
                    className="profile-input-text"
                    prefix={<EmailRounded />}
                  />
                </Form.Item>
                <Form.Item
                  name="phonenumber"
                  label="Phone number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your phone number",
                    },
                  ]}
                >
                  <Input
                    className="profile-input-text"
                    prefix={<LocalPhoneRounded />}
                  />
                </Form.Item>
                <Form.Item>
                  <Space className="form-buttons-wrapper">
                    <Button
                      className="submit-btn"
                      htmlType="submit"
                      variant="contained"
                      onClick={handleUpdate}
                    >
                      Update Profile
                    </Button>
                    <Button
                      onClick={() => form.resetFields()}
                      className="reset-btn"
                    >
                      Reset
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </>
          )}
          {activeMenu === "company-requests" &&
            profile.role === "COMPANYREQUESTVALIDATOR" && (
              <>
                <Title level={3}>Pending Company Requests</Title>
                <List
                  dataSource={companyRequests}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={item.companyName}
                        description={item.requestDate}
                      />
                      <Button
                        type="primary"
                        onClick={() => handleApproveRequest(item.id)}
                      >
                        Approve
                      </Button>
                      <Button onClick={() => handleRejectRequest(item.id)}>
                        Reject
                      </Button>
                    </List.Item>
                  )}
                />
              </>
            )}
          {activeMenu === "company-preferences"  && profile.type === "Pro" && (
              <PreferencesPage  type="Pro" company= {profile?.username} />
            )}
          {activeMenu === "company-settings" && profile.type === "Pro" && (
              <CompanySettings  type="Pro" company= {profile?.username} />
            )}

        </Content>
      </Layout>
    </Layout>
  );
};

export { UserProfilePage };