import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Badge, TextField, Button, Select, MenuItem,  ListItem, ListItemText,ListItemAvatar,Avatar , Grid, Box, styled, Paper, Divider,
  Card, CardContent, CardActionArea, CardMedia} from "@mui/material";
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import timezone from 'dayjs/plugin/timezone';
import moment from "moment";

import { Row, Col, Modal, Typography } from "antd";
import { DateTimePicker } from "@mui/x-date-pickers";
// import AdapterDateFns from '@mui/lab/AdapterDateFns'; 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, PickersDay, DateCalendar } from '@mui/x-date-pickers';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
// import   AdapterDateFns from '@mui/x-date-pickers/AdapterDateFns';


// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { CloseOutlined, DeleteFilled } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { companyService } from "../../../app/services";
import MapPicker from "../../MapPicker";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';

import { Scheduler } from "./Scheduler";
import { DataObjectSharp, DriveFileRenameOutlineOutlined, SortOutlined, } from "@mui/icons-material";
import TextArea from "antd/es/input/TextArea";

import ServiceCard from './ServiceCard';
import TimeSlotList from './TimeSlotList';
import ServiceSelector from './ServiceSelector' ;
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const AppointmentForm = forwardRef(({
  visible,newForm,formdata,setFormData,closeModal,handleSubmitForm,handleDeleteSubmit,onFinish,
},ref) => {

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      id: formdata?.id || "",
      companyid: formdata?.companyid || "",
      companyemployeeid: formdata?.companyemployeeid || "",
      title: formdata?.title || "",
      startDate: formdata?.startDate || null,
      endDate: formdata?.endDate || null,
      calendarid: formdata?.calendarid || "1",
      description: formdata?.description || "",
    },
    // resolver: yupResolver(schema),
  });

  useImperativeHandle(ref, () => ({
    resetForm: () => reset()
  }));
  const initialValue = new Date();

  const [selectedProfessionalType, setSelectedProfessionalType] = useState({});
  const [scheduleView, setScheduleView] = useState("week");
  const [currentAddress, setCurrentAddress] = useState("");

  const [professionalTypes, setProfessionalTypes] = useState();


  const [professionals, setProfessionals] = useState([]);
  const [showProfessionalsList, setShowProfessionalsList] = useState(false);
  const [showProfessionalSchedule, setShowProfessionalSchedule] = useState(false);

  const [selectedDate, setSelectedDate] = useState(initialValue);
  const [calendarDate, setCalendarDate] = useState(new Date());

  const [availabilities, setAvailabilities] = useState([]);

  const [selectedProfessional, setSelectedProfessional] = useState("");

  const [employees, setEmployees] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [totalDuration, setTotalDuration] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0.0);

  const [currentlatitude, setCurrentLatitude] = useState(0);
  const [currentlongitude, setCurrentLongitude] = useState(0);

  // State to keep track of the number of days to fetch
  const [daysToFetch, setDaysToFetch] = useState(10);
  const [currentShownEndDate, setCurrentShownEndDate] = useState(new Date());  
  const [selectedSlot, setSelectedSlot] = useState(null); // Add state for selected slot
  const [timeSlots, setTimeSlots] = useState([]);




  // const initialValue = dayjs('2022-04-17');

  const { t } = useTranslation();

  ///////////////// DateCalendar
  function ServerDay(props) {
    
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
    // && highlightedDays.indexOf(props.day.date()) >= 0
    const isSelected = !props.outsideCurrentMonth;

    return (
      <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? '🌚' : undefined}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}

    >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
                // fontSize: '5px',
                // height: '10px',
                // width: '5px',
                // borderRadius: '50%',
    );
  }      
  // sx={{
  //   '& .MuiBadge-badge': {
  //     fontSize: '0.5px', width: '0.025px',
  //     backgroundColor: isSelected ? '#1E90FF' : 'transparent', // Blue color
  //     color: isSelected ? '#1E90FF' : 'transparent', // Match the background color to hide the text
  //   },
  // }}

  const requestAbortController = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15]);

  const fetchHighlightedDays = (date) => {

    console.log("boo");
    setIsLoading(false);
    // const controller = new AbortController();
    // fakeFetch(date, {
    //   signal: controller.signal,
    // })
    //   .then(({ daysToHighlight }) => {
    //     setHighlightedDays(daysToHighlight);
    //     setIsLoading(false);
    //   })
    //   .catch((error) => {
    //     // ignore the error if it's caused by `controller.abort`
    //     if (error.name !== 'AbortError') {
    //       throw error;
    //     }
    //   });

    // requestAbortController.current = controller;
  };

  React.useEffect(() => {
    fetchHighlightedDays(initialValue);
    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, []);

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  ///////////////// end DateCalendar


  useEffect(() => {
    // Check if browser supports geolocation
    if (navigator.geolocation) {
      // Get current position using navigator's geolocation
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLatitude(position.coords.latitude);
          setCurrentLongitude(position.coords.longitude);
          setCurrentAddress(position.coords.address);
        },
        (error) => {
          fetch("https://ipapi.co/json/")
            .then((response) => response.json())
            .then((data) => {
              setCurrentLatitude(data.latitude);
              setCurrentLongitude(data.longitude);
              setCurrentAddress(data.address);
            })
            .catch((error) => {
              // setError(`Error retrieving location: ${error.message}`);
              // setIsGoogleApiLoaded(true);
            });
          // setError(`Error retrieving location: ${error.message}`);
          // setIsGoogleApiLoaded(true);
        }
      );
    } else {
      // If geolocation is not supported, get location based on IP address
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((data) => {
          setCurrentLatitude(data.latitude);
          setCurrentLongitude(data.longitude);
          setCurrentAddress(data.address);
          // setIsGoogleApiLoaded(true);
        })
        .catch((error) => {
          // setError(`Error retrieving location: ${error.message}`);
          // setIsGoogleApiLoaded(true);
        });
    }
    // autocomplete = new google.maps.places.Autocomplete((this.refs.autoCompletePlaces), {types: ['geocode']});
  }, []);

  useEffect(() => {
    companyService
      .getAllCategories()
      .then((categories) => {
        setProfessionalTypes(categories);
      })
      .catch((error) => {
        console.error("Failed to fetch professional types:", error);
      });
  }, []);

  useEffect(() => {
    if (formdata) {
      setValue("id", formdata.id || "");
      setValue("title", formdata.title || "");
      setValue("startDate", formdata.startDate || null);
      setValue("endDate", formdata.endDate || null);
      setValue("calendarid", formdata.calendarid || "1");
      setValue("description", formdata.description || "");
      setValue("companyid", formdata.companyid || "");
      setValue("companyemployeeid", formdata.companyemployeeid || "");
    }
  }, [formdata, setValue]);


  useEffect(() => {
    if (selectedProfessional) {
      // Fetch employees for the selected professional
      companyService.getCompanyEmployees(selectedProfessional.username)
        .then(response => {
          const anyEmployee = { id: '0', name: 'Any Employee', role: '', image: '' };
          setEmployees([anyEmployee, ...response.data]);
          return true;
        });
    }
  }, [selectedProfessional]);


  useEffect(() => {
    if (selectedEmployee) {
      // Fetch employees for the selected professional
  
      companyService.getCompanyServicesByEmployee(selectedEmployee.username)
        .then(response => {
          setServices(response);
          setValue('companyemployeeid', selectedEmployee.username);
          return true;
        });
    }
  }, [selectedEmployee]);


  useEffect(() => {
    const duration = selectedServices.reduce((total, service) => {
      return total + parseInt(service.duration, 10);
    }, 0);
    setTotalDuration(duration);

    const price = selectedServices.reduce((total, service) => {
      return total + parseFloat(service.price);
    }, 0.0);
    setTotalPrice(price);
  }, [selectedServices]);

  useEffect(() => {
    ////

  if(selectedEmployee && selectedServices && selectedServices.length>0)
  {
    
    const totalDuration = calculateTotalDuration();

    companyService
      .getCompanyDisponibilities(selectedProfessional, selectedDate, 1 ,totalDuration)
      .then((availabilities) => {
        setTimeSlots(availabilities);
      })
      .catch((error) => {
        console.error('Failed to fetch time slots:', error);
      });

      
  }
  }, [selectedDate, selectedServices]);


  const calculateTotalDuration = () => {
    return selectedServices.reduce((total, service) => total + parseInt(service.duration, 10), 0);
    //selectedServices.reduce((total, service) => total + service.duration, 0);
  };


  // const onSubmit = (data) => {
  //   handleSubmitForm(data);
  //   reset();
  // };

  // const handleFormSubmit = handleSubmit((data) => {
  //   
  //   onSubmit(data);
  //   onFinish();
  // });
  const handleFormSubmit = (data) => {
    // Prevent the default form submission
    // Process the form data
    handleSubmitForm(data);
    onFinish();
    // Your form submission logic goes here
  };
  // const handleFormSubmit = (event) => {
  //   
  //   event.preventDefault(); // Prevent the default form submission
  //   handleSubmit(onSubmit)();
  //   onFinish();
  // };

  const handleDeleteClick = () => {
    handleDeleteSubmit(formdata);
  };

  const handleBackToFormClick = () => {
    setSelectedProfessionalType(null);
    setShowProfessionalsList(false);
    setShowProfessionalSchedule(false);
  };

  // State to track the selected day
  const [selectedDay, setSelectedDay] = useState(null);

  // Group availabilities by day
  const groupedAvailabilities = availabilities.reduce((grouped, availability) => {
    const day = new Date(availability.startDate).toLocaleDateString();

    if (!grouped[day]) {
      grouped[day] = [];
    }

    grouped[day].push(availability);

    // Update maxDate if the current availability's start date is greater
    const availabilityStartDate = new Date(availability.startDate);
    if (availabilityStartDate > currentShownEndDate) {
      setCurrentShownEndDate(availabilityStartDate);
    }


    return grouped;
  }, {});

  // Handle day button click
  const handleDayClick = (day) => {
    setSelectedDay(day);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);//
  };

  // Handle slot click
  // const handleSlotClick = (slot) => {
  //  //
  //   scheduleSelectAction(slot);

  // };

  // const fetchAvailabilities = (professional,numberOfDays) => {
  //  //
  //   setSelectProfessional(professional);

  //   companyService
  //     .getCompanyDisponibilities(professional,currentShownEndDate,daysToFetch)
  //     .then((availabilities) => {
  //       console.log(availabilities);
  //       setAvailabilities(availabilities);


  //       setShowProfessionalsList(false);
  //       setShowProfessionalSchedule(true);
  //     })
  //     .catch((error) => {
  //       console.error("Failed to fetch professionals:", error);
  //     });
  // };

  // Handle the selection of a professional
  
  const handleViewScheduleClick = (professional) => {
    if (professional) {
      console.log(professional);

      setSelectedProfessional(professional);
      setValue('companyid', professional.id);
      setShowProfessionalsList(false);
      setShowProfessionalSchedule(true);
      // fetchAvailabilities(professional);
    }
  };

  const handleRescedulingClick = (context) =>
  {
   //

    if(context.companyid != '')
    {
      companyService.getCompaniesByCategoryAndLocationAndCompanyid("", {},context.companyid)
      .then(response => {
        handleViewScheduleClick(response[0]);
        return true;
      });
    }

 
  }

  // useEffect(() => {
  // //   fetchAvailabilities(daysToFetch);
  // // }, [daysToFetch]);

  // const handleViewMoreClick = () => {
  //   fetchAvailabilities(selectProfessional,10);
  //   currentShownEndDate.setDate(currentShownEndDate.getDate() +daysToFetch)
  //   setCurrentShownEndDate(currentShownEndDate)
  // };

  const showCurrentDateCaption = (date) => {
    var monthNameText = date
      .toLocaleString("default", { month: "long" })
      .toUpperCase();

    var yearNameText = date.getFullYear();

    return monthNameText + "," + yearNameText;
  };

  const changeCalendarDate = async (direction) => {
    var current = new Date(selectedDate);

    current = new Date(
      selectedDate.setDate(calendarDate.getDate() + 7 * direction)
    );

    setCalendarDate(current);
  };

  //1st Step of the process -  Handle the selection of a professional Type
  const handleProfessionalTypeClick = (professionalType) => {
    setSelectedProfessionalType(professionalType);

    // companyService
    //   .getCompaniesByCategoryAndLocation(professionalType, {
    //     Lat: currentlatitude,
    //     Lng: currentlongitude,
    //   })
    //   .then((company) => {

    // setProfessionals(company);
    setShowProfessionalsList(true);
    setShowProfessionalSchedule(false);
    // })
    // .catch((error) => {
    //   console.error("Failed to fetch professionals:", error);
    // });
    // const filteredProfessionals = professionalTypes.filter(
    //   (professional) => professional.type === type
    // );
    // setProfessionals(filteredProfessionals);
    // setSelectedProfessional(null);
  };

  const handleEmployeeSelect = (event) => {
  const employeeId = event.target.value;
  const employee = employees.find(emp => emp.id === employeeId);
  setSelectedEmployee(employee);
  };

  const handleServiceSelect = (service) => { 
    setSelectedServices((prevSelectedServices) => {

      if (prevSelectedServices.includes(service)) {
        // Remove service if already selected
        return prevSelectedServices.filter((s) => s.id !== service.id);
      } else {
        // Add service if not already selected
        return [...prevSelectedServices, service];
      }
    });
  };

  const handleSlotSelect = (slot) => {
    setSelectedSlot(slot);
    scheduleSelectAction(slot)
  };

  // 2nd Step of the process - show professionals List screen
  if (showProfessionalsList) {
    // Render professionals list and map view
    return (
      <Modal
        key={"modalAppointmentForm"}
        className={"gx-modal gx-modal-wide choose-professionals-popup"}
        open={visible}
        title={newForm ? t("AppointmentForm.newschedule") : t("AppointmentForm.editschedule")}
        closeIcon={<CloseOutlined style={{ fontSize: 14 }} />}
        onCancel={closeModal}
        footer={
          <footer>
            <Grid xs={12} item container>
              <Grid xs={1} item>
                {/* helperText={errors.description?.message} */}
                <Button
                  type="button"
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteFilled />}
                  onClick={handleDeleteClick}
                  className="delete-btn"
                >
                  {t("AppointmentForm.delete")}
                </Button>
              </Grid>
              <Grid xs={9} item />
              <Grid xs={2} item>
                <Button
                  type="submit"
                  variant="contained"
                  className="submit-btn"
                >
                  {newForm ? t("AppointmentForm.add") : t("AppointmentForm.update")}
                </Button>
              </Grid>
            </Grid>
          </footer>
        }
      >
        <MapPicker
          Lat={currentlatitude}
          Lng={currentlongitude}
          address={currentAddress}
          selectedProfessionalType={selectedProfessionalType}
          handleViewScheduleClick={handleViewScheduleClick}
        />
      </Modal>
    );
  }
  // 4th Step of the process - Handle the selection of a schedule
  const scheduleSelectAction = (appointmentData) => {
    // setNewForm(false);
    // setVisible(false);

    // let startDate = new Date(startDate);
    // let endDate = new Date(endDate);
   //
    setFormData({
      id: appointmentData.id,
      title: appointmentData.title,
      description: appointmentData.description,
      calendarid: appointmentData.calendarid,
      startDate:
        appointmentData.startDate != null
          ? appointmentData.startDate
          : moment(), //.format('YYYY/MM/DD'),
      endDate:
        appointmentData.endDate != null
          ? appointmentData.endDate
          : moment().add(30, "minutes"), //.format('YYYY/MM/DD'),
      calendars: formdata.calendars,
      companyid: appointmentData.companyid,
      companyemployeeid: appointmentData.companyemployeeid,
    });

    // setShowProfessionalsList(false);
    // setShowProfessionalSchedule(false);
    // setVisible(true);
  };

  const shouldDisableDate = (date) => {
    return date < new Date(); // Disable dates before today
  };

  // const getTotalDuration = () => {
  //   console.log("getTotalDuration");
  //   return selectedServices.reduce((total, serviceId) => {
  //     const service = services.find(s => s.id === serviceId);
  //     return total + (service ? parseInt(service.duration, 10) : 0);
  //   }, 0);
  // };

  // const getTotalPrice = () => {
  //   console.log("getTotalPrice");
  //   return selectedServices.reduce((total, serviceId) => {
  //     const service = services.find(s => s.id === serviceId);
  //     return total + (service ? parseFloat(service.price) : 0);
  //   }, 0);
  // };

  // 3rd Step of the process -  show professional schedule screen
  if (showProfessionalSchedule) {
    return (
      <Modal
        key={"modalAppointmentForm"}
        className={"gx-modal gx-modal-wide availabilities-pro-popup"}
        open={visible}
        title={newForm ? t("AppointmentForm.newschedule") : t("AppointmentForm.editschedule")}
        closeIcon={<CloseOutlined style={{ fontSize: 14 }} />}
        onCancel={closeModal}
        footer={
          <footer>
            <Grid xs={12} item container>
              <Grid xs={1} item>
                {/* helperText={errors.description?.message} */}
                <Button
                  type="button"
                  variant="contained"
                  className="delete-btn"
                  startIcon={<DeleteFilled />}
                  onClick={handleDeleteClick}
                >
                  {t("AppointmentForm.delete")}
                </Button>
              </Grid>
              <Grid xs={9} item />
              <Grid xs={2} item>
                <Button
                  type="submit"
                  variant="contained"
                  className="submit-btn"
                  onClick={handleSubmit(handleFormSubmit)} 
                >
                  {newForm ? (showProfessionalSchedule?t("AppointmentForm.book"):t("AppointmentForm.add")) : t("AppointmentForm.update")}
                </Button>
              </Grid>
            </Grid>
          </footer>
        }
      >
    <form className="signup-form" spacing="5">            
        <input type="hidden" {...register("companyid")} value={selectedProfessional.id} />
              <input type="hidden" {...register("companyemployeeid")} value={selectedEmployee ? selectedEmployee.id : ''} />
              <input type="hidden" {...register("startDate")} value={selectedSlot ? selectedSlot.startDate : ''} />
              <input type="hidden" {...register("endDate")} value={selectedSlot ? selectedSlot.endDate : ''} />

    <Grid container spacing={2}>
      
          {/* First Column: Company Description */}
          <Grid item xs={3}>
            <Box>
              
              <Avatar src={selectedProfessional.logo} alt={selectedProfessional.name} style={{ width: 80, height: 80, marginBottom: 16 }} />
              <Typography variant="h4" {...register("companyid")} className="professional-name">{selectedProfessional.name}</Typography>
              <Typography variant="h5" className="professional-address">{selectedProfessional.address}</Typography>
              <Typography variant="h5" className="professional-hours">Open today: 08:00-20:00</Typography>
            </Box>
          </Grid>

          {/* Second Column: Selection of Company and Services */}
          <Grid item xs={3}>
            <Box>
              <Typography variant="h6">Select Employee</Typography>
              <Select
                value={selectedEmployee ? selectedEmployee.id : ''}
                style={{ width: '100%' }}
                onChange={handleEmployeeSelect}
                label="Employee"
                renderValue={(selected) => {
                  const employee = employees.find(emp => emp.id === selected);
                  if (!employee) return null;
                  return (
                    <Box display="flex" alignItems="center">
                      <Avatar src={employee.image || ''} alt={employee.name} style={{ marginRight: 8 }}>
                        {employee.image ? '' : employee.name.charAt(0)}
                      </Avatar>
                      <Typography>{employee.name}</Typography>
                    </Box>
                  );
                }}
              >
                {employees.length > 0 ? (
                  employees.map((employee) => (
                    <MenuItem key={employee.id} value={employee.id}>
                      <ListItemAvatar style={{ minWidth: '40px' }}>
                        <Avatar src={employee.image || ''} alt={employee.name} style={{ marginRight: 8 }}>
                          {employee.image ? '' : employee.name.charAt(0)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={employee.name} secondary={employee.role} />
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="None">
                    <ListItemText primary="No employees available" />
                  </MenuItem>
                )}
              </Select>
            </Box>

            <Box marginTop={2}>
              <Typography variant="h6">Select Services</Typography>
              <ServiceSelector
                services={services}
                selectedServices={selectedServices}
                onSelect={handleServiceSelect}
              />
            </Box>
          </Grid>

          {/* Third Column: Selection of the Slot */}
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateCalendar
                defaultValue={initialValue}
                loading={isLoading}
                value={selectedDate ?? " "}
                onMonthChange={handleMonthChange}
                onChange={handleDateChange}
                renderLoading={() => <DayCalendarSkeleton />}
                shouldDisableDate={shouldDisableDate}
                slots={{
                  day: ServerDay,
                }}
                slotProps={{
                  day: {
                    highlightedDays,
                  },
                }}
              />
            </LocalizationProvider>
            <TimeSlotList professional={selectedProfessional} employee={selectedEmployee} selectedServices={selectedServices} timeSlots = {timeSlots} onSlotSelect={handleSlotSelect} selectedDate={selectedDate} interval={30} />
          </Grid>
          {/* {...register("companyid",selectedProfessional.id)}{...register("companyemployeeid",selectedEmployee.id)} {...register(selectedSlot.startDate)} */}
          {/* Fourth Column: Recapitulation */}
          <Grid item xs={3}>
          <Box>
            <Typography variant="h6">Booking Summary</Typography>
                  <ListItem>
                      <ListItemText primary="Company" secondary= {selectedProfessional.name} />
                      <ListItemText primary="Address" secondary= {selectedProfessional.address} />
                    </ListItem>
                  <ListItem>
                      <ListItemText primary="Selected Employee" secondary=  {selectedEmployee ? selectedEmployee.name : 'None'} />
                    </ListItem>
                  <ListItem>
                      <ListItemText primary="Selected Services" secondary= {<ul>
                            {selectedServices.length > 0 ? (
                              selectedServices.map(currentservice => {
                                const service = services.find(s => s.id === currentservice.id);
                                return <li key={service.id}>{t(service.name)}</li>;
                              })
                            ) : (
                              <li>No services selected</li>
                            )}
                          </ul>} />
                    </ListItem>
                  <ListItem>
                      <ListItemText primary="Selected Date" secondary= {selectedDate ? selectedDate.toLocaleDateString() : 'None'} />                
                      <ListItemText primary="Selected Slot" secondary= {selectedSlot ? (
                          <Typography>{new Date(selectedSlot.startDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Typography>
                        ) : (
                          <Typography>No slot selected</Typography>
                        )}/>
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Total Duration" secondary={`${totalDuration} minutes`} />
                      <ListItemText primary="Total Price" secondary={`$${totalPrice.toFixed(2)}`} />
                    </ListItem>
            </Box>
            {/* <Box>
              <Typography variant="h6">Recap</Typography>
              <Typography variant="body1"  ><strong>Company:</strong> {selectedProfessional.name}</Typography>
              <Typography variant="body1"><strong>Address:</strong> {selectedProfessional.address}</Typography>
              <Typography variant="body1"  ><strong>Selected Employee:</strong> {selectedEmployee ? selectedEmployee.name : 'None'}</Typography>
              <Typography variant="body1"><strong>Selected Services:</strong></Typography>
              <ul>
                {selectedServices.length > 0 ? (
                    selectedServices.map(currentservice => {
                     //
                      const service = services.find(s => s.id === currentservice.id);
                      return <li key={service.id}>{t(service.name)}</li>;
                    })
                  ) : (
                    <li>No services selected</li>
                  )}
              </ul>
              <Typography variant="body1"><strong>Selected Date:</strong> {selectedDate ? selectedDate.toLocaleDateString() : 'None'}</Typography>
              <Typography variant="h5" >Selected Slot:</Typography>
                  {selectedSlot ? (
                    <Typography>{new Date(selectedSlot.startDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Typography>
                  ) : (
                    <Typography>No slot selected</Typography>
                  )}


                <Typography variant="body1" ><strong>Total Duration:</strong> {totalDuration} minutes</Typography>
                <Typography variant="body1" ><strong>Total Price:</strong> ${totalPrice.toFixed(2)}</Typography>
            </Box> */}
          </Grid>

          {/* Divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        </form>
      </Modal>
    );
  }

  const initialDate = "2024-07-11T10:00:00";
  // Show normal event creation form
  return (
    <Modal
      key={"modalAppointmentForm"}
      className={"gx-modal new-schedule-regular-popup"}
      open={visible}
      title={newForm ? t("AppointmentForm.newschedule") : t("AppointmentForm.editschedule")}
      closeIcon={<CloseOutlined style={{ fontSize: 14 }} />}
      onCancel={closeModal}
      footer={
        <footer>
          <Grid xs={12} item container style={{  display: "flex", alignItems: "center", justifyContent: "space-between", }} >
            <div>
              <Button type="button" variant="contained" className="delete-btn" startIcon={<DeleteFilled />} onClick={handleDeleteClick} >
                {t("AppointmentForm.delete")}
              </Button>
            </div>

            {newForm ?
              <></>
              :
              <div>
                <Button  variant="contained" className="submit-btn"  onClick={() => handleRescedulingClick(formdata )} >
                  Reschedule
                </Button>
              </div>
            }
            <div>
              <Button  variant="contained" className="submit-btn" onClick={handleSubmit(handleFormSubmit)} >
                {newForm ? t("AppointmentForm.add") : t("AppointmentForm.update")}
              </Button>
            </div>
          </Grid>
        </footer>
      }
    >
      <Box display="flex">
        <Box flexGrow={1}>
          <form className="signup-form" spacing="5">
            <input type="hidden" className="input" defaultValue={formdata?.id} {...register("id")} />
            <input type="hidden" className="input" defaultValue={formdata?.companyid} {...register("companyid")} />            
            <input type="hidden" className="input" defaultValue={formdata?.companyemployeeid} {...register("companyemployeeid")} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="edit-schedule-input" style={{ margin: 0, position: "relative", display: "flex", alignItems: "center", margin: "10px 0 10px 0", }} >
                  <TextField label={t("AppointmentForm.title")} variant="outlined" fullWidth {...register("title", {defaultValue: formdata?.title || "",})}
                    error={errors.title !== undefined ? errors.title : undefined}
                  />
                  <DriveFileRenameOutlineOutlined
                    style={{
                      position: "absolute",
                      color: "var(--light-color)",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="date-field">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    control={control}
                    name="startDate"
                    defaultValue={dayjs(initialDate)}
                    render={({ field }) => (
                      <DateTimePicker
                        {...field}
                        value={field.value ? dayjs(field.value) : dayjs(initialDate)}
                        onChange={(date) => field.onChange(date)}
                      />
                    )}
                  />
              </LocalizationProvider>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name="startDate"
                    control={control}
                    defaultValue={formdata?.startDate ? dayjs(formdata.startDate) : dayjs()}
                    render={({ field }) => (
                      <DateTimePicker
                        label={t("AppointmentForm.starttime")}
                        value={field.value}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                          handleDateChange(newValue, 'startDate');
                        }}
                        disabled={formdata?.companyid != null}
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            variant="outlined"
                            fullWidth
                            error={!!errors.startDate}
                            helperText={errors.startDate?.message}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider> */}
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name="startDate"
                    control={control}
                    // defaultValue={dayjs()} // Ensure default value is a dayjs object
                    render={({ field }) => (
                      <DateTimePicker
                        label={t("AppointmentForm.starttime")}
                        value={field.value}
                        className="datetime-picker"
                        onChange={field.onChange}
                        disabled={formdata?.companyid != null}
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            variant="outlined"
                            fullWidth
                            error={errors.startDate !== undefined ? errors.startDate : undefined}
                          />
                          // helperText={errors.startdate?.message}
                        )}
                      />
                    )}
                  />
                </LocalizationProvider> */}
              </Grid>
              <Grid xs={12} item md={6} className="date-field" >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Controller
                        control={control}
                        name="endDate"
                        defaultValue={dayjs(initialDate)}
                        render={({ field }) => (
                          <DateTimePicker
                            {...field}
                            value={field.value ? dayjs(field.value) : dayjs(initialDate)}
                            onChange={(date) => field.onChange(date)}
                          />
                        )}
                      />
                  </LocalizationProvider>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => {
                      const value = dayjs(field.value); // Ensure field.value is a dayjs object
                      return (
                        <DateTimePicker
                          label="End Time"
                          value={value}
                          onChange={(newValue) => {
                            field.onChange(newValue);
                            handleDateChange(newValue, 'endDate');
                          }}
                          disabled={formdata?.companyid != null}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              variant="outlined"
                              fullWidth
                              error={!!errors.endDate}
                              helperText={errors.endDate?.message}
                            />
                          )}
                        />
                      );
                    }}
                  />
                </LocalizationProvider> */}
                
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name="endDate"
                    control={control}
                    defaultValue={dayjs()} // Ensure default value is a dayjs object
                    render={({ field }) => (
                      <DateTimePicker
                        label={t("AppointmentForm.endtime")}
                        value={field.value}
                        className="datetime-picker"
                        onChange={field.onChange}
                        disabled={formdata?.companyid != null}
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            variant="outlined"
                            fullWidth
                            error={errors.endDate !== undefined ? errors.endDate : undefined}
                          />
                          // helperText={errors.enddate?.message}
                        )}
                      />
                    )}
                  />
                </LocalizationProvider> */}
              </Grid>
              {newForm ? (
                <div>
                  <Typography variant="h6">Choose a professional:</Typography>
                  {Array.isArray(professionalTypes) ? (
                    <div className="professional-categories">
                      {professionalTypes.map((proftype) => (
                        <a
                          key={proftype.id}
                          variant={
                            selectedProfessionalType === proftype.name
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => handleProfessionalTypeClick(proftype)}
                        >
                          {proftype.name}
                        </a>
                      ))}
                    </div>
                  ) : (
                    <Typography
                      variant="body1"
                      style={{
                        marginBottom: "20px",
                        textDecoration: "underline",
                        textDecorationColor: "#01b075",
                      }}
                    >
                      No professional types available.
                    </Typography>
                  )}
                </div>
              ) : (<></>
                // <Button  >Reschedule Meeting</Button>
                // onClick={handleViewScheduleClick(selectProfessional)}
              )}


              <Grid xs={12} item>
                <Typography>{t("AppointmentForm.calendar")}</Typography>
                <Controller
                  name="calendarid"
                  control={control}
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      onChange={field.onChange}
                      variant="outlined"
                      className="select-calendar-input"
                      fullWidth
                      error={errors.calendarid !== undefined ? errors.calendarid : undefined}
                    >
                      {/* helperText={errors.calendarid?.message} */}
                      {formdata?.calendars?.map((c) => (
                        <MenuItem key={c.id} value={c.id}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
              <div
                className="description-schedule-textarea"
                style={{
                  margin: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "flex-start",
                  margin: "10px 0 10px 0",
                  width: "100%"                
                }}
              >
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      label={t("AppointmentForm.description")}
                      variant="outlined"
                      rows={3}
                      {...field}
                      error={errors.description !== undefined?errors.description:""}
                    />
                  )}
                />
                <SortOutlined
                  style={{
                    position: "absolute",
                    color: "var(--light-color)",
                  }}
                />
              </div>
              {/* <Grid xs={12} item>
                <div
                  className="description-schedule-textarea"
                  style={{
                    margin: 0,
                    position: "relative",
                    display: "flex",
                    alignItems: "flex-start",
                    margin: "10px 0 10px 0",
                  }}
                >
                  <TextArea
                    label={t("AppointmentForm.description")}
                    variant="outlined"
                    rows={3}
                    {...register("description")}
                    error={errors.description !== undefined ? errors.description : undefined}
                  />
                  <SortOutlined
                    style={{
                      position: "absolute",
                      color: "var(--light-color)",
                    }}
                  />
                </div>
              </Grid> */}
            </Grid>
          </form>
        </Box>
        {/* <Box width={200} padding={2} marginRight={2}  marginLeft={2} bgcolor="#f0f0f0">
      <Typography variant="h6">Book an appointment</Typography>
      <Box marginTop={2}>
        
        {activityTypes.map((activityType) => (
          <Button
            key={activityType.id}
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginBottom: 10 }}
          >
            {activityType.label}
          </Button>
        ))}
      </Box>
    </Box> */}
      </Box>
    </Modal>
  );
});

const mapStateToProps = (state) => {
  return {
    calendars: state.calendar.calendars,
    currentCalendar: state.calendar.currentCalendar,
    schedules: state.schedule.schedules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequestCalendars: () => requestCalendars(dispatch),
    onReceiveCalendar: (Color, BgColor, Name, Id, selected) =>
      dispatch(receiveCalendar(Color, BgColor, Name, Id, selected)),
    onSetCalendar: (calendar) => dispatch(setCalendar(calendar)),
    onRequestSchedules: () => requestSchedules(dispatch),
    onReceiveSchedule: (
      Name,
      description,
      Recurence,
      Category,
      Location,
      calendarId,
      id,
      Start,
      End,
      RoleGroupId
    ) =>
      dispatch(
        receiveSchedule(
          Name,
          description,
          Recurence,
          Category,
          Location,
          calendarId,
          id,
          Start,
          End,
          RoleGroupId
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentForm);
