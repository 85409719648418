import React, { useState } from 'react';
import ComingEvents from './ComingEvents';
import FriendsShares from './FriendsShares';
import { Row, Col, Breadcrumb, Layout, Menu, Typography, Tooltip } from 'antd';
 import { Box, Container, TextField, Button, Grid } from '@mui/material';
 import { LaptopOutlined, NotificationOutlined, UserOutlined, PushpinOutlined } from "@ant-design/icons";

const samplePosts = [
  {
    author: { name: 'John Doe', avatar: 'https://via.placeholder.com/50' },
    content: 'Hello, this is my first post!',
  },
  {
    author: { name: 'Jane Smith', avatar: 'https://via.placeholder.com/50' },
    content: 'Great day at the beach! 🏖️',
  },
  // Add more sample posts as needed
];

const UserDashboard = () => {

  const [basicColor, setBasicColor] = useState('#4865F5')
  const [events, setEvents] = useState([]);
  const [friendsShares, setFriendsShares] = useState([
    {
      id: 1,
      user: {
        name: 'John Doe',
        jobTitle: 'Software Engineer',
        profilePicture: 'https://example.com/john-doe-profile.jpg',
      },
      content: 'Just landed my dream job!',
      likes: 10,
      comments: 5,
    },
    {
      id: 2,
      user: {
        name: 'sadok jaziri',
        jobTitle: 'Software Engineer',
        profilePicture: 'https://example.com/john-doe-profile.jpg',
      },
      content: 'Just another post!',
      likes: 5,
      comments: 7,
    },
    ]);
  const [posts, setPosts] = useState(samplePosts);
  const [newPost, setNewPost] = useState('');

  const { Content, Sider } = Layout;
  const { Title } = Typography;
  

  // Function to add a new event
  const addEvent = (event) => {
    setEvents([...events, event]);
  };

  // Function to add a new friend share
  const addFriendShare = (friendShare) => {
    setFriendsShares([...friendsShares, friendShare]);
  };

  const handleAddPost = () => {
    if (newPost.trim() !== '') {
      const newPostObject = {
        author: { name: 'Current User', avatar: 'https://via.placeholder.com/50' },
        content: newPost,
      };
      setPosts([...posts, newPostObject]);
      setNewPost('');
    }
  };


  return (
    <Layout className={'content-form'} style={{ minHeight: '100vh' }}>

    <Content style={{ margin: '0 16px' }}>
           <Grid item xs={12}>    
           <div>
              <h2>Friends' Shares</h2>
                <ul>
                     {friendsShares && friendsShares.map((share) => ( 
                        <FriendsShares 
                              key={share.id}
                              user={share.user}
                              content={share.content}
                              likes={share.likes}
                              comments={share.comments} />
                       ))} 
                </ul>
              </div>
           </Grid>
    </Content>
    <Sider width={300} collapsedWidth={85} style={{ backgroundColor: 'transparent!important',borderRight: '1px solid #001529'}}>
        <div className={`gx-w-100 side_bar_header  `}>
           <Title level={2} className={'side_bar_header'}>Coming meeting</Title>
           <div className={'flex-grow-1'} />
        </div>
        <div className={`gx-w-100 side_bar_header  `}>
           <Title level={2} className={'side_bar_header'}>Coming events</Title>
           <div className={'flex-grow-1'} />
        </div>
    </Sider>
</Layout>


    //   <Container maxWidth="md">
  //   <Box sx={{ mt: 4 }}>
  //     {/* New post input */}
  //     <TextField
  //       label="Share your thoughts..."
  //       variant="outlined"
  //       value={newPost}
  //       onChange={(e) => setNewPost(e.target.value)}
  //       fullWidth
  //       sx={{ mb: 2 }}
  //     />
  //     <Button variant="contained" onClick={handleAddPost}>
  //       Post
  //     </Button>

  //     {/* Render existing posts */}
  //     <Grid container spacing={2} sx={{ mt: 2 }}>
  //       {posts.map((post, index) => (
  //         <Grid item xs={12} key={index}>
  //            <FriendsShares friendsShares={friendsShares} />
  //         </Grid>
  //       ))}
  //     </Grid>
  //   </Box>
  // </Container>
    // <div>
    //   <h1>User Dashboard</h1>
    //   <ComingEvents events={events} />
    //   <FriendsShares friendsShares={friendsShares} />
    //   {/* Add forms or input components here to allow users to add new events and friend shares */}
    // </div>
  );
};

export default UserDashboard;
