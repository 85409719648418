import * as React from "react";
import Button from "@mui/material/Button";
import { Menu, MenuItem } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/system";
import { authenticationService, userService } from "../../app/services";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";


// Images
import avatar from "../../assets/images/avatar.png";
// import { selectUser } from "../../app/services/user.slice";

// export default function ProfileSubMenu({ appendClass }) {
  function ProfileSubMenu(props,{ appendClass }) {
  const navigate = useNavigate();
  const [isOpen, setOpen] = React.useState(false);
  const menuActions = React.useRef(null);
  const [buttonElement, setButtonElement] = React.useState(null);
  // const [user] = useSelector(selectUser);

  // const user = useSelector(selectUser);
  // const initials = user.name
  // .split(' ')
  // .map((name) => name[0])
  // .join('');

  const updateAnchor = React.useCallback((node) => {
    setButtonElement(node);
  }, []);

  const handleToggle = () => {
    if (!isOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    // setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (buttonElement && buttonElement.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(isOpen);

  React.useEffect(() => {
    if (prevOpen.current && !isOpen) {
      buttonElement?.focus();
    }

    prevOpen.current = isOpen;
  }, [isOpen, buttonElement]);

  // React.useEffect(() => {
  //   authenticationService
  //     .getUserByToken()
  //     .then((user) => {
  //       
  //       setUser(user);
  //     })
  //     .catch((error) => {
  //       console.error("Failed to fetch professional types:", error);
  //     });
  // }, []);

  React.useEffect(() => {
    if (prevOpen.current && !isOpen) {
      buttonElement?.focus();
    }

    prevOpen.current = isOpen;
  }, [isOpen, buttonElement]);

  const renderUserAvatar = () => {
    if (user && user.image) {
      return <Avatar alt={user.name} src={user.image} />;
    } else if (user && user.name) {
      const initials = user.name
        .split(" ")
        .map((name) => name[0])
        .join("");
      return <Avatar>{initials}</Avatar>;
    }
    return null;
  };

  // const createHandleMenuClick = (menuItem) => {
  // 
  //     return () => {
  //     setOpen(false);
  //     buttonElement?.focus();
  //     };
  // };

  const createHandleMenuClick = (e, menuItem) => {
    e.preventDefault();
    if (menuItem === "Profile") {
      // Handle profile menu item
      // Example: navigate to profile page
      navigate("/profile");
    } else if (menuItem === "My account") {
      // Handle my account menu item
      // Example: navigate to account settings page
      navigate("/account-settings");
    } else if (menuItem === "Logout") {
      // Handle logout menu item
      // Example: perform logout action
      authenticationService.logout();
    }

    setOpen(false);
    buttonElement?.focus();
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      className={`profile-submenu ${appendClass}`}
    >
      <div>
        <Button
          ref={updateAnchor}
          id="composition-button"
          aria-controls={isOpen ? "composition-menu" : undefined}
          aria-expanded={isOpen ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Avatar
            src={avatar}
            alt="diarytree profile image"
            sx={{
              width: 40,
              height: 40,
              padding: "0 !important",
            }}
          />
          {/* startIcon={renderUserAvatar()} src={user.profilePicture} */}
          {/* {user && user.email && <Avatar alt={user.name} />} */}
        </Button>
        <Popper
          open={isOpen}
          anchorEl={buttonElement}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
          className="dropdown-profile-submenu"
          style={{ zIndex: 1 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              className="dropdown-transition-wrapper"
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "right top",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <div className="dropdown-profile-wrapper">
                    <div className="profile-info">
                      {/* <div>
                        <Avatar
                          src={avatar}
                          alt="diarytree profile image"
                          sx={{
                            width: 60,
                            height: 60,
                            padding: "0 !important",
                          }}
                        />
                      </div> {props.user.username}*/}
                      <div className="user-details">
                        <h5>{props.user.sub}</h5>
                        <p className="role">Product Manager</p>
                        <p className="subscription">{props.user.typ}</p>
                      </div>
                    </div>
                    {/* 
                    TODO
                    
                    <div className="subscription-offer">
                      <div className="subscription-title">
                        <p>
                          Try our premium DiaryTree plan and you will save min
                          €20/mo
                        </p>
                      </div>
                      <div className="subscription-info">
                        <span>€ 12/mo</span>
                        <a href="!#">Subscribe</a>
                      </div>
                    </div> */}
                    <hr />
                    <div className="nav-links">
                      <a
                        href="!#"
                        onClick={(e) => createHandleMenuClick(e, "Profile")}
                      >
                        Profile
                      </a>
                    </div>
                    {/* <div className="nav-links">
                      <a href="!#">Settings</a>
                    </div> */}
                    <div className="nav-links">
                      <a
                        href="!#"
                        onClick={(e) => createHandleMenuClick(e, "Logout")}
                      >
                        Logout
                      </a>
                    </div>
                  </div>
                  {/* <VerticalMenuList
                    autoFocusItem={isOpen}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={createHandleMenuClick("Profile")}>
                      Profile
                    </MenuItem>
                    <MenuItem onClick={createHandleMenuClick("My account")}>
                      My account
                    </MenuItem>
                    <MenuItem onClick={createHandleMenuClick("Logout")}>
                      Logout
                    </MenuItem>
                  </VerticalMenuList> */}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps)(ProfileSubMenu);

const VerticalMenuList = styled(MenuList)`
  display: flex;
  flex-direction: column;
`;
