import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Input, message, Modal, DatePicker } from "antd";
import { Row, Col } from "react-bootstrap";
import { CirclePicker } from "react-color";
import { Button, Grid, Alert, TextField } from "@mui/material";
import * as Yup from "yup";
import {
  CloseOutlined,
  DeleteFilled,
  CalendarOutlined,
  FileOutlined,
} from "@ant-design/icons";
// import Form from 'react-bootstrap/Form';

import moment from "react-moment";
import {
  DriveFileRenameOutlineOutlined,
  SortOutlined,
} from "@mui/icons-material";
import TextArea from "antd/es/input/TextArea";

const AddCalendarModal = (props) => {
  const { visible, newForm, formdata, closeModal } = props;
  const { register, handleSubmit, reset, control, formState } = useForm({
    defaultValues: {
      name: formdata?.name,
      description: formdata?.description,
      color: formdata?.color,
    },
  });
  const handleCloseForm = () => {
    closeModal();
  };
  // : { errors }{defaultValues: {'title':props?.formdata?.title,'startdate':formdata?.startDate,'enddate':formdata?.endDate,'calendarid':formdata?.selectedCalendar,'description':formdata?.description}}

  const [color, setColor] = useState("#4865F5");
  useEffect(() => {
    if (props.visible) {
      // if (props.formdata) {
      //     setColor(props.formdata.color);
      //     //form.setFieldsValue(props.formdata);
      //     setTitle('Edit Calendar');
      // }
      // else {
      //     //resetAsyncForm();
      //     //form.resetFields();
      //     setColor('');
      // }
    }
  }, [props.visible]);

  const handleChangeComplete = (param) => {
    setColor(param.hex);
  };

  const onSubmit = (data, e) => {
    console.log("on submit.....", data);
    props.handleSubmit(data);

    reset({ name: "", description: "", color: "" });
  };

  const handleDeleteClick = async () => {
    props.handleDeleteSubmit(props.formdata.id);
  };

  const onError = (errors, e) => console.log(errors, e);
  //   async () => {
  // await form.validateFields().then((data) => {
  //     var submitData = {};
  //     Object.getOwnPropertyNames(data).forEach(key => {
  //         if (key == 'color') {
  //             submitData[key] = data[key]['hex'];
  //         } else {
  //             submitData[key] = data[key];
  //         }
  //     })
  //     if(props.formdata){
  //         props.handleEditSubmit(submitData);
  //     } else {
  //         props.handleSubmit(submitData);
  //     }
  //     if (moment(submitData.startHour).isAfter(submitData.endHour)) {
  //         Alert.warning('Start time must earlier than end time!');
  //     } else {
  //         form.resetFields();
  //         Alert.success('Sucessfully added!');
  //     }
  // });
  // }, onError)

  return (
    // <Modal
    // title={title}
    // open={props.visible}
    // onCancel={props.handleClose}
    // footer={null}

    <Modal
      key={"modalCalendarForm"}
      className={"gx-modal edit-calendar-modal"}
      open={visible}
      title={newForm ? "New Calendar" : "Edit Calendar"}
      closeIcon={<CloseOutlined style={{ fontSize: 14 }} />}
      onCancel={handleCloseForm}
      // onFinish={values => onFinish(values)}
      footer={false}
    >
      <form
        onSubmit={handleSubmit(onSubmit, (d) => console.log(d))}
        className="signup-form"
        spacing="5"
      >
        <input
          type="hidden"
          className="input"
          defaultValue={props?.formdata?.id}
          {...register("id")}
        />
        <div
          style={{
            margin: 0,
            position: "relative",
            display: "flex",
            alignItems: "center",
            paddingTop: "15px",
          }}
        >
          <TextField
            id="name"
            defaultValue={formState?.name}
            className="input edit-calendar-input"
            placeholder={formState?.name ? "" : "Enter calendar name"}
            label="Name"
            variant="outlined"
            {...register("name")}
          />
          <DriveFileRenameOutlineOutlined
            style={{
              position: "absolute",
              color: "var(--light-color)",
            }}
          />
          {/* <input className="input"
                        defaultValue={props?.formdata?.title}
                       
                        // onChange={(e)=> setTitle(e.target.value)}/onChange={(e)=> setTitle(e.target.value)}
                        placeholder="Input calendar name"
                        prefix={
                            <FileOutlined style={{ color: "#a2aabd", fontSize: 12 }} />
                        }
                    /> */}
          {/* rules={[
                                    {
                                        required: true,
                                        message: 'Calendar name is required!',   } name={'color'}
                                    },
                                ]} */}
        </div>
        <div
          style={{
            margin: 0,
            position: "relative",
            display: "flex",
            alignItems: "start",
          }}
          className="textarea-wrapper"
        >
          <textarea
            // rows={3}
            id="description"
            className="input edit-calendar-textarea"
            placeholder={formdata?.description || "Description"}
            label="Description"
            variant="outlined"
            name="description"
            defaultValue={formdata?.description}
            {...register("description")}
          />
          <SortOutlined
            style={{
              position: "absolute",
              color: "var(--light-color)",
            }}
          />
        </div>
        <Row span={24} style={{ paddingTop: "15px" }}>
          <h6>Color</h6>
        </Row>
        <Row span={24} style={{ margin: "10px 20px" }}>
          <Controller
            control={control}
            name="color"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => {
              return (
                // <Checkbox
                // onBlur={onBlur} // notify when input is touched
                // onChange={onChange} // send value to hook form
                // checked={value}
                // inputRef={ref}
                // />

                <CirclePicker
                  onChangeComplete={(param) => onChange(param.hex)}
                  inputRef={ref}
                  color={formState?.defaultValues.color}
                  className={"gx-d-flex gx-justify-content-center"}
                  style={{ float: "right" }}
                />
              );
            }}
          />
        </Row>
        <Row
          style={{
            margin: "30px 20px 0 20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Col span={2}>
            <DeleteFilled
              key="btndelete"
              type={"default"}
              className={"view_button modal-delete-action-btn"}
              onClick={handleDeleteClick}
              style={{ fontSize: "24px" }}
            >
              {"Remove"}
            </DeleteFilled>
          </Col>
          <Col span={12} />
          <Col span={2}>
            <Button
              type="submit"
              className={"view_button edit-calendar-btn"}
              style={{
                color: "#fff",
              }}
            >
              {newForm ? "Add" : "Save"}
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  );
};
// key="btnsubmit" className={"view_button"} style={{ backgroundColor: "#4865F5", color: "#fff" }}
const mapStateToProps = (state) => {
  return {
    calendars: state.calendar.calendars,
    currentCalendar: state.calendar.currentCalendar,
    schedules: state.schedule.schedules,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onRequestCalendars: () => requestCalendars(dispatch),
    onReceiveCalendar: (Color, BgColor, Name, Id, selected) =>
      dispatch(receiveCalendar(Color, BgColor, Name, Id, selected)),
    onSetCalendar: (calendar) => dispatch(setCalendar(calendar)),
    onRequestSchedules: () => requestSchedules(dispatch),
    onReceiveSchedule: (
      Name,
      description,
      Recurence,
      Category,
      Location,
      calendarId,
      id,
      Start,
      End,
      RoleGroupId
    ) =>
      dispatch(
        receiveSchedule(
          Name,
          description,
          Recurence,
          Category,
          Location,
          calendarId,
          id,
          Start,
          End,
          RoleGroupId
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCalendarModal);
// export default AddCalendarModal;

{
  /* <Form form={form} className={'gx-w-100'} layout={'vertical'}>
<Row className={'gx-d-flex gx-w-100'}>
    <Col span={24}>
        <Form.Item label={'Calendar Name'} name={'title'}
            rules={[
                {
                    required: true,
                    message: 'Calendar name is required!',
                },
            ]}>
            <Input className={'gx-w-100'} placeholder={'Input calendar name'} />
        </Form.Item>
    </Col>
    <Col span={24}>
        <Form.Item label={'Description'} name={'description'} >
            <Input className={'gx-w-100'} placeholder={'Input description'} />
        </Form.Item>
    </Col>
    <Col span={24}>
        <Form.Item name="color" label="Color" className={'gx-d-flex gx-justify-content-center'}>
            <CirclePicker color={color} onChangeComplete={handleChangeComplete} />
        </Form.Item>
    </Col>
    <Form.Item hidden={true} name={'id'}>
        <Input />
    </Form.Item>
    <Col span={24}>
        <Form.Item>
            <Button type={'primary'} className={'gx-w-100'} onClick={handleSubmit}>{title}</Button>
        </Form.Item>
    </Col>
</Row>
</Form> */
}
