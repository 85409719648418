import * as signalR from "@microsoft/signalr";
import { authenticationService } from "./authentication.service";
import appConfig from "../envConfig/config";
import logger from "redux-logger";

class SignalRService {
  constructor(webSocketUrl = appConfig.hub_url) {
    this.webSocketUrl = webSocketUrl;
    this.connection = null;
    this.eventHandlers = {};
  }

  buildConnection() {
    const token = authenticationService.currentUserToken;

    // Check if the token is available
    if (!token) {
      console.error('No authentication token found. SignalR connection will not be established.');
      return null;
    }

    const options = {
      accessTokenFactory: () => token,  // localhost from **AspNetCore3.1 service**
        skipNegotiation: true,
        logger: signalR.LogLevel.Debug,
        logMessageContent: true,
        transport: signalR.HttpTransportType.WebSockets

         
    };

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(this.webSocketUrl, options)
      .withAutomaticReconnect()
      .withHubProtocol(new signalR.JsonHubProtocol())
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.serverTimeoutInMilliseconds = 1000 * 60 * 5; // 5 minutes

    connection.onclose(async (error) => {
      console.error('SignalR connection closed:', error);
      await this.startConnection();
    });

    connection.onreconnecting((error) => {
      console.warn('SignalR connection lost. Reconnecting...', error);
    });

    connection.onreconnected((connectionId) => {
      console.log('SignalR reconnected. Connection ID:', connectionId);
    });

    return connection;
  }

  async startConnection() {
    if (!this.connection) {
      this.connection = this.buildConnection();
      if (!this.connection) {
        return;
      }
    }

    try {
      await this.connection.start();
      console.log('SignalR connection established.');
    } catch (err) {
      console.error('SignalR connection error:', err);

      // Enhanced error handling
      if (err.message.includes('negotiate version')) {
        console.error('Negotiate version error. Ensure client and server SignalR versions are compatible.');
      }

      if (err.message.includes('WebSocket failed to connect')) {
        console.log('Retrying connection...');
        setTimeout(() => this.startConnection(), 5000); // Retry after 5 seconds
      } else if (err.message.includes('The connection was stopped during negotiation')) {
        console.error('Connection stopped during negotiation. Check server configuration and network.');
      } else if (err.message.includes('Unauthorized')) {
        console.error('Unauthorized. Check the token and user authentication.');
      } else {
        throw err;
      }
    }
  }

  async stopConnection() {
    try {
      if (this.connection) {
        await this.connection.stop();
        console.log('SignalR connection stopped.');
        this.connection = null;
      }
    } catch (err) {
      console.error('Error stopping SignalR connection:', err);
      throw err;
    }
  }

  on(eventName, callback) {
    if (!this.eventHandlers[eventName]) {
      this.eventHandlers[eventName] = [];
    }

    const handler = (args) => {
      callback(args);
    };

    this.eventHandlers[eventName].push(handler);
    this.connection.on(eventName, handler);
  }

  onNotificationReceived(callback) {
    this.on("NotificationReceived", callback);
  }

  off(eventName, callback) {
    const handlers = this.eventHandlers[eventName];

    if (handlers && handlers.length > 0) {
      if (callback) {
        const index = handlers.indexOf(callback);
        if (index !== -1) {
          handlers.splice(index, 1);
          this.connection.off(eventName, callback);
        }
      } else {
        handlers.forEach((handler) => {
          this.connection.off(eventName, handler);
        });
        handlers.length = 0;
      }
    }
  }

  invoke(methodName, ...args) {
    if (this.connection) {
      return this.connection.invoke(methodName, ...args);
    } else {
      console.error('No SignalR connection established. Cannot invoke method:', methodName);
      return Promise.reject('No connection established');
    }
  }
}

export default SignalRService;





// import * as signalR from "@microsoft/signalr";
// import { authenticationService } from "./authentication.service";
// import appConfig from "../envConfig/config";

// class SignalRService {
//   constructor(webSocketUrl = appConfig.hub_url) {
//     this.connection = this.buildConnection(webSocketUrl);
//     this.eventHandlers = {};
//   }

//   buildConnection(webSocketUrl) {
//     const connection = new signalR.HubConnectionBuilder()
//         .withUrl(webSocketUrl, { accessTokenFactory: () => authenticationService.currentUserToken })
//         .withAutomaticReconnect()
//         .build();

//     // Set server timeout
//     connection.serverTimeoutInMilliseconds = 1000 * 60 * 5;

//     return connection;
// }

//   startConnection() {
//     return this.connection.start();
//   }

//   stopConnection() {
//     return this.connection.stop();
//   }

//   on(eventName, callback) {
//     if (!this.eventHandlers[eventName]) {
//       this.eventHandlers[eventName] = [];
//     }

//     const handler = (args) => {
//       // 
//       callback(args);
//     };

//     this.eventHandlers[eventName].push(handler);
//     this.connection.on(eventName, handler);
//   }

//   onNotificationReceived(callback) {
//     // 
//     this.connection.on("NotificationReceived", (notification) => {
//       callback(notification);
//     });
//   }

//   off(eventName, callback) {
//     const handlers = this.eventHandlers[eventName];

//     if (handlers && handlers.length > 0) {
//       if (callback) {
//         const index = handlers.indexOf(callback);
//         if (index !== -1) {
//           handlers.splice(index, 1);
//           this.connection.off(eventName, callback);
//         }
//       } else {
//         handlers.forEach((handler) => {
//           this.connection.off(eventName, handler);
//         });
//         handlers.length = 0;
//       }
//     }
//   }

//   invoke(methodName, ...args) {
//     return this.connection.invoke(methodName, ...args);
//   }
// }

// export default SignalRService;
