import React, { useState, useRef, useEffect } from "react";
import { Grid, Button, Typography } from "@mui/material";
import {
  LoadScript,
  StandaloneSearchBox,
  GoogleMap,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { PlaceRounded } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import { companyService } from "../app/services"; // Ensure this service is correctly set up
import { Rate } from "antd";

const libraries = ["places"];  // Define libraries array outside the component

const MapPicker = ({
  Lat,
  Lng,
  Address,
  selectedProfessionalType,
  handleViewScheduleClick
}) => {
  const [searchAddress, setSearchAddress] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);
  const [businesses, setBusinesses] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [latitude, setLatitude] = useState(Lat);
  const [longitude, setLongitude] = useState(Lng);
  const [address, setAddress] = useState(Address);
  const [professionalType, setProfessionalType] = useState(selectedProfessionalType);
  const { t } = useTranslation();

  const [registeredProfessionals, setRegisteredProfessionals] = useState([]);
  const [detailedBusinesses, setDetailedBusinesses] = useState([]);
  const mapRef = useRef(null);

  useEffect(() => {
    // Fetch professionals from your backend
    companyService
      .getCompaniesByCategoryAndLocationAndCompanyid(professionalType.name, { Lat: latitude, Lng: longitude })
      .then((company) => {
        setRegisteredProfessionals(company);
      })
      .catch((error) => {
        console.error("Failed to fetch professionals:", error);
      });
  }, [professionalType, latitude, longitude]);

  const fetchNearbyBusinesses = (location) => {
    if (location && mapRef.current) {
      const service = new window.google.maps.places.PlacesService(mapRef.current);
      service.nearbySearch(
        {
          location,
          radius: 1000,
          type: [professionalType.googleTypeName], // Adjust the type as needed
        },
        (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            debugger;
            setBusinesses(results);
            // Fetch detailed information for each business
            results.forEach((business) => {
              service.getDetails(
                { placeId: business.place_id },
                (placeResult, placeStatus) => {
                  if (placeStatus === window.google.maps.places.PlacesServiceStatus.OK) {
                    setDetailedBusinesses((prevDetails) => [...prevDetails, placeResult]);
                  }
                }
              );
            });
          } else {
            setBusinesses([]);
          }
        }
      );
    }
  };

  const handlePlaceSelect = () => {
    if (autocomplete) {
      const place = autocomplete.getPlaces()[0];
      if (place) {
        setSearchAddress(place.formatted_address);
        setLatitude(place.geometry.location.lat());
        setLongitude(place.geometry.location.lng());
        fetchNearbyBusinesses(place.geometry.location);
      } else {
        setBusinesses([]);
      }
    }
  };

  const onLoad = (ref) => {
    setAutocomplete(ref);
  };

  const mapOnLoad = (map) => {
    mapRef.current = map;
    fetchNearbyBusinesses({ lat: latitude, lng: longitude });
  };

  const mapOptions = {
    zoomControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    draggableCursor: "pointer",
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };

  return (
    <Grid container spacing={2} className="choose-professionals-wrapper" style={{ height: '100vh' }}>
      <Grid item md={4} className="professional-list-wrapper" style={{ overflowY: 'auto', maxHeight: '100%', height: '100%' }}>
        {registeredProfessionals && registeredProfessionals.length > 0 ? (
          <ul className="professional-list">
            {registeredProfessionals.map((professional, index) => (
              <li key={professional.id} className="professional-item">
                <div className="professional-details">
                  <h4 className="professional-name">{index + 1} - {professional.name}</h4>
                  <h5 className="professional-address">{professional.address}</h5>
                  <h5 className="professional-distancefromcurrent">{professional?.distanceFromCurrent?.crowflies}</h5>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  className="view-schedule-button"
                  onClick={() => handleViewScheduleClick(professional)}
                >
                  {t("AppointmentForm.ViewDisponibilities")}
                </Button>
              </li>
            ))}
          </ul>
        ) : (
          <Typography variant="h6" style={{ color: 'white' }}>{t("No professionals found in your area.")}</Typography>
        )}
        {detailedBusinesses && detailedBusinesses.length > 0 && (
          <ul className="professional-list">
            {detailedBusinesses.map((business, index) => (
              <li key={business.place_id} className="professional-item">
                <div className="professional-details">
                  <h4 className="professional-name">{index + 1 + registeredProfessionals.length} - {business.name}</h4>
                  <h5 className="professional-address">{business.vicinity}</h5>
                  <Rate
                    value={business.rating}
                    disabled
                    allowHalf
                  />
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  className="not-available-button"
                  onClick={() => handleViewScheduleClick(null)}
                >
                  {t("AppointmentForm.NotAvailable")}
                </Button>
              </li>
            ))}
          </ul>
        )}
      </Grid>
      <Grid item md={8} className="professionals-map-wrapper" style={{ height: '100%' }}>
        <LoadScript googleMapsApiKey="AIzaSyB_j8NP_ZfkMFGHNxqsCL_pPhEIBlBp9As" libraries={libraries}>
          <div className="search-box-container">
            <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={handlePlaceSelect}>
              <div>
                <PlaceRounded fontSize="small" htmlColor="#01b075" className="search-location-icon" />
                <input type="text" placeholder="Enter address" className="search-input" />
              </div>
            </StandaloneSearchBox>
          </div>
          <GoogleMap
            mapContainerStyle={{ height: "100%", width: "100%", borderRadius: "12px" }}
            center={{ lat: latitude, lng: longitude }}
            zoom={14}
            options={mapOptions}
            onLoad={mapOnLoad}
          >
            <Marker position={{ lat: latitude, lng: longitude }} icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"} />
            {registeredProfessionals && registeredProfessionals.length > 0 && registeredProfessionals.map((professional, index) => (
              <Marker
                key={professional.id}
                position={{ lat: professional.coordinate.lat, lng: professional.coordinate.lng }}
                icon={"http://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
                label={(index + 1).toString()}
                onClick={() => handleMarkerClick(professional)}
              />
            ))}
            {detailedBusinesses.map((business, index) => (
              <Marker
                key={business.place_id}
                position={{ lat: business.geometry.location.lat(), lng: business.geometry.location.lng() }}
                icon={"http://maps.google.com/mapfiles/ms/icons/red-dot.png"}
                label={(index + 1 + registeredProfessionals.length).toString()}
                onClick={() => handleMarkerClick(business)}
              />
            ))}
            {selectedMarker && (
              <InfoWindow
                position={{
                  lat: selectedMarker.geometry ? selectedMarker.geometry.location.lat() : selectedMarker.location.lat,
                  lng: selectedMarker.geometry ? selectedMarker.geometry.location.lng() : selectedMarker.location.lng,
                }}
                onCloseClick={handleInfoWindowClose}
              >
                <div className="info-window">
                  <h4>{selectedMarker.name}</h4>
                  <p>{selectedMarker.vicinity || selectedMarker.address}</p>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </LoadScript>
      </Grid>
    </Grid>
  );
};

export default MapPicker;



// import React, { useState, useRef, useEffect } from "react";
// import { Grid, Button, Typography } from "@mui/material";
// import {
//   LoadScript,
//   StandaloneSearchBox,
//   GoogleMap,
//   Marker,
//   InfoWindow,
// } from "@react-google-maps/api";
// import { PlaceRounded } from "@mui/icons-material";
// import { useTranslation } from 'react-i18next';
// import { companyService } from "../app/services"; // Ensure this service is correctly set up

// const MapPicker = ({
//   Lat,
//   Lng,
//   Address,
//   selectedProfessionalType,
//   handleViewScheduleClick
// }) => {
//   const [searchAddress, setSearchAddress] = useState("");
//   const [autocomplete, setAutocomplete] = useState(null);
//   const [businesses, setBusinesses] = useState([]);
//   const [selectedMarker, setSelectedMarker] = useState(null);
//   const [latitude, setLatitude] = useState(Lat);
//   const [longitude, setLongitude] = useState(Lng);
//   const [address, setAddress] = useState(Address);
//   const [professionalType, setProfessionalType] = useState(selectedProfessionalType);
//   const { t } = useTranslation();

//   const [registeredProfessionals, setRegisteredProfessionals] = useState([]);
//   const [detailedBusinesses, setDetailedBusinesses] = useState([]);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     // Fetch professionals from your backend
//     companyService
//       .getCompaniesByCategoryAndLocation(professionalType.name, { Lat: latitude, Lng: longitude })
//       .then((company) => {
//         setRegisteredProfessionals(company);
//       })
//       .catch((error) => {
//         console.error("Failed to fetch professionals:", error);
//       });



//   }, [professionalType, latitude, longitude]);

//   const handlePlaceSelect = () => {

//     if (autocomplete) {
//       const place = autocomplete.getPlaces()[0];
//       if (place) {
//         setSearchAddress(place.formatted_address);
//         setLatitude(place.geometry.location.lat());
//         setLongitude(place.geometry.location.lng());

//         const service = new window.google.maps.places.PlacesService(mapRef.current);
//         service.nearbySearch(
//           {
//             location: place.geometry.location,
//             radius: 1000,
//             type: [professionalType.googleTypeName], // Adjust the type as needed"professional_services"
//           },
//           (results, status) => {
//             if (status === window.google.maps.places.PlacesServiceStatus.OK) {
//               setBusinesses(results);
//               // Fetch detailed information for each business
//               results.forEach((business) => {
//                 service.getDetails(
//                   { placeId: business.place_id },
//                   (placeResult, placeStatus) => {
//                     if (placeStatus === window.google.maps.places.PlacesServiceStatus.OK) {
//                       setDetailedBusinesses((prevDetails) => [...prevDetails, placeResult]);
//                     }
//                   }
//                 );
//               });
//             } else {
//               setBusinesses([]);
//             }
//           }
//         );
//       } else {
//         setBusinesses([]);
//       }
//     }
//   };

//   const onLoad = (ref) => {
//     setAutocomplete(ref);

// debugger;
//           const service = new window.google.maps.places.PlacesService(mapRef.current);
//       service.nearbySearch(
//         {
//           location: address,
//           radius: 1000,
//           type: [professionalType.googleTypeName], // Adjust the type as needed
//         },
//         (results, status) => {
//           debugger;
//           if (status === window.google.maps.places.PlacesServiceStatus.OK) {
//             setBusinesses(results);
//             // Fetch detailed information for each business
//             results.forEach((business) => {
//               service.getDetails(
//                 { placeId: business.place_id },
//                 (placeResult, placeStatus) => {
//                   if (placeStatus === window.google.maps.places.PlacesServiceStatus.OK) {
//                     setDetailedBusinesses((prevDetails) => [...prevDetails, placeResult]);
//                   }
//                 }
//               );
//             });
//           } else {
//             setBusinesses([]);
//           }
//         }
//       );
//   };

//   const mapOptions = {
//     zoomControl: true,
//     mapTypeControl: false,
//     streetViewControl: false,
//     fullscreenControl: false,
//     draggableCursor: "pointer",
//   };

//   const handleMarkerClick = (marker) => {
//     setSelectedMarker(marker);
//   };

//   const handleInfoWindowClose = () => {
//     setSelectedMarker(null);
//   };

//   return (
//     <Grid container spacing={2} className="choose-professionals-wrapper" style={{ height: '600px' }}>
//       <Grid item md={4} className="professional-list-wrapper" style={{ backgroundColor: 'Blue' }}>
//         {registeredProfessionals && registeredProfessionals.length > 0 ? (
//           <ul className="professional-list">
//             {registeredProfessionals.map((professional, index) => (
//               <li key={professional.id} className="professional-item">
//                 <h4 className="professional-name">{index + 1} - {professional.name}</h4>
//                 <h5 className="professional-address">{professional.address}</h5>
//                 <h5 className="professional-distancefromcurrent">{professional?.distanceFromCurrent?.crowflies}</h5>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   className="view-schedule-button"
//                   onClick={() => handleViewScheduleClick(professional)}
//                 >
//                   {t("AppointmentForm.ViewDisponibilities")}
//                 </Button>
//               </li>
//             ))}
//           </ul>
//         ) : (
//           <Typography variant="h6" style={{ color: 'white' }}>{t("No professionals found in your area.")}</Typography>
//         )}
//         {detailedBusinesses && detailedBusinesses.length > 0 && (
//           <ul className="professional-list">
//             {detailedBusinesses.map((business, index) => (
//               <li key={business.place_id} className="professional-item">
//                 <h4 className="professional-name">{index + 1 + registeredProfessionals.length} - {business.name}</h4>
//                 <h5 className="professional-address">{business.vicinity}</h5>
//                 <h5 className="professional-rating">Rating: {business.rating} / 5</h5>
//                 {business.reviews && business.reviews.length > 0 && (
//                   <ul className="professional-reviews">
//                     {business.reviews.map((review, reviewIndex) => (
//                       <li key={reviewIndex} className="professional-review">
//                         <p>{review.text}</p>
//                         <p>Rating: {review.rating} / 5</p>
//                       </li>
//                     ))}
//                   </ul>
//                 )}
//               </li>
//             ))}
//           </ul>
//         )}
//       </Grid>
//       <Grid item md={8} className="professionals-map-wrapper" style={{ backgroundColor: "Red" }}>
//         <LoadScript googleMapsApiKey="AIzaSyB_j8NP_ZfkMFGHNxqsCL_pPhEIBlBp9As" libraries={["places"]}>
//           <div className="search-box-container">
//             <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={handlePlaceSelect}>
//               <div>
//                 <PlaceRounded fontSize="small" htmlColor="#01b075" className="search-location-icon" />
//                 <input type="text" placeholder="Enter address" className="search-input" />
//               </div>
//             </StandaloneSearchBox>
//           </div>
//           <GoogleMap
//             mapContainerStyle={{ height: "100%", width: "100%", borderRadius: "12px" }}
//             center={{ lat: latitude, lng: longitude }}
//             zoom={14}
//             options={mapOptions}
//             onLoad={(map) => { mapRef.current = map; }}
//           >
//             <Marker position={{ lat: latitude, lng: longitude }} icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"} />
//             {registeredProfessionals && registeredProfessionals.length > 0 && registeredProfessionals.map((professional, index) => (
//               <Marker
//                 key={professional.id}
//                 position={{ lat: professional.coordinate.lat, lng: professional.coordinate.lng }}
//                 icon={"http://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
//                 label={(index + 1).toString()}
//                 onClick={() => handleMarkerClick(professional)}
//               />
//             ))}
//             {detailedBusinesses.map((business, index) => (
//               <Marker
//                 key={business.place_id}
//                 position={{ lat: business.geometry.location.lat(), lng: business.geometry.location.lng() }}
//                 icon={"http://maps.google.com/mapfiles/ms/icons/red-dot.png"}
//                 label={(index + 1 + registeredProfessionals.length).toString()}
//                 onClick={() => handleMarkerClick(business)}
//               />
//             ))}
//             {selectedMarker && (
//               <InfoWindow
//                 position={{
//                   lat: selectedMarker.geometry ? selectedMarker.geometry.location.lat() : selectedMarker.location.lat,
//                   lng: selectedMarker.geometry ? selectedMarker.geometry.location.lng() : selectedMarker.location.lng,
//                 }}
//                 onCloseClick={handleInfoWindowClose}
//               >
//                 <div className="info-window">
//                   <h4>{selectedMarker.name}</h4>
//                   <p>{selectedMarker.vicinity || selectedMarker.address}</p>
//                 </div>
//               </InfoWindow>
//             )}
//           </GoogleMap>
//         </LoadScript>
//       </Grid>
//     </Grid>
//   );
// };

// export default MapPicker;




// import React, { useState, useEffect, useRef } from "react";
// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Stack,
//   TextField,
//   Button,
//   Select,
//   MenuItem,
//   Grid,
//   Box,
// } from "@mui/material";
// import {
//   GoogleMap,
//   LoadScript,
//   Marker,
//   StandaloneSearchBox,
//   InfoWindow,
// } from "@react-google-maps/api";
// import { PlaceRounded  } from "@mui/icons-material";

// const MapPicker = ({ Lat, Lng, Address, registeredProfessionals , handleViewScheduleClick}) => {
//   const [isGoogleApiLoaded, setIsGoogleApiLoaded] = useState(false);
//   const [searchAddress, setSearchAddress] = useState("");
//   const [error, setError] = useState(null);
//   const [autocomplete, setAutocomplete] = useState(null);
//   const [libraries] = useState(["places"]);
//   const [businesses, setBusinesses] = useState([]);
//   const [selectedMarker, setSelectedMarker] = useState(null);

//   const [latitude, setLatitude] = useState(Lat);
//   const [longitude, setLongitude] = useState(Lng);
//   const [address, setAddress] = useState(Address);

//   const [registeredProfessionalsl, setRegisteredProfessionalsl] = useState(registeredProfessionals);

//   const mapRef = useRef(null);

//   const handlePlaceSelect = () => {
//     if (autocomplete) {
//       const place = autocomplete.getPlaces()[0];
//       if (place) {
//         setSearchAddress(place.formatted_address);
//         setLatitude(place.geometry.location.lat());
//         setLongitude(place.geometry.location.lng());
//         setError(null);

//         const service = new window.google.maps.places.PlacesService(mapRef.current);
//         service.nearbySearch(
//           {
//             location: place.geometry.location,
//             radius: 5000,
//             type: ["professional_services"], // You can adjust the type to your needs
//           },
//           (results, status) => {
//             if (status === window.google.maps.places.PlacesServiceStatus.OK) {
//               setBusinesses(results);
//             } else {
//               setBusinesses([]);
//               setError("Error retrieving business details");
//             }
//           }
//         );
//       } else {
//         setBusinesses([]);
//         setError("Error retrieving place details");
//       }
//     }
//   };

//   const onLoad = (ref) => {
//     setAutocomplete(ref);
//   };

//   const mapOptions = {
//     zoomControl: true,
//     mapTypeControl: false,
//     streetViewControl: false,
//     fullscreenControl: false,
//     draggableCursor: "pointer",
//   };

//   const handleMarkerClick = (marker) => {
//     setSelectedMarker(marker);
//   };

//   const handleInfoWindowClose = () => {
//     setSelectedMarker(null);
//   };

//   return (
//     <Grid container spacing={2} className="choose-professionals-wrapper" style={{backgroundColor:'Green',height:'100%'}}>
//     {registeredProfessionalsl && registeredProfessionalsl.length > 0 && (
//       <Grid item md={4} className="professional-list-wrapper">
//         <ul className="professional-list">
//           {registeredProfessionalsl.map((professional) => (
//             <li key={professional.id} className="professional-item">
//               <h4 className="professional-name">
//                 {professionals.indexOf(professional)} -{" "}
//                 {professional.name}
//               </h4>
//               <h5 className="professional-address">
//                 {professional.address}
//               </h5>
//               <h5 className="professional-distancefromcurrent">
//                 {professional?.distanceFromCurrent?.crowflies}
//               </h5>
//               <button
//                 className="view-schedule-button"
//                 onClick={() => handleViewScheduleClick(professional)}
//               >
//                 {t("AppointmentForm.ViewDisponibilities")}
//               </button>
//             </li>
//           ))}
//         </ul>
//       </Grid>
//     )}
//     <Grid item md={8} className="professionals-map-wrapper" style={{backgroundColor:'Blue'}}> 
//       <LoadScript
//                  googleMapsApiKey="AIzaSyB_j8NP_ZfkMFGHNxqsCL_pPhEIBlBp9As"
//         libraries={libraries}
//       >
//         <div className="search-box-container" style={{backgroundColor:'red'}}>
//           <StandaloneSearchBox
//             onLoad={onLoad}
//             onPlacesChanged={handlePlaceSelect}
//           >
//             <div>
//               <PlaceRounded
//                 fontSize="small"
//                 htmlColor="#01b075"
//                 className="search-location-icon"
//               />
//               <input
//                 type="text"
//                 placeholder="Enter address"
//                 className="search-input"
//               />
//             </div>
//           </StandaloneSearchBox>
//         </div>
//         <GoogleMap
//           mapContainerStyle={{
//             height: "100%",
//             width: "100%",
//             borderRadius: "12px",
//           }}
//           center={{ lat: latitude, lng: longitude }}
//           zoom={14}
//           options={mapOptions}
//           onLoad={(map) => {
//             mapRef.current = map;
//             setIsGoogleApiLoaded(true);
//           }}
//         >
//           <Marker
//             position={{ lat: latitude, lng: longitude }}
//             icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}
//           />

//           {registeredProfessionals &&
//             registeredProfessionals.map((professional, index) => (
//               <Marker
//                 key={professional.id}
//                 position={{
//                   lat: professional.location.lat,
//                   lng: professional.location.lng,
//                 }}
//                 icon={"http://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
//                 label={(index + 1).toString()}
//                 onClick={() => handleMarkerClick(professional)}
//               />
//             ))}

//           {businesses &&
//             businesses.map((business, index) => (
//               <Marker
//                 key={business.place_id}
//                 position={{
//                   lat: business.geometry.location.lat(),
//                   lng: business.geometry.location.lng(),
//                 }}
//                 icon={"http://maps.google.com/mapfiles/ms/icons/red-dot.png"}
//                 label={(index + 1 + registeredProfessionals.length).toString()}
//                 onClick={() => handleMarkerClick(business)}
//               />
//             ))}

//           {selectedMarker && (
//             <InfoWindow
//               position={{
//                 lat: selectedMarker.geometry ? selectedMarker.geometry.location.lat() : selectedMarker.location.lat,
//                 lng: selectedMarker.geometry ? selectedMarker.geometry.location.lng() : selectedMarker.location.lng,
//               }}
//               onCloseClick={handleInfoWindowClose}
//             >
//               <div className="info-window">
//                 <h4>{selectedMarker.name}</h4>
//                 <p>{selectedMarker.vicinity || selectedMarker.address}</p>
//               </div>
//             </InfoWindow>
//           )}
//         </GoogleMap>
//       </LoadScript>
//       </Grid>
//   </Grid>
//   );
// };

// export default MapPicker;

// import React, { useEffect, useState } from "react";
// import {
//   GoogleMap,
//   LoadScript,
//   Marker,
//   StandaloneSearchBox,
//   InfoWindow,
// } from "@react-google-maps/api";
// import { PlaceRounded } from "@mui/icons-material";

// const MapPicker = ({ Lat, Lng, Address, professionals }) => {
//   const [isGoogleApiLoaded, setIsGoogleApiLoaded] = useState(false);
//   const [searchAddress, setSearchAddress] = useState("");
//   const [error, setError] = useState(null);
//   const [autocomplete, setAutocomplete] = useState(null);
//   const [libraries] = useState(["places"]);
//   const [businesses, setBusinesses] = useState([]);
//   const [selectedMarker, setSelectedMarker] = useState(null);

//   const [latitude, setLatitude] = useState(Lat);
//   const [longitude, setLongitude] = useState(Lng);
//   const [address, setAddress] = useState(Address);

//   const handleSearchAddressFromIP = (ipAddress) => {
//     if (!ipAddress) {
//       setError("Please enter an address");
//       return;
//     }

//     debugger;
//     fetch(
//       `https://maps.googleapis.com/maps/api/geocode/json?ip=${encodeURIComponent(
//         ipAddress
//       )}&key=AIzaSyB_j8NP_ZfkMFGHNxqsCL_pPhEIBlBp9As`
//     )
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.status === "OK") {
//           debugger;
//           const location = data.results[0].geometry.location;
//           setLatitude(location.lat);
//           setLongitude(location.lng);
//           setError(null);
//         } else {
//           setError("Error retrieving location");
//         }
//       })
//       .catch((error) => {
//         setError(`Error retrieving location: ${error.message}`);
//       });
//   };

//   const handlePlaceSelect = () => {
//     debugger;
//     if (autocomplete) {
//       //&& map
//       const place = autocomplete.getPlaces()[0];
//       if (place) {
//         // const place = places[0];
//         setSearchAddress(place.formatted_address);
//         setLatitude(place.geometry.location.lat());
//         setLongitude(place.geometry.location.lng());
//         setError(null);
//       } else {
//         setBusinesses([]);
//         setError("Error retrieving place details");
//       }
//     }
//   };

//   const onLoad = (ref) => {
//     setAutocomplete(ref);
//   };



//   if (error) {
//     return <div>{error}</div>;
//   }

//   const mapOptions = {
//     zoomControl: true,
//     mapTypeControl: false,
//     streetViewControl: false,
//     fullscreenControl: false,
//     draggableCursor: "pointer",
//   };

//   const handleMarkerClick = (marker) => {
//     setSelectedMarker(marker);
//   };

//   const handleInfoWindowClose = () => {
//     setSelectedMarker(null);
//   };
//   return (
//     <>
//       <LoadScript
//         googleMapsApiKey="AIzaSyB_j8NP_ZfkMFGHNxqsCL_pPhEIBlBp9As"
//         libraries={libraries}
//       >
//         <div className="search-box-container">
//           <StandaloneSearchBox
//             onLoad={onLoad}
//             onPlacesChanged={handlePlaceSelect}
//           >
//             <div>
//               <PlaceRounded
//                 fontSize="small"
//                 htmlColor="#01b075"
//                 className="search-location-icon"
//               />
//               <input
//                 type="text"
//                 placeholder="Enter address"
//                 className="search-input"
//               />
//             </div>
//           </StandaloneSearchBox>
//         </div>
//         <GoogleMap
//           mapContainerStyle={{
//             height: "100%",
//             width: "100%",
//             borderRadius: "12px",
//           }}
//           center={{ lat: latitude, lng: longitude }}
//           zoom={14}
//           options={mapOptions}
//         >
//           <Marker
//             position={{ lat: latitude, lng: longitude }}
//             icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}
//           />

//           {professionals &&
//             professionals.map((business) => (
//               <div key={business.id}>
//                 <Marker
//                   position={{
//                     lat: business.coordinate.lat,
//                     lng: business.coordinate.lng,
//                   }}
//                   label={professionals.indexOf(business).toString()}
//                   onClick={() => handleMarkerClick(business)}
//                 />
//               </div>
//             ))}

//           {selectedMarker && (
//             <InfoWindow
//               position={{
//                 lat: selectedMarker.coordinate.lat,
//                 lng: selectedMarker.coordinate.lng,
//               }}
//               onCloseClick={handleInfoWindowClose}
//             >
//               <div className="info-window">
//                 <h4>{selectedMarker.name}</h4>
//                 <p>{selectedMarker.address}</p>
//               </div>
//             </InfoWindow>
//           )}
//         </GoogleMap>
//       </LoadScript>
//     </>
//   );
// };

// export default MapPicker;

