
const environment = process.env.REACT_APP_ENV || 'development';

const config = {
    development: {
        api_url:"https://localhost:8001",
        hub_url:"https://localhost:8001/hubs/Cal",
        is_production: false,
        basename:"/"
    },
    production: {
        api_url:"https://api.diarytree.com",
        hub_url:"https://api.diarytree.com/hubs/Cal",
        is_production: true,
        basename:"/"
    },
  };
  console.log(environment);
const appConfig = config[environment];

export default appConfig;