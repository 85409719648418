import React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
} from "@mui/material";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import ImageIcon from "@mui/icons-material/Image";
import { Typography, Row, Col } from "antd";
import { CheckSquareOutlined } from "@ant-design/icons";
import { AiOutlineCheck } from "react-icons/ai";
import image from "../assets/Calendar/calendar_noimage-fd7df6ac9f2e585b244d85e28bc49661.png";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const { Title } = Typography;
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CalendarBlock = (props) => {
  return (
    <Row
      className={`${props.data.selected ? "is_select" : "is_select_none"}`}
      style={{ justifyContent: "space-between" }}
    >
      <Col span={16} style={{ alignItems: "center", display: "flex" }}>
        <ListItem
          onClick={() => {
            props.handleClick(props.data.id);
          }}
          style={{
            cursor: "pointer",
            borderLeft: "6px",
            borderLeftStyle: "solid",
            borderLeftColor: props.data.selected
              ? props.data.color
              : "transparent",
          }}
        >
          <ListItemAvatar>
            <Avatar>
              {/* <ImageIcon  src={image} style={{ backgroundColor: props.data.color }} />                */}
              <div className={"block_logo"}>
                <div
                  className={" gx-h-100 gx-rounded-sm"}
                  style={{
                    backgroundColor: props.data.color,
                    width: "40px",
                    height: "40px",
                    filter: props.data.selected
                      ? "grayscale(0%)"
                      : "grayscale(100%)",
                  }}
                >
                  <img
                    src={image}
                    style={{
                      backgroundColor: props.data.color,
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </div>
                <div
                  className={`gx-d-flex gx-align-items-center gx-justify-content-center gx-rounded-sm`}
                  style={{ position: "absolute" }}
                >
                  <AiOutlineCheck style={{ fontSize: 15, color: "#fff" }} />
                </div>
              </div>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={props.data.name}
            secondary={props.data.description}
            style={{
              color: props.data.selected ? props.data.color : "#9e9e9e",
            }}
          />
        </ListItem>
      </Col>
      <div
        style={{ display: "flex", alignItems: "center", paddingRight: "16px" }}
      >
        <a
          onClick={() => {
            props.handleEditCalendar();
          }}
          className="left-side-action-btn"
        >
          <EditOutlined size={16} />
        </a>
        <a
          onClick={() => {
            // if (props.editCalendarAvailablity) {
            props.handleDeleteCalendar(props.data.id);
            // }
          }}
          className="left-side-action-btn"
        >
          <DeleteOutlined size={16} />
        </a>
      </div>
    </Row>

    // style={{ color: props.data.color }}
  );
};

export default CalendarBlock;

// <Row onClick={() => { props.handleClick(props.data.id) }}>className={'gx-w-100 gx-h-100'}
// <Col span={12}>
//     <div className={'block_logo'}>
//         <div className={`gx-w-100 gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center is_select gx-rounded-sm ${props.data.selected ? '' : 'gx-d-none'}`}>
//             <CheckSquareOutlined style={{ fontSize: 30, color: '#fff' }} />
//         </div>
//     </div>
// </Col>
// <Col span={12} >
//             <Title level={4} style={{ color: props.data.color,margin:'2px' }} >{props.data.name}</Title>
//             <h5>{props.data.description}</h5>
//             <h5 >{props.data.selected}</h5>
// </Col>
// </Row>
// src={image}

//  <div  style={{ backgroundColor: props.data.color }} >
//     <img  />
// </div>
// <div className={'gx-w-100 calendar_block gx-d-flex'}  onClick={() => { props.handleClick(props.data.id) }}>src={image}
//     <div className={'gx-w-25 gx-mw-90'}>
//         <div className={'block_logo'}>
//             <div className={'gx-w-100 gx-h-100 gx-rounded-sm'} style={{ backgroundColor: props.data.color }} >
//                 <img className={'gx-w-100 gx-h-100'}  />
//             </div>
//             <div className={`gx-w-100 gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center is_select gx-rounded-sm ${props.data.selected ? '' : 'gx-d-none'}`}>
//                 <CheckSquareOutlined  style={{ fontSize: 30, color: '#fff' }}  />
//             </div>
//         </div>
//     </div>
//     <div className={'gx-w-75'}>
//         <Grid  className={'gx-flex-sm-row gx-p-3 flex-grow-1'}>
//             <Grid  span={24}>
//                 <Title className={'gx-w-100 gx-m-0'} level={4} style={{ color: props.data.color }} >{props.data.name}</Title>
//             </Grid >
//             <Grid  span={24}>
//                 <pre className={'gx-w-100 calendar_comment'}>{props.data.description}</pre>
//                 <pre >{props.data.selected}</pre>
//             </Grid >
//         </Grid >
//     </div>
// </div>

// <div className={'gx-w-100 calendar_block d-flex'}  onClick={() => { props.handleClick(props.data.id) }}>
// <div className={'gx-w-25 gx-mw-90'}>
//     <div className={'block_logo'}>
//         <div className={'gx-w-100 gx-h-100 gx-rounded-sm'} style={{ backgroundColor: props.data.color }} >
//             <img className={'gx-w-100 gx-h-100'} />
//         </div>
//         <div className={`gx-w-100 gx-h-100 d-flex  gx-align-items-center gx-justify-content-center is_select gx-rounded-sm ${props.data.selected ? '' : 'gx-d-none'}`}>
//             <CheckSquareOutlined style={{ fontSize: 30, color: '#fff' }} />
//         </div>
//     </div>
// </div>
// <div className={'gx-w-75'}>
//     <Row className={'gx-flex-sm-row gx-p-3 flex-grow-1'}>
//         <Col span={24}>
//             <Title className={'gx-w-100 gx-m-0'} level={4} style={{ color: props.data.color }} >{props.data.name}</Title>
//         </Col>
//         <Col span={24}>
//             <pre className={'gx-w-100 calendar_comment'}>{props.data.description}</pre>
//             <pre >{props.data.selected}</pre>
//         </Col>
//     </Row>
// </div>
