// export {  default as Blog } from './blogs';
// export default Features from './features/Features';
// // export  default  Footer  from './footer/Footer';
// export default  Header from './header/Header';
// export  default  Possibility  from './possibility/Possibility';
// export default  dtree from './dtree/dtree';


import Home from './Home';
export default Home ;
