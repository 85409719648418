import { BehaviorSubject } from 'rxjs';
import  ApiCore  from  './common/api-core';
import { handleResponse } from '../helpers';

// Get current Token value
const url = "CalendarApi";

const apiTasks = new ApiCore({
    getAll: true,
    getSingle: true,
    post: true,
    put: false,
    patch: true,
    delete: false,
    url: url
  });

export const calendarService = {
    send,
    update,
    remove
};

// send a calendar
function send(calendar) {
    return apiTasks.post('AddCalendar',calendar)
    .then(function (response) {
                if(!response.isAxiosError)
                {
                    return response;
                }
          })
}

// update a calendar
function update(calendar){
    return apiTasks.post('UpdateCalendar',calendar)
    .then(function (response) {
                if(!response.isAxiosError)
                {
                    return response;
                }
          })
}

// delete
function remove(calendarid){
    return apiTasks.post('DeleteCalendar',calendarid)
    .then(function (response) {
                if(!response.isAxiosError)
                {
                    return response;
                }
          })
}