import React from 'react';
import CheckCircleTwoTone from  '@ant-design/icons/CheckCircleTwoTone';
import { BsCheckAll } from "react-icons/bs";

const CalendarSelectToggle = (props) => {
    return (
        <div className={'gx-w-100 calendar_small_block'} onClick={props.clickCheckAll}>
            <BsCheckAll/>
            {/* <CheckCircleTwoTone  style={{ fontSize: 30, color: 'rgba(33, 33, 33, 0.6)' }}  /> */}
        </div>
    )
};

export default CalendarSelectToggle;
