import blog01 from '../assets/images/blog01.png';
import blog02 from '../assets/images/blog02.png';
import blog03 from '../assets/images/blog03.png';
import blog04 from '../assets/images/blog04.png';
import blog05 from '../assets/images/blog05.png';

export {
  blog01,
  blog02,
  blog03,
  blog04,
  blog05,
};
