import { useEffect } from "react";

import Image from "react-bootstrap/Image";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from 'react-i18next';


import FeatImage01 from "../../../../assets/images/dashboard-hairdresserbooking-fotor-bg-remover-20230522222429.png";
import FeatImage02 from "../../../../assets/images/fotor_2023-5-23_22_27_38.png";
import FeatImage03 from "../../../../assets/images/fotor_2023-5-23_22_33_47.png";
import { Box, Container, Grid } from "@mui/material";

// import FeatImage01 from '../../../../assets/images/dashboard-calendarmanaging.jpg'
// import FeatImage02 from '../../../../assets/images/dashboard-hairdresserbooking.jpg'
// import FeatImage03 from '../../../../assets/images/dashboard-sharingtasksandevents.jpg'

export default function Zigzag() {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 600,
      easing: "ease-out-sine",
    });
  });

  return (
    <div className="section__padding">
      {/* Section header */}
      {/* <div className="dtree_section_header">
        <Container>
          <div className="dtree_section_header_small">
            Reach goals that matter TOTO
          </div>
          <h1 className="dtree_section_header_title">
            One product, unlimited solutions
          </h1>
          <p className="dtree_section_header_desc">
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
            officia deserunt mollit laborum — semper quis lectus nulla.
          </p>
        </Container>
      </div> */}

      <div className="dtree_section_elements">
        {/* 1st zigzag */}
        <div
          className="dtree_section_element section__padding_side section__padding_updown"
          style={{ backgroundColor: "#f0f6fc" }}
        >
          <Container>
            <Grid container alignItems="center">
              <Grid item xs={12} md={6} data-aos="fade-right">
                <Box
                  sx={{
                    textAlign: {
                      xs: "center",
                      md: "left",
                    },
                  }}
                  className="dtree_section_element-daughter"
                >
                  {t("HomePage.1stZigZagtitle")}
                </Box>
                <h3 className="dtree_section_element-title">
                  {t("HomePage.1stZigZagheader")}
                </h3>
                <p className="dtree_section_element-desc">
                  {t("HomePage.1stZigZagBody")}
                </p>
                <ul className="dtree_section_element-Points">
                  <li className="dtree_section_element-Point">
                    <svg
                      className="svg_tick"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>{t("HomePage.1stZigZagFirstTick")}</span>
                  </li>
                  <li className="dtree_section_element-Point">
                    <svg
                      className="svg_tick"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>{t("HomePage.1stZigZagSecondTick")}</span>
                  </li>
                  <li className="dtree_section_element-Point">
                    <svg
                      className="svg_tick"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>{t("HomePage.1stZigZagThirdTick")}</span>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} md={6} className="dtree_section_element-image">
                <img src={FeatImage01} data-aos="fade-up" />
              </Grid>
            </Grid>
          </Container>
        </div>

        {/* 2nd zigzag */}
        <div className="dtree_section_element section__padding_side section__padding_updown">
          <Container>
            <Grid container alignItems="center" className="zigzag2">
              <Grid item xs={12} md={6} className="dtree_section_element-image">
                <img src={FeatImage02} data-aos="fade-up" />
              </Grid>
              <Grid item xs={12} md={6} data-aos="fade-left">
                <div className="dtree_section_element-daughter">
                  {t("HomePage.2ndZigZagtitle")}
                </div>
                <h3 className="dtree_section_element-title">
                  {t("HomePage.2ndZigZagheader")}
                </h3>
                <p className="dtree_section_element-desc">
                  {t("HomePage.2ndZigZagBody")}
                </p>
                <ul className="dtree_section_element-Points">
                  <li className="dtree_section_element-Point">
                    <svg
                      className="svg_tick"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>{t("HomePage.2ndZigZagFirstTick")}</span>
                  </li>
                  <li className="dtree_section_element-Point">
                    <svg
                      className="svg_tick"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>{t("HomePage.2ndZigZagSecondTick")}</span>
                  </li>
                  <li className="dtree_section_element-Point">
                    <svg
                      className="svg_tick"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>{t("HomePage.2ndZigZagThirdTick")}</span>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Container>
        </div>

        {/* 3rd zigzag */}
        <div
          className="dtree_section_element section__padding_side section__padding_updown"
          style={{ backgroundColor: "#f0f6fc" }}
        >
          <Container>
            <Grid container alignItems="center">
              <Grid item xs={12} md={6} data-aos="fade-right">
                <div className="dtree_section_element-daughter">
                  {t("HomePage.3rdZigZagtitle")}
                </div>
                <h3 className="dtree_section_element-title">
                  {t("HomePage.3rdZigZagheader")}
                </h3>
                <p className="dtree_section_element-desc">
                  {t("HomePage.3rdZigZagBody")}
                </p>
                <ul className="dtree_section_element-Points">
                  <li className="dtree_section_element-Point">
                    <svg
                      className="svg_tick"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>{t("HomePage.3rdZigZagFirstTick")}</span>
                  </li>
                  <li className="dtree_section_element-Point">
                    <svg
                      className="svg_tick"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>{t("HomePage.3rdZigZagSecondTick")}</span>
                  </li>
                  <li className="dtree_section_element-Point">
                    <svg
                      className="svg_tick"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>{t("HomePage.3rdZigZagThirdTick")}</span>
                  </li>
                </ul>
              </Grid>

              <Grid item xs={12} md={6} className="dtree_section_element-image">
                <img src={FeatImage03} data-aos="fade-up" />
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
    // <section>
    //   <div className="max-w-6xl mx-auto px-4 sm:px-6">
    //     <div className="py-12 md:py-20 border-t border-gray-800">

    //       {/* Section header */}
    //       <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
    //         <div className="inline-flex text-sm font-semibold py-1 px-3 m-2 text-green-600 bg-green-200 rounded-full mb-4">Reach goals that matter</div>
    //         <h1 className="h2 mb-4">One product, unlimited solutions</h1>
    //         <p className="text-xl text-gray-400">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit laborum — semper quis lectus nulla.</p>
    //       </div>

    //       {/* Items */}
    //       <div className="grid gap-20">

    //         {/* 1st item */}
    //         <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
    //           {/* Image */}
    //           <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
    //             <Image className="max-w-full mx-auto md:max-w-none h-auto" src={FeatImage01} width={540} height={405} alt="Features 01" />
    //           </div>
    //           {/* Content */}
    //           <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
    //             <div className="md:pr-4 lg:pr-12 xl:pr-16">
    //               <div className="font-architects-daughter text-xl text-purple-600 mb-2">More speed. Less spend</div>
    //               <h3 className="h3 mb-3">Keep projects on schedule</h3>
    //               <p className="text-xl text-gray-400 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    //               <ul className="text-lg text-gray-400 -mb-2">
    //                 <li className="flex items-center mb-2">
    //                   <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    //                     <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
    //                   </svg>
    //                   <span>Duis aute irure dolor in reprehenderit</span>
    //                 </li>
    //                 <li className="flex items-center mb-2">
    //                   <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    //                     <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
    //                   </svg>
    //                   <span>Excepteur sint occaecat</span>
    //                 </li>
    //                 <li className="flex items-center">
    //                   <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    //                     <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
    //                   </svg>
    //                   <span>Amet consectetur adipiscing elit</span>
    //                 </li>
    //               </ul>
    //             </div>
    //           </div>
    //         </div>

    //         {/* 2nd item */}
    //         <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
    //           {/* Image */}
    //           <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 rtl" data-aos="fade-up">
    //             <Image className="max-w-full mx-auto md:max-w-none h-auto" src={FeatImage02} width={540} height={405} alt="Features 02" />
    //           </div>
    //           {/* Content */}
    //           <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-left">
    //             <div className="md:pl-4 lg:pl-12 xl:pl-16">
    //               <div className="font-architects-daughter text-xl text-purple-600 mb-2">More speed. Less spend</div>
    //               <h3 className="h3 mb-3">Keep projects on schedule</h3>
    //               <p className="text-xl text-gray-400 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    //               <ul className="text-lg text-gray-400 -mb-2">
    //                 <li className="flex items-center mb-2">
    //                   <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    //                     <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
    //                   </svg>
    //                   <span>Duis aute irure dolor in reprehenderit</span>
    //                 </li>
    //                 <li className="flex items-center mb-2">
    //                   <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    //                     <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
    //                   </svg>
    //                   <span>Excepteur sint occaecat</span>
    //                 </li>
    //                 <li className="flex items-center">
    //                   <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    //                     <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
    //                   </svg>
    //                   <span>Amet consectetur adipiscing elit</span>
    //                 </li>
    //               </ul>
    //             </div>
    //           </div>
    //         </div>

    //         {/* 3rd item */}
    //         <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
    //           {/* Image */}
    //           <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
    //             <Image className="max-w-full mx-auto md:max-w-none h-auto" src={FeatImage03} width={540} height={405} alt="Features 03" />
    //           </div>
    //           {/* Content */}
    //           <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
    //             <div className="md:pr-4 lg:pr-12 xl:pr-16">
    //               <div className="font-architects-daughter text-xl text-purple-600 mb-2">More speed. Less spend</div>
    //               <h3 className="h3 mb-3">Keep projects on schedule</h3>
    //               <p className="text-xl text-gray-400 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    //               <ul className="text-lg text-gray-400 -mb-2">
    //                 <li className="flex items-center mb-2">
    //                   <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    //                     <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
    //                   </svg>
    //                   <span>Duis aute irure dolor in reprehenderit</span>
    //                 </li>
    //                 <li className="flex items-center mb-2">
    //                   <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    //                     <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
    //                   </svg>
    //                   <span>Excepteur sint occaecat</span>
    //                 </li>
    //                 <li className="flex items-center">
    //                   <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    //                     <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
    //                   </svg>
    //                   <span>Amet consectetur adipiscing elit</span>
    //                 </li>
    //               </ul>
    //             </div>
    //           </div>
    //         </div>

    //       </div>

    //     </div>
    //   </div>
    // </section>
  );
}
