import React from 'react';
import { Card, CardActionArea, CardMedia, CardContent, Typography, IconButton, Grid } from '@mui/material';
import SpaIcon from '@mui/icons-material/Spa';

const ServiceCard = ({ image, serviceName, duration, price }) => {
  return (
    <Card sx={{ maxWidth: 345, margin: '10px' }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={image}
          alt={serviceName}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item>
              {/* <Spa fontSize="large" color="primary" /> */}
            </Grid>
            <Grid item xs>
              <Typography gutterBottom variant="h5" component="div">
                {serviceName}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {duration}
              </Typography>
              <Typography variant="h6" color="text.primary">
                ${price}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ServiceCard;
