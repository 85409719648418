// ComingEvents.js
import React, { useState } from 'react';

const ComingEvents = () => {
  const [events, setEvents] = useState([
    { id: 1, title: 'Event 1', date: '2023-07-25' },
    { id: 2, title: 'Event 2', date: '2023-08-05' },
    // Add more events here
  ]);

  return (
    <div>
      <h2>Coming Events</h2>
      <ul>
        {events.map((event) => (
          <li key={event.id}>
            <strong>{event.title}</strong> - {event.date}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ComingEvents;
